import { Dropdown, Tooltip } from 'antd';
import history from 'helpers/history';
import { validationFlowCompletedInCancelation } from 'helpers/validationFlows';
import useCurrentUser from 'hooks/useCurrentUser';
import useGetCurrentPermissions from 'hooks/useGetCurrentPermissions';
import ChangeOrderStatus from 'pages/Order/components/GlobalActionsOrderDrawer/OptionsControl/ChangeOrderStatus';
import ChangeSlaDate from 'pages/Order/components/GlobalActionsOrderDrawer/OptionsControl/ChangeSLADate';
import RemoveShippingPrice from 'pages/Order/components/GlobalActionsOrderDrawer/OptionsControl/RemoveShippingPrice';
import { verifyDisabledButton } from 'pages/Orders/components/Actions/validations';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { MdKeyboardArrowDown } from 'react-icons/md';
import useGetPickupPreference from 'hooks/useGetPickupPreference';
import { useDispatch } from 'react-redux';
import { useCreateFulfillmentByLogisticMutation } from 'redux/features/Fulfillments';
import {
    ordersApi,
    useCancelByLogisticV2Mutation,
    useCancelItemV2Mutation,
    useRequestCancelFulfillmentMutation,
    useRequestCancelItemsMutation,
} from 'redux/features/Orders';
import { setDetailOrderForChangeCourier, toggleModal } from 'redux/features/Utils';
import { ActionsButtonProductTable } from '../styles';
import { ModalAddShippingCost } from './ModalAddShippingCost';
import { ModalCancelFulfillment } from './ModalCancelFulfillment';
import { ModalCancelItemsV2 } from './ModalCancelItemsV2';
import { MenuItemStyled, MenuStyled } from './styles';
import { ModalRequestCancel } from './ModalRequestCancel';
import { useGetValueFeatureFlag } from 'app-config/useGetValueFeatureFlag';

const orderDeliveredState = ['delivered'];

export const ActionsProductTable = ({
    dataOrder,
    data,
    name,
    selectedRows,
    line_items,
    is_fulfillment,
    refetch,
    type,
    returnPreferences,
}) => {
    // la data puede ser la data del fulfillment si este existe, o en caso contrario sera la data de la logística
    // la dataOrder es la data del pedido en general
    const [menuOpen, setMenuOpen] = useState(false);
    const [hasDTETicket, setHasDTETicket] = useState(false);
    const [isAdminOrSac, setIsAdminOrSac] = useState(false);
    const [disabledButtonCreateFulfillment, setDisabledButtonCreateFulfillment] = useState(false);
    const [disableCreateFulfillmentClickValidation, setDisableCreateFulfillmentClickValidation] =
        useState(false);
    const [disabledButtonCancelFulfillment, setDisabledButtonCancelFulfillment] = useState(false);
    const [disabledButtonChangeFulfillmentStatus, setDisabledButtonChangeFulfillmentStatus] =
        useState(false);
    const [disabledButtonChangeLocation, setDisabledButtonChangeLocation] = useState(false);
    const [disabledButtonCancelItem, setDisabledButtonCancelItem] = useState(false);
    const [disabledButtonCancelItemByIntegration, setDisabledButtonCancelItemByIntegration] =
        useState(false);
    const [cancelItemV2] = useCancelItemV2Mutation();

    const activeDirectionColombia = useGetValueFeatureFlag('orders', 'cancellation_request');

    const [modalCancelCurrentData, setModalCancelCurrentData] = useState({
        comment: '',
        has_shipping_cost: false,
        purpose_annulment: '',
    });
    const user = useCurrentUser();
    const [permissions, isAdmin] = useGetCurrentPermissions();
    const isSac = permissions?.includes('sac');
    const dispatch = useDispatch();
    const url = `/orders/${dataOrder._id}/change-store?products_id=${selectedRows}`;
    const modalName = `items-cancellation-modal-${
        data._id ?? data.logistic_id ?? name ?? dataOrder?._id
    }`;
    const modalNameRequestCancel = `items-request-cancellation-modal-${
        data._id ?? data.logistic_id ?? name ?? dataOrder?._id
    }`;

    const [isOpenModalAddShippingCost, setIsOpenModalAddShippingCost] = useState(false);
    const [isOpenModalCancelFulfillment, setIsOpenModalCancelFulfillment] = useState(false);
    const [cancelByLogisticV2] = useCancelByLogisticV2Mutation();
    const [requestCancelItems] = useRequestCancelItemsMutation();
    const [requestCancelFulfillment] = useRequestCancelFulfillmentMutation();
    const [createFulfillmentByLogistic] = useCreateFulfillmentByLogisticMutation();
    const [cancelMethod, setCancelMethod] = useState('');
    const [, dataPickupsPreferences] = useGetPickupPreference();

    const orderHasFailedPickupIncidence = Array.from(dataOrder?.manual_incidences ?? [])?.some(
        (incidence) => incidence?.incidence_type === 'failed_pickup' && incidence?.status === 'open'
    );

    const buttonEnabled = (isAdmin || isSac) && dataOrder?.total_shipping > 0;
    const isCancelationFlow = dataOrder?.sales_channel?.allow_cancelation_flow;
    const isDevolution = ['delivered'].includes(data.status);
    const isDevolutionFlow = returnPreferences?.enable_return_approval_flow;

    useEffect(() => {
        setHasDTETicket(
            dataOrder?.logs?.filter((log) =>
                ['DTE_INVOICE_CREATED', 'DTE_TICKET_CREATED'].includes(log?.action)
            )?.length > 0
        );
        const usersWithPermissions = isAdmin || isSac;
        setIsAdminOrSac(usersWithPermissions);
    }, [dataOrder, permissions, isAdmin]);

    useEffect(() => {
        // Deshabilitar botón enviar a preparación
        // en la antigua página !(!fulfillment?._id && logistic?._id && order?.logistics_info?.length > 1) preguntar a pato después

        const userStore = user?.userData?.store_warehouse_id;
        const logisticStore = data?.location_id?._id;

        const userStoreIsLogisticStore = (userStore && userStore === logisticStore) || isAdmin;

        // esto sin el Array.from da pantalla blanca
        const lineItemsByData =
            data.line_item_ids ??
            Array.from(data?.line_items ?? [])
                .filter((lineItem) => lineItem?.line_item)
                .map((lineItem) => lineItem?.line_item);

        const allItemsCancel = Array.from(lineItemsByData ?? []).every(
            (lineItem) =>
                lineItem?.quantity ===
                (lineItem?.removed_quantity ?? 0) +
                    (lineItem?.refunded_quantity ?? 0) +
                    (lineItem?.cancelation_requested_quantity ?? 0)
        );

        if (
            name === 'product_table_fulfillment' ||
            // dataOrder?.fulfillments?.length === 1
            dataOrder?.logistics_info?.length ===
                (dataOrder?.logistics_info?.length === 1 &&
                    dataOrder?.fulfillments?.length === 1) ||
            !userStoreIsLogisticStore ||
            allItemsCancel
        ) {
            setDisabledButtonCreateFulfillment(true);
        } else {
            setDisabledButtonCreateFulfillment(false);
        }
        // Deshabilitar botón cancelar fulfillment
        if (
            ['delivered'].includes(data.status) ||
            ['cancelled', 'anulated', 'delivered'].includes(dataOrder?.fulfillment_status) ||
            ['cancelled', 'closed'].includes(dataOrder?.order_status) ||
            ((dataOrder?.fulfillments?.length ? dataOrder?.fulfillments?.length === 1 : true) &&
                (dataOrder?.logistics_info?.length
                    ? dataOrder?.logistics_info?.length === 1
                    : true)) ||
            !isAdminOrSac ||
            !hasDTETicket ||
            allItemsCancel
        ) {
            setDisabledButtonCancelFulfillment(true);
        } else {
            setDisabledButtonCancelFulfillment(false);
        }

        if (
            ['cancelled', 'anulated', 'closed', 'delivered', 'sac'].includes(data?.status) ||
            ['cancelled', 'anulated', 'delivered', 'sac'].includes(dataOrder?.fulfillment_status) ||
            ['cancelled', 'closed', 'sac'].includes(dataOrder?.order_status)
        ) {
            setDisabledButtonChangeFulfillmentStatus(true);
        } else {
            setDisabledButtonChangeFulfillmentStatus(false);
        }

        //  Deshabilitar botón cambio de sucursal
        const hasIncidence =
            // dataOrder?.manual_incidences?.length > 0 ||
            dataOrder?.line_items_manual_incidences?.length > 0;
        if (
            !hasIncidence ||
            ['delivered_to_courier', 'delivered'].includes(dataOrder?.fulfillment_status) ||
            selectedRows?.length === 0
        ) {
            setDisabledButtonChangeLocation(true);
        } else {
            setDisabledButtonChangeLocation(false);
        }

        //  Deshabilitar botón anular producto
        const itemWithPreDevolution = dataOrder?.line_items?.some(
            (item) => item?.pre_devolution_quantity
        );

        setDisabledButtonCancelItemByIntegration(
            !validationFlowCompletedInCancelation(dataOrder?.flows)
        );

        if (
            ['cancelled', 'anulated'].includes(dataOrder?.fulfillment_status) ||
            ['cancelled', 'closed'].includes(dataOrder?.order_status) ||
            !hasDTETicket ||
            !isAdminOrSac ||
            selectedRows?.length === 0 ||
            itemWithPreDevolution
        ) {
            setDisabledButtonCancelItem(true);
        } else {
            setDisabledButtonCancelItem(false);
        }
    }, [dataOrder, data, name, hasDTETicket, isAdminOrSac, selectedRows, user]);

    const handleToggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickCreateFulfillment = async () => {
        setDisableCreateFulfillmentClickValidation(true);

        const hasDteDocument = dataOrder?.logs?.some((log) =>
            ['DTE_TICKET_CREATED', 'DTE_INVOICE_CREATED'].includes(log?.action)
        );
        if (dataOrder?.sales_channel?.order_without_dte_cannot_prepared && !hasDteDocument) {
            toast.error(
                'El pedido/fulfillment no puede ser preparado debido a facturación electrónica pendiente'
            );

            setDisableCreateFulfillmentClickValidation(false);

            return;
        }
        await createFulfillmentByLogistic({
            order_id: dataOrder?._id,
            logistic_id: data?._id,
            assigned_operators: [],
        });

        setDisableCreateFulfillmentClickValidation(false);

        dispatch(ordersApi.util.invalidateTags(['Order']));
    };

    const createItemsToCancel = (items) => {
        return items.map((item) => {
            const lineItem = line_items.find(
                (li) => li._id === item._id || li.line_item?._id === item._id
            );

            return {
                id: item?._id,
                remote_variant_id: lineItem?.remote_variant_id,
                quantity: item.quantity,
                ean: lineItem?.ean,
                sku: lineItem?.sku,
            };
        });
    };

    const handleCancelItem = (params) => {
        console.log('shippingItemId', params);

        const shippingItemId = params?.has_shipping_cost
            ? dataOrder?.shipping_items?.find(
                  (item) => (item?.fulfillment_id ?? item?.logistic_id) === data?._id
              )?._id
            : undefined;
        console.log('shippingItemId', shippingItemId);

        const obj = {
            items: createItemsToCancel(params.items),
            order_id: dataOrder._id,
            // la verificación del fulfillmentId se hace para determinar si es o no fulfillment, podría usar is_fulfillment pero no sirve en realidad ya que discrimina solo si hay fulfillments pero puede haber 3 fulfillments y una logística y is_fulfillment seria true lo que no sirve en el caso de que el item que estemos anulando sea de una logística, la logística también tiene line_items pero se llaman line_items_ids
            fulfillment_id: data?._id,
            logistic_id: data?._id,
            merchant_id: line_items[0]?.merchant_id,
            comment: params?.comment,
            has_shipping_cost: params?.has_shipping_cost,
            purpose_annulment: params?.purpose_annulment,
            shipping_item_id: shippingItemId,
            is_devolution: isDevolution,
        };

        console.log('[handleCancelItem] obj', obj);

        if ((isCancelationFlow && !isDevolution) || (isDevolutionFlow && isDevolution)) {
            requestCancelItems(obj).then(() => refetch(true));
        } else {
            cancelItemV2(obj).then(() => refetch(true));
        }
        return;
    };

    const handleConfirmCancel = ({ hasShippingCost, transferLocationId }) => {
        console.log('[handleConfirmCancel] values', cancelMethod);
        if (cancelMethod === 'items') {
            handleCancelItem({
                comment: modalCancelCurrentData?.comment,
                has_shipping_cost: hasShippingCost,
                purpose_annulment: modalCancelCurrentData?.purpose_annulment,
                items: modalCancelCurrentData?.items,
            });
        }

        if (cancelMethod === 'fulfillment') {
            const shippingItemId = hasShippingCost
                ? dataOrder?.shipping_items?.find(
                      (item) => (item?.fulfillment_id ?? item?.logistic_id) === data?._id
                  )?._id
                : undefined;

            const body = {
                order_id: dataOrder?._id,
                fulfillment_id: data?._id,
                logistic_id: data?.logistic_id ?? data?._id,
                has_shipping_cost: hasShippingCost,
                comment: modalCancelCurrentData?.comment,
                purpose_annulment: modalCancelCurrentData?.purpose_annulment,
                items: modalCancelCurrentData?.items,
                shipping_item_id: shippingItemId,
                ...(transferLocationId && {
                    transfer_location_id: transferLocationId?.transfer_location_id,
                }),
            };

            if ((isCancelationFlow && !isDevolution) || (isDevolutionFlow && isDevolution)) {
                requestCancelFulfillment(body).then(() => refetch());
            } else {
                cancelByLogisticV2(body).then(() => refetch());
            }
        }

        setIsOpenModalAddShippingCost(false);
    };

    const handleClickCancelFulfillment = (delivery_method) => {
        setCancelMethod('fulfillment');

        if (
            dataPickupsPreferences?.delivery_options?.pickup
                ?.when_cancelation_is_through_oms_interface_require_destination_store_for_transfer_prism_sap_oms &&
            delivery_method === 'pickup' &&
            orderHasFailedPickupIncidence
        ) {
            setIsOpenModalCancelFulfillment(true);
            return;
        }

        if (shippingCost > 0) {
            setIsOpenModalAddShippingCost(true);
            return;
        }

        const body = {
            order_id: dataOrder?._id,
            logistic_id: data?.logistic_id ?? data?._id,
            has_shipping_cost: false,
            fulfillment_id: data?._id,
            comment: modalCancelCurrentData?.comment,
            purpose_annulment: modalCancelCurrentData?.purpose_annulment,
        };

        if ((isCancelationFlow && !isDevolution) || (isDevolutionFlow && isDevolution)) {
            requestCancelFulfillment(body);
        } else {
            cancelByLogisticV2(body);
        }
    };

    const handleClickCancelItem = () => {
        setCancelMethod('items');
        dispatch(toggleModal(modalName));
    };

    const handleClickRequestCancelItem = () => {
        dispatch(toggleModal(modalNameRequestCancel));
    };

    const handleOkCancelItem = ({
        has_shipping_cost,
        comment_to_cancel,
        purpose_annulment,
        items,
    }) => {
        if (has_shipping_cost) {
            setModalCancelCurrentData({
                comment: comment_to_cancel,
                has_shipping_cost: has_shipping_cost,
                purpose_annulment,
                items,
            });
            setIsOpenModalAddShippingCost(true);
            return;
        }

        handleCancelItem({
            comment: comment_to_cancel,
            has_shipping_cost: has_shipping_cost,
            purpose_annulment,
            items,
        });
    };

    const handleOkRequestCancelItem = ({
        has_shipping_cost,
        comment_to_cancel,
        purpose_annulment,
        items,
    }) => {
        setCancelMethod('items');

        if (has_shipping_cost) {
            setModalCancelCurrentData({
                comment: comment_to_cancel,
                has_shipping_cost: has_shipping_cost,
                purpose_annulment,
                items,
            });
            setIsOpenModalAddShippingCost(true);
            return;
        }

        handleCancelItem({
            comment: comment_to_cancel,
            has_shipping_cost: has_shipping_cost,
            purpose_annulment,
            items,
        });
    };

    const shippingCost = useMemo(() => {
        const shippingItems = dataOrder?.shipping_items;
        const withoutShippingCost = dataOrder?.total_shipping === 0;

        if (withoutShippingCost) {
            return dataOrder?.total_shipping;
        }

        if (shippingItems && shippingItems.length > 0) {
            const total = shippingItems?.reduce((acc, item) => {
                if ((item?.fulfillment_id ?? item?.logistic_id) === data?._id)
                    acc += item?.price ?? 0;

                return acc;
            }, 0);
            return total;
        }
        return 0;
    }, [dataOrder]);

    const disabledButton = useMemo(() => {
        const response = verifyDisabledButton({
            order_ids: [],
            orders: [dataOrder],
            fulfillment_id: dataOrder?.fulfillments?.length ? data._id : undefined,
            logistic_id:
                dataOrder?.logistics_info?.length && !dataOrder?.fulfillments?.length
                    ? data._id
                    : undefined,
            order_id: data._id ? undefined : dataOrder._id,
        });

        const lineItemsByData =
            data.line_item_ids ??
            Array.from(data?.line_items ?? [])
                .filter((lineItem) => lineItem?.line_item)
                .map((lineItem) => lineItem?.line_item);

        const allItemsCancel = Array.from(lineItemsByData ?? []).every(
            (lineItem) =>
                lineItem?.quantity ===
                (lineItem?.removed_quantity ?? 0) + (lineItem?.refunded_quantity ?? 0)
        );

        return response || allItemsCancel;
    }, [dataOrder]);

    const hasPermissionForChangeSLA = isAdmin || isSac;

    const menu = (
        <MenuStyled>
            {dataOrder?.logistics_info?.length > 1 && (
                <>
                    <span className="actions_product_table_title">Fulfillment</span>
                    <MenuItemStyled
                        key="1"
                        className="actions_product_table_option"
                        disabled={
                            disabledButtonCreateFulfillment ||
                            disableCreateFulfillmentClickValidation ||
                            disabledButtonCancelItemByIntegration
                        }
                        onClick={handleClickCreateFulfillment}
                    >
                        <Tooltip
                            title={
                                disabledButtonCancelItemByIntegration
                                    ? 'Acción deshabilitada por movimientos de integración pendiente'
                                    : ''
                            }
                        >
                            Enviar a preparación
                        </Tooltip>
                    </MenuItemStyled>

                    <MenuItemStyled
                        key="2"
                        className="actions_product_table_option"
                        disabled={disabledButtonChangeFulfillmentStatus}
                        onClick={() => {
                            setMenuOpen(false);
                            dispatch(toggleModal(`order-change-status-modal-${data?._id}`));
                        }}
                    >
                        Cambiar estado
                    </MenuItemStyled>

                    <MenuItemStyled
                        key="3"
                        className="actions_product_table_option"
                        disabled={
                            disabledButtonCancelFulfillment || disabledButtonCancelItemByIntegration
                        }
                        onClick={() => handleClickCancelFulfillment(data?.delivery_method)}
                    >
                        <Tooltip
                            title={
                                disabledButtonCancelItemByIntegration
                                    ? 'Acción deshabilitada por movimientos de integración pendiente'
                                    : ''
                            }
                        >
                            {isDevolutionFlow && isDevolution
                                ? 'Solicitar devolución del fulfillment'
                                : activeDirectionColombia && isCancelationFlow
                                ? 'Solicitar cancelación del fulfillment'
                                : 'Cancelar Fulfillment'}
                        </Tooltip>
                    </MenuItemStyled>

                    {/* Anular costo de envío */}
                    <MenuItemStyled
                        modalName={`removed-shipping-price-action-v2-${data._id}`}
                        className="actions_product_table_option"
                        onClick={() =>
                            dispatch(toggleModal(`removed-shipping-price-action-v2-${data._id}`))
                        }
                        disabled={
                            ['cancelled'].includes(dataOrder?.fulfillment_status) ||
                            dataOrder?.delivery_method === 'pickup' ||
                            !buttonEnabled ||
                            disabledButtonCancelItemByIntegration
                        }
                    >
                        <Tooltip
                            title={
                                disabledButtonCancelItemByIntegration
                                    ? 'Acción deshabilitada por movimientos de integración pendiente'
                                    : ''
                            }
                        >
                            {orderDeliveredState.includes(data.status) ? 'Devolver' : 'Anular'}{' '}
                            costo de envío
                        </Tooltip>
                    </MenuItemStyled>

                    {isAdmin && (
                        <MenuItemStyled
                            key="3"
                            className="actions_product_table_option"
                            disabled={disabledButton}
                            onClick={() => {
                                dispatch(toggleModal('orders-change-couriers'));
                                dispatch(
                                    setDetailOrderForChangeCourier({
                                        fulfillment_id: dataOrder?.fulfillments?.length
                                            ? data._id
                                            : undefined,
                                        logistic_id:
                                            dataOrder?.logistics_info?.length &&
                                            !dataOrder?.fulfillments?.length
                                                ? data._id
                                                : undefined,
                                        order_id: data._id ? undefined : dataOrder._id,
                                    })
                                );
                            }}
                        >
                            Cambiar Courier/Tipo de servicios
                        </MenuItemStyled>
                    )}

                    <ChangeSlaDate
                        refetch={refetch}
                        order={dataOrder}
                        buttonProps={{
                            // buttonText: 'Cambiar fecha de entrega',
                            buttonText: 'Cambiar fecha de entrega',
                            ghost: true,
                            className: 'actions_product_table_option',
                            style: {
                                border: 'none',
                                background: 'none',
                                fontSize: '12px',
                                paddingLeft: '20px',
                            },
                            disabled:
                                !hasPermissionForChangeSLA ||
                                ['delivered', 'delivered_to_courier'].includes(data?.status),
                        }}
                        isFulfillment={is_fulfillment}
                        fulfillment={data}
                    />

                    <hr />
                </>
            )}
            <span className="actions_product_table_title">Productos</span>

            <MenuItemStyled
                key="3"
                className="actions_product_table_option"
                disabled={disabledButtonChangeLocation || disabledButtonCancelItemByIntegration}
                onClick={() => history.push(`${url}&fulfillment_id=${data?._id}`)}
            >
                Cambio de sucursal
            </MenuItemStyled>

            {(activeDirectionColombia && isCancelationFlow && !isDevolution) ||
            (isDevolutionFlow && isDevolution) ? (
                <MenuItemStyled
                    key="3"
                    className="actions_product_table_option"
                    onClick={() => handleClickRequestCancelItem()}
                    disabled={
                        disabledButtonCancelItem ||
                        disabledButtonCancelItemByIntegration ||
                        type === 'multiple'
                    }
                >
                    <Tooltip
                        title={
                            disabledButtonCancelItemByIntegration
                                ? 'Acción deshabilitada por movimientos de integración pendiente'
                                : type === 'multiple'
                                ? 'No es posible ejecutar la acción debido a que está seleccionando múltiples funciones al mismo tiempo'
                                : ''
                        }
                    >
                        Solicitar {isDevolution ? 'devolución' : 'cancelación'} de producto(s)
                    </Tooltip>
                </MenuItemStyled>
            ) : (
                <MenuItemStyled
                    key="3"
                    className="actions_product_table_option"
                    onClick={() => handleClickCancelItem()}
                    disabled={
                        disabledButtonCancelItem ||
                        disabledButtonCancelItemByIntegration ||
                        type === 'multiple'
                    }
                >
                    <Tooltip
                        title={
                            disabledButtonCancelItemByIntegration
                                ? 'Acción deshabilitada por movimientos de integración pendiente'
                                : type === 'multiple'
                                ? 'No es posible ejecutar la acción debido a que está seleccionando múltiples funciones al mismo tiempo'
                                : ''
                        }
                    >
                        {['delivered'].includes(
                            (is_fulfillment && data?.status) || dataOrder?.fulfillment_status
                        ) &&
                        data?.preparationcd_transfer_pickupstore?.status !==
                            'delivered_to_pickup_location'
                            ? 'Devolver producto'
                            : 'Anular producto'}
                    </Tooltip>
                </MenuItemStyled>
            )}
        </MenuStyled>
    );

    return (
        <>
            <Dropdown
                className="order_page_product_table_actions"
                overlay={menu}
                visible={menuOpen}
                onVisibleChange={handleToggleMenu}
                disabled={data?.status === 'cancelled'}
            >
                <div>
                    <ActionsButtonProductTable
                        disabled={data?.status === 'cancelled'}
                        icon={
                            <MdKeyboardArrowDown className="order-page__product-table-icon-actions" />
                        }
                        iconPosition="right"
                    >
                        <span>Acciones</span>
                    </ActionsButtonProductTable>
                </div>
            </Dropdown>

            <ModalAddShippingCost
                isOpen={isOpenModalAddShippingCost}
                onClose={() => setIsOpenModalAddShippingCost(false)}
                shippingCost={shippingCost}
                isCancelationFlow={isCancelationFlow}
                onOK={() =>
                    handleConfirmCancel({
                        hasShippingCost: true,
                    })
                }
                onCancel={() =>
                    handleConfirmCancel({
                        hasShippingCost: false,
                    })
                }
                isRefund={orderDeliveredState.includes(data?.status)}
            />

            <ModalCancelFulfillment
                isOpen={isOpenModalCancelFulfillment}
                onClose={() => setIsOpenModalCancelFulfillment(false)}
                onOK={(transferLocationId) =>
                    handleConfirmCancel({
                        transferLocationId,
                    })
                }
                onCancel={() =>
                    handleConfirmCancel({
                        hasShippingCost: false,
                    })
                }
                cancelationRequireTransferLocation={
                    data?.delivery_method === 'pickup' &&
                    orderHasFailedPickupIncidence &&
                    dataPickupsPreferences?.delivery_options?.pickup
                        ?.when_cancelation_is_through_oms_interface_require_destination_store_for_transfer_prism_sap_oms
                }
            />

            <ModalCancelItemsV2
                onConfirmCancelItem={handleOkCancelItem}
                lineItems={line_items.map((item) => ({ ...item, ...(item.line_item ?? {}) }))}
                lineItemsManualIncidences={dataOrder?.line_items_manual_incidences ?? []}
                modalName={modalName}
                order={dataOrder}
                fulfillment={data}
            />

            <ModalRequestCancel
                onConfirmCancelItem={handleOkRequestCancelItem}
                lineItems={line_items.map((item) => ({ ...item, ...(item.line_item ?? {}) }))}
                lineItemsManualIncidences={dataOrder?.line_items_manual_incidences ?? []}
                modalName={modalNameRequestCancel}
                order={dataOrder}
                fulfillment={data}
            />

            <RemoveShippingPrice
                order_id={dataOrder?._id}
                id={data._id}
                total_shipping={dataOrder?.total_shipping}
                this_shipping_cost={
                    dataOrder?.shipping_items?.find(
                        (shippingItem) =>
                            (shippingItem?.fulfillment_id ?? shippingItem?.logistic_id) ===
                            data?._id
                    )?.price ?? 0
                }
                isRefund={orderDeliveredState.includes(data?.status)}
                refetch={refetch}
                modalName={`removed-shipping-price-action-v2-${data._id}`}
            />

            <ChangeOrderStatus
                refetch={refetch}
                order={dataOrder}
                fulfillmentOrLogisticId={data?._id}
                fulfillment={is_fulfillment ? data : null}
                modalName={`order-change-status-modal-${data?._id}`}
            />
        </>
    );
};
