import React, { useState, useEffect, Suspense, useMemo } from 'react';
import { Divider, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useGetUserPreferencesQuery } from 'redux/features/Users';
import InfoFilter from 'components/V2/Filters/InfoFilter';
import {
    removeFilter,
    clearFilters,
    overrideFilters,
    setValueOptionByField,
} from 'redux/features/Crossdocking/crossdockingFilters';
import { setCrossdockingFilters, toggleModal } from 'redux/features/Utils';
import useCurrentUser from 'hooks/useCurrentUser';
import Container from 'components/Grid/Container';
import PageHeader from 'components/Layout/PageHeader';
import Flex from 'components/Utils/Flex';
import LocationPicker from 'components/V2/LocationPicker';
import { getDateFilterToSend } from 'components/V2/DatePicker/utils';
import ViewTabs from 'components/V2/ViewTabs';
import SearchInput from 'components/V2/SearchInput';
import FilterButton from 'components/V2/Buttons/FilterButton';
import ColumnsButton from 'components/V2/Buttons/ColumnsButton';
import ShowQuantityItems from 'components/V2/ShowQuantityItems';
import CrossdockingActions from './CrossdockingActions';
import { defaultColumnsView, INITIAL_PAGE_SIZE } from './config';
import store from '../../redux';
import { promisedDateRanges } from 'data/promised_date_ranges';
import {
    useLazyGetOrdersAllIdsByFiltersQuery,
    useLazyGetOrdersByFiltersQuery,
    useProcessShipmentMutation,
    useSendOrdersToDeliveriesMutation,
} from 'redux/features/Orders';
import { deliveryMethodOptions, fulfillmentStatusOptions } from './components/Filters';
import useAllSalesChannel from 'hooks/useAllSalesChannel';
import { removeAccents } from 'helpers/removeAccents';
import useAllShops from 'hooks/useAllShops';
import useAllCouriers from 'hooks/useAllCouriers';
import { SendDeliveryModal } from './components/SendDeliveryModal';
import { RangePicker } from 'components/RangePicker';
import { useLocation } from 'react-router-dom';

const DraggableTable = React.lazy(() => import('./Table/DraggableTable'));

const Crossdocking = () => {
    const PREFERENCES = 'crossdocking';
    const modalOrdersToDeliveryTicketsConfirmationName = 'orders-to-delivery-tickets-confirmation';

    // Redux queries
    const {
        data: userPreferences,
        isLoading: userLoading,
        isFetching: userFetching,
    } = useGetUserPreferencesQuery();

    const [getOrders, { data, isLoading, isFetching }] = useLazyGetOrdersByFiltersQuery({
        refetchOnMountOrArgChange: true,
    });
    const [getOrdersAllIds, { data: dataIds }] = useLazyGetOrdersAllIdsByFiltersQuery({
        refetchOnMountOrArgChange: true,
    });
    const params = useLocation();

    // eslint-disable-next-line no-unused-vars
    const [processShipment, { isLoading: isLoadingProcessShipment }] = useProcessShipmentMutation();
    // Redux state and get data from local storage
    const { filters, valueOptionByField } = useSelector((state) => {
        return state.crossdockingFilters;
    });

    // const { massiveFulfillmentPreparation } = useSelector((state) => state.utils);
    const { userData } = useCurrentUser();
    const userDataLocalStorage = JSON.parse(localStorage.getItem('userLogged') ?? '{}') ?? null;
    const objectDateToSend = getDateFilterToSend(
        userDataLocalStorage?.orders_configuration?.default_date_orders_filter,
        userDataLocalStorage?.orders_configuration?.default_date_orders_filter_custom
    );
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState('all');
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [ordersSelected, setOrdersSelected] = useState([]);
    const [hasStatusAllowedRowProcessShipment, setHasStatusAllowedRowProcessShipment] =
        useState(false);
    const [hasStatusAllowedRowSendToDeliveries, setHasStatusAllowedRowSendToDeliveries] =
        useState(false);
    const [tabColumns, setTabColumns] = useState(defaultColumnsView);
    const [tablePageSize, setTablePageSize] = useState(INITIAL_PAGE_SIZE);
    const [selectedDateRange, setSelectedDateRange] = useState({
        fromDate: objectDateToSend?.start ?? dayjs().format('YYYY-MM-DD'),
        toDate: objectDateToSend?.end ?? dayjs().format('YYYY-MM-DD'),
    });
    const [fulfillmentUrlParams, setFulfillmentUrlParams] = useState({});
    const [, setSelectedLocation] = useState([userData?.store_warehouse_id || 'all']);
    const [massiveFulfillmentCrossdocking, setMassiveFulfillmentCrossdocking] = useState([]);

    const [scanMode, setScanMode] = useState(false);
    const [expandedKeys, setExpandedKeys] = useState([]);

    const [salesChannelsLoading, salesChannels] = useAllSalesChannel();
    const [locationsLoading, locations] = useAllShops();
    const [couriersLoading, couriers] = useAllCouriers();
    const [sendOrdersToDelivery] = useSendOrdersToDeliveriesMutation();

    const loading =
        isLoading ||
        isFetching ||
        userLoading ||
        userFetching ||
        salesChannelsLoading ||
        locationsLoading ||
        couriersLoading;

    const handleOrdersRefetch = (forceRefetch) => {
        // refetch();
        const utils = store.getState().utils;

        const filters = utils.crossdockingFilters;
        getOrders(
            {
                ...filters,
                orderFilters: filters.filters,
            },
            !forceRefetch
        );
        getOrdersAllIds(
            {
                ...filters,
                orderFilters: filters.filters,
            },
            !forceRefetch
        );
    };
    // Funcion para actualizar el estado de la tab seleccionada a traves de los hijos
    const handleChangeTab = (tabValue) => {
        const selectedTabIndex = userPreferences?.preferences[PREFERENCES]?.tabs?.findIndex(
            (tab) => tab.value === tabValue
        );
        setSelectedTab(tabValue);
        setSelectedTabIndex(
            selectedTabIndex === -1
                ? userPreferences?.preferences[PREFERENCES]?.tabs?.length
                : selectedTabIndex
        );
        const filtersToOverride = structuredClone(
            userPreferences?.preferences[PREFERENCES]?.tabs[selectedTabIndex]?.filters ?? []
        );
        filtersToOverride.forEach((filter) => {
            filter.compromiseValues = valueOptionByField[filter.field];
        });
        dispatch(overrideFilters(filtersToOverride));
        // Despachamos los filtros para que se apliquen
        dispatch(
            setCrossdockingFilters({
                filters: [
                    ...(filtersToOverride?.map((filter) => ({
                        andOr: filter.andOr,
                        field: filter.field,
                        condition: filter.condition,
                        value: filter.value,
                    })) ?? []),
                ],
                page: 1,
            })
        );
        // refetch();
        handleOrdersRefetch();
    };
    const handleChangeDateRange = (updatedDateRange) => {
        setSelectedDateRange(updatedDateRange);
        dispatch(
            setCrossdockingFilters({
                date_range: [updatedDateRange?.fromDate, updatedDateRange?.toDate],
                page: 1,
            })
        );
        handleOrdersRefetch();
    };
    const handleChangeLocations = (updatedLocation) => {
        setSelectedLocation(updatedLocation);
        dispatch(
            setCrossdockingFilters({
                location_id: updatedLocation,
                page: 1,
            })
        );
        handleOrdersRefetch();
    };
    const handleChangeTabColumns = (updatedColumns) => {
        setTabColumns(updatedColumns);
    };
    const handleChangePageSize = (sizePage) => {
        setTablePageSize(sizePage);
    };

    const handleSelectRows = (ids, selectedRows) => {
        const hasStatusAllowedProcessShipment = selectedRows.every((order) => {
            const fulfillmentsFiltered = order?.fulfillments?.filter((f) => f.line_items?.length);
            const isFulfilledStatus = order?.fulfillment_status === 'fulfilled';
            const hasAllFulfillmentInCdLocation = fulfillmentsFiltered?.every(
                (ful) => ful.consolidation?.status === 'received_in_cd_location'
            );
            const hasFulfillmentLabels = fulfillmentsFiltered?.some(
                (ful) =>
                    !ful?.consolidation?.labels?.pdf &&
                    ful.consolidation?.labels?.status !== 'loading'
            );
            return isFulfilledStatus && hasAllFulfillmentInCdLocation && hasFulfillmentLabels;
        });
        const hasStatusAllowedSendDelivery = selectedRows.every((order) => {
            const fulfillmentsFiltered = order?.fulfillments?.filter((f) => f.line_items?.length);

            const isFulfilledStatus = order?.fulfillment_status === 'fulfilled';

            return (
                isFulfilledStatus &&
                fulfillmentsFiltered?.every(
                    (ful) =>
                        !!ful?.consolidation?.labels?.pdf &&
                        ful?.consolidation?.labels?.status !== 'loading' &&
                        ful.consolidation.status !== 'ready_for_deliver'
                )
            );
        });

        setHasStatusAllowedRowProcessShipment(hasStatusAllowedProcessShipment);
        setHasStatusAllowedRowSendToDeliveries(hasStatusAllowedSendDelivery);
        setOrdersSelected(ids);
    };

    const handleChangeColumns = (columns) => {
        setTabColumns(columns);
    };
    const handleDeleteFilter = (filterId) => {
        if (fulfillmentUrlParams?.fulfillment_id) {
            setFulfillmentUrlParams({});
        }
        dispatch(removeFilter(filterId));
        dispatch(
            setCrossdockingFilters({
                filters: filters
                    ?.filter((filter) => filter.id !== filterId)
                    .map((filter) => ({
                        andOr: filter.andOr,
                        field: filter.field,
                        condition: filter.condition,
                        value: filter.value,
                    })),
                page: 1,
            })
        );
        handleOrdersRefetch(true);
    };

    const handleSendToDeliveryModal = (id) => {
        dispatch(toggleModal(modalOrdersToDeliveryTicketsConfirmationName));
        setOrdersSelected([id]);
    };

    const handleConfirmSendToDelivery = () => {
        sendOrdersToDelivery({
            order_ids: ordersSelected,
        }).then(() => {
            handleOrdersRefetch(true);
        });
    };

    const handleProcessShipment = (orderId) => {
        if (isLoadingProcessShipment) return;
        processShipment({
            order_ids: orderId ? [orderId] : ordersSelected,
        }).then(() => {
            handleOrdersRefetch(true);
            setOrdersSelected([]);
            setTimeout(() => {
                handleOrdersRefetch(true);
            }, 3000);
        });
    };

    const searchFulfillmentByUrlParams = (fulfillmentId, remoteOrderId) => {
        const state = store.getState();
        const utils = state.utils;
        const filters = utils.crossdockingFilters;
        const filter = {
            andOr: '',
            condition: 'is',
            field: 'remote_order_id',
            value: remoteOrderId,
            consolidation: false,
            isRemovable: true,
            id: 'fulfillment_url_params',
            compromiseValues: [],
        };
        setFulfillmentUrlParams({
            fulfillment_id: fulfillmentId,
            remote_order_id: remoteOrderId,
        });
        dispatch(overrideFilters([filter]));

        getOrders(
            {
                ...filters,
                orderFilters: [filter],
                location_id: 'all',
                page: 1,
                date_range: [],
            },
            false
        ).then(() => {});
        getOrdersAllIds(
            {
                ...filters,
                orderFilters: [filter],
                location_id: 'all',
                page: 1,
                date_range: [],
            },
            false
        );
        // window.history.replaceState(null, null, window.location.pathname);
    };
    const handleOnInit = () => {
        const searchParams = new URLSearchParams(params.search);
        const fulfillmentId = searchParams.get('fulfillment_id');
        const remoteOrderId = searchParams.get('remote_order_id');
        if (fulfillmentId && remoteOrderId) {
            searchFulfillmentByUrlParams(fulfillmentId, remoteOrderId);
            return;
        }
    };
    useEffect(() => {
        const tab = userPreferences?.preferences[PREFERENCES]?.tabs?.find(
            (tab) => tab.value === selectedTab
        );
        if (!tab || tab?.typeOfTab === 'basic') {
            setTabColumns(defaultColumnsView);
            return;
        }
        setTabColumns(tab.columns);
    }, [selectedTab, selectedTabIndex]);
    // Para actualizar con el punto
    const forceRefetchWithButton = (event) => {
        const key = event?.key;
        if (key === '.') {
            handleOrdersRefetch(true);
        }
    };
    useEffect(() => {
        document.addEventListener('keypress', forceRefetchWithButton);
        return () => {
            document.removeEventListener('keypress', forceRefetchWithButton, true);
            dispatch(clearFilters());
            dispatch(setCrossdockingFilters({}));
        };
    }, []);

    useEffect(() => {
        if (loading) return;
        const fieldValuesObj = {
            promised_date_range: promisedDateRanges,
        };
        dispatch(setValueOptionByField(fieldValuesObj));
    }, [loading]);

    useEffect(() => {
        if (Array.from(massiveFulfillmentCrossdocking ?? []).length > 0) {
            setOrdersSelected(massiveFulfillmentCrossdocking.map((item) => item._id));
        }
    }, [massiveFulfillmentCrossdocking]);

    useEffect(() => {
        if (!scanMode) {
            setOrdersSelected([]);
            setMassiveFulfillmentCrossdocking([]);
        }
    }, [scanMode]);

    useEffect(() => {
        setOrdersSelected([]);
    }, [selectedTab, selectedTabIndex, filters, selectedDateRange, scanMode]);

    useEffect(() => {
        if (loading) return;
        dispatch(
            setValueOptionByField({
                fulfillment_status: fulfillmentStatusOptions,
                sales_channel: salesChannels.map((sc) => ({
                    label: sc?.alias ?? sc?.integration?.api_name,
                    value: sc?._id,
                })),
                location_id: [{ label: 'Todas', value: 'all' }].concat(
                    Array.from(locations ?? [])
                        .filter((l) => l.is_enabled)
                        ?.sort((a, b) =>
                            removeAccents(a.name.toUpperCase()).localeCompare(
                                removeAccents(b.name.toUpperCase())
                            )
                        )
                        .map((location) => ({
                            label: location?.name || '',
                            value: location._id,
                        }))
                ),
                delivery_method: deliveryMethodOptions,
                assigned_courier: couriers.map((courier) => ({
                    label: courier?.public_name ?? courier?.courier_name ?? '',
                    value: courier?._id,
                })),
                promised_date_range: promisedDateRanges,
            })
        );
    }, [loading]);

    useEffect(() => {
        handleOnInit();
    }, []);

    const filtersToShow = useMemo(() => {
        return filters.filter((filter) => filter.field);
    }, [filters]);

    return (
        <Container extraTitle="Crossdocking">
            <PageHeader title="Crossdocking" withMarginBottom={false} />
            <p style={{ margin: '0.5rem 0 0.2rem' }}>Filtrar métricas y tabla por:</p>
            <Flex direction="row" columnGap="1rem" paddingBottom="2rem">
                <RangePicker loading={loading} setDateCalendar={handleChangeDateRange} />
                <LocationPicker handleChangeLocations={handleChangeLocations} loading={loading} />
            </Flex>
            {/* Vistas personalizadas */}
            <ViewTabs
                userPreferences={userPreferences}
                selectedTab={selectedTab}
                handleChangeTab={handleChangeTab}
                preferences={PREFERENCES}
            />
            {/* Searchbar y botones de acciones */}
            <Flex
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                // marginBottom="1.5rem"
                padding="1rem 0 2rem"
            >
                <Flex direction="column" rowGap="0.5rem">
                    <Flex direction="row" gap="1rem">
                        <Switch onChange={(scan) => setScanMode(scan)} checked={scanMode} />
                        <span style={{ fontSize: '14px' }}>
                            Modo scan {scanMode ? 'habilitado' : 'deshabilitado'}
                        </span>
                    </Flex>
                    <SearchInput
                        stateSearchKey="search_by_order"
                        refetch={handleOrdersRefetch}
                        dispatchersType={PREFERENCES}
                        disabled={loading}
                        scanMode={scanMode}
                        setMassiveFulfillmentPendingArrival={setMassiveFulfillmentCrossdocking}
                        massiveFulfillmentPendingArrival={massiveFulfillmentCrossdocking}
                        name="crossdocking-search-input"
                    />
                </Flex>
                <Flex gap="1rem">
                    <FilterButton
                        userPreferences={userPreferences}
                        selectedTab={selectedTab}
                        selectedTabIndex={selectedTabIndex}
                        loading={loading}
                        refetch={handleOrdersRefetch}
                        data={data}
                        modalName="crossdocking-filter-modal"
                        formName="crossdocking-filter-form"
                        filters={filters}
                        valueOptionByField={valueOptionByField}
                        dispatchersType={PREFERENCES}
                    />
                    <ColumnsButton
                        userPreferences={userPreferences}
                        selectedTab={selectedTab}
                        selectedTabIndex={selectedTabIndex}
                        loading={loading}
                        userFetching={userFetching}
                        tabColumns={tabColumns}
                        handleChangeTabColumns={handleChangeTabColumns}
                        formName="crossdocking-column-form"
                        modalName="crossdocking-column-modal"
                        dispatchersType={PREFERENCES}
                    />
                    <ShowQuantityItems
                        tablePageSize={tablePageSize}
                        handleChangePageSize={handleChangePageSize}
                        disabled={loading}
                        dispatchersType={PREFERENCES}
                        refetch={handleOrdersRefetch}
                    />
                    <CrossdockingActions
                        hasStatusAllowedRowSendToDeliveries={hasStatusAllowedRowSendToDeliveries}
                        processShipment={handleProcessShipment}
                        hasStatusAllowedRowProcessShipment={hasStatusAllowedRowProcessShipment}
                        disabled={loading || ordersSelected.length < 1}
                        selected={ordersSelected}
                        data={data}
                    />
                </Flex>
            </Flex>
            <SendDeliveryModal
                ordersSelected={ordersSelected}
                onCancel={() => {
                    setOrdersSelected([]);
                }}
                sentToDelivery={handleConfirmSendToDelivery}
            />
            {/* Filtros seleccionados */}
            <Flex gap="0.75rem" marginBottom="1.5rem">
                <span>Filtros: </span>
                {filtersToShow.length === 0 ? (
                    <InfoFilter loading={loading} />
                ) : (
                    filtersToShow?.map((filter) => (
                        <InfoFilter
                            key={filter.id}
                            filter={filter}
                            onDelete={handleDeleteFilter}
                            loading={loading}
                            showDeleteIcon
                            preferences={PREFERENCES}
                            valueOptionByField={valueOptionByField}
                        />
                    ))
                )}
            </Flex>
            <Flex direction="row" alignItems="center">
                {ordersSelected.length === 0 ? (
                    <b style={{ display: 'block' }} className="text-sm">
                        No tienes pedidos seleccionados
                    </b>
                ) : (
                    <b style={{ display: 'block' }} className="text-sm">
                        En esta vista tienes seleccionado{' '}
                        <strong>
                            {ordersSelected.length} pedido(s) de {data?.totalDocs}
                        </strong>{' '}
                        {filtersToShow.length > 0 && '(Aplican filtros)'}
                    </b>
                )}
                <Divider type="vertical" style={{ marginBottom: '25px' }} />
                {data?.totalDocs > 0 && data?.totalPages > 1 && !!dataIds?.ids.length && (
                    <b
                        className="text-sm"
                        role="button"
                        onClick={() => {
                            if (ordersSelected.length === data?.totalDocs) {
                                setOrdersSelected([]);
                            } else {
                                setOrdersSelected(dataIds?.ids);
                            }
                        }}
                        style={{ display: 'block' }}
                    >
                        <u>
                            {data?.totalDocs === ordersSelected.length
                                ? 'Deseleccionar todos'
                                : 'Seleccionar todos'}{' '}
                            ({data?.totalDocs})
                        </u>
                    </b>
                )}
            </Flex>
            {/* Table */}
            <Suspense fallback={<></>}>
                <DraggableTable
                    fulfillmentUrlParams={fulfillmentUrlParams}
                    data={data}
                    loading={loading}
                    processShipment={handleProcessShipment}
                    userPreferences={userPreferences}
                    selectedTab={selectedTab}
                    refetch={handleOrdersRefetch}
                    selectedRows={ordersSelected}
                    handleSelectRows={handleSelectRows}
                    tabColumns={tabColumns}
                    handleChangeColumns={handleChangeColumns}
                    preferences={PREFERENCES}
                    massiveFulfillmentPreparation={massiveFulfillmentCrossdocking}
                    scanMode={scanMode}
                    expandedKeys={expandedKeys}
                    setExpandedKeys={setExpandedKeys}
                    onClickSendToDelivery={handleSendToDeliveryModal}
                />
            </Suspense>
        </Container>
    );
};
export default Crossdocking;
