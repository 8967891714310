import { createSlice } from '@reduxjs/toolkit';
import { getDateFilterToSend } from 'components/V2/DatePicker/utils';

const userData = JSON.parse(localStorage.getItem('userLogged') ?? '{}');

const userLocationId = userData?.store_warehouse_id;

const dateRange = getDateFilterToSend(
    userData?.orders_configuration?.default_date_orders_filter ?? 'today',
    userData?.orders_configuration?.default_date_orders_filter_custom ?? []
);

const sortHandler = (sort) => {
    if (sort === 'sla_asc') {
        return {
            sortBy: 'promised_date',
            sortValue: 'ascend',
        };
    }

    if (sort === 'order_desc') {
        return {
            sortBy: 'createdAt',
            sortValue: 'descend',
        };
    }

    return {
        sortBy: 'promised_date',
        sortValue: 'ascend',
    };
};

const sortHandling = sortHandler(
    userData?.orders_configuration?.default_orders_filter ?? 'sla_asc'
);

const initialOrderFilters = {
    sort: {
        sortBy: sortHandling?.sortBy,
        sortValue: sortHandling?.sortValue,
    },
    date_range: [dateRange?.start, dateRange?.end],
    delivery_method: [],
    featured_filters: [],
    fulfillment_status: [],
    limit: 25,
    location_id: userLocationId ? [userLocationId] : [],
    page: 1,
    price_range: [],
    priority: true,
    search_by_order: '',
    user_id: [],
};

const initialOrdersV3Filters = {
    sort: {
        sortBy: sortHandling.sortBy,
        sortValue: sortHandling.sortValue,
    },
    date_range: [dateRange?.start, dateRange?.end],
    searchTerm: '',
    limit: 25,
    page: 1,
    filters: [],
    origin: userLocationId ? [userLocationId] : [],
    view: 'orders',
    searchBefore: '',
    searchAfter: '',
};

const initialPreparationFilters = {
    sort: {
        sortBy: sortHandling.sortBy,
        sortValue: sortHandling.sortValue,
    },
    date_range: [dateRange?.start, dateRange?.end],
    searchTerm: '',
    limit: 25,
    page: 1,
    filters: [],
    origin: userLocationId ? [userLocationId] : [],
    view: 'preparations',
};

const initialPendingArrivalFilters = {
    sort: {
        sortBy: sortHandling.sortBy,
        sortValue: sortHandling.sortValue,
    },
    date_range: [dateRange?.start, dateRange?.end],
    searchTerm: '',
    limit: 25,
    page: 1,
    filters: [],
    pickup_location: userLocationId ? [userLocationId] : [],
    view: 'pending_arrival',
    preparationcd_transfer_pickupstore: [
        // 'created',
        // 'ready_for_deliver_to_pickup_location',
        // 'delivered_to_pickup_location',
        // 'partially_received_in_pickup_location',
    ],
    // view: 'pending_arrival',
};

const initialCrossdockingFilters = {
    sort: {
        sortBy: sortHandling.sortBy,
        sortValue: sortHandling.sortValue,
    },
    date_range: [dateRange?.start, dateRange?.end],
    searchTerm: '',
    limit: 25,
    page: 1,
    filters: [],
    location_id: userLocationId ? [userLocationId] : [],
    view: 'crossdocking',
};

const initialManifestsFilters = {
    sort: {
        sort_by: sortHandling.sortBy,
        sort_value: sortHandling.sortValue,
    },
    date_range: [dateRange?.start, dateRange?.end],
    searchTerm: '',
    limit: 25,
    page: 1,
    filters: [],
    location: userLocationId ? [userLocationId] : [],
};

const initialDeliveriesFilters = {
    sort: {
        sortBy: sortHandling.sortBy,
        sortValue: sortHandling.sortValue,
    },
    date_range: [dateRange?.start, dateRange?.end],
    searchTerm: '',
    limit: 25,
    page: 1,
    filters: [
        {
            andOr: '',
            field: 'status',
            condition: 'not_include',
            value: ['delivered', 'delivered_to_courier'],
        },
    ],
    origin: userLocationId ? [userLocationId] : [],
    view: 'deliveries',
};

const initialErrorsFilters = {
    sort: {},
    search_terms: undefined,
    status: ['pending'],
    limit: 50,
    page: 1,
};

export const utilsSlice = createSlice({
    name: 'utils',
    initialState: {
        sidebarCollapsed: true,
        themeName: localStorage.getItem('theme') || 'light',
        drawer: {},
        modal: {},
        ordersFilters: {
            ...initialOrderFilters,
        },
        ordersV3Filters: {
            ...initialOrdersV3Filters,
        },
        preparationsFilters: {
            ...initialPreparationFilters,
        },
        deliveriesFilters: {
            ...initialDeliveriesFilters,
        },
        pendingArrivalsFilters: {
            ...initialPendingArrivalFilters,
        },
        crossdockingFilters: {
            ...initialCrossdockingFilters,
        },
        errorsFilters: {
            ...initialErrorsFilters,
        },
        externallyUpdatedFilters: true,
        selectedRows: {},
        multiplePreparation: [],
        massiveFulfillmentPreparation: [],
        massiveFulfillmentDelivery: [],
        massiveManifestsToProcess: [],
        settingsContentActive: 'general',
        fulfillmentsViewScanMode: false,
    },

    reducers: {
        toggleSidebar: (state) => {
            return {
                ...state,
                sidebarCollapsed: !state.sidebarCollapsed,
            };
        },
        changeTheme: (state) => {
            const themeToActive = state.themeName === 'light' ? 'dark' : 'light';
            localStorage.setItem('theme', themeToActive);
            return {
                ...state,
                themeName: themeToActive,
            };
        },
        toggleDrawer: (state, action) => {
            return {
                ...state,
                drawer: {
                    ...state.drawer,
                    [action.payload]: !state.drawer[action.payload],
                },
            };
        },
        openDrawer: (state, action) => {
            return {
                ...state,
                drawer: {
                    ...state.drawer,
                    [action.payload]: true,
                },
            };
        },
        closeDrawer: (state, action) => {
            return {
                ...state,
                drawer: {
                    ...state.drawer,
                    [action.payload]: false,
                },
            };
        },
        toggleModal: (state, action) => {
            return {
                ...state,
                modal: {
                    ...state.modal,
                    [action.payload]: !state.modal[action.payload],
                },
            };
        },
        openModal: (state, action) => {
            return {
                ...state,
                modal: {
                    ...state.modal,
                    [action.payload]: true,
                },
            };
        },
        closeModal: (state, action) => {
            return {
                ...state,
                modal: {
                    ...state.modal,
                    [action.payload]: false,
                },
            };
        },
        setSelectedRows: (state, action) => {
            const { payload } = action;
            const { selectedRows, tableName } = payload;
            return {
                ...state,
                selectedRows: {
                    ...state.selectedRows,
                    [tableName]: [...selectedRows],
                },
            };
        },
        setFulfillmentForPreparation: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                multiplePreparation: [...payload],
            };
        },
        removeFromFulfillmentForPreparation: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                multiplePreparation: state.multiplePreparation.filter((item) => item !== payload),
            };
        },
        setFulfillmentForDelivery: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                multipleDelivery: [...payload],
            };
        },
        removeFromFulfillmentForDelivery: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                multipleDelivery: state.multipleDelivery.filter((item) => item !== payload),
            };
        },
        clearSelectedRows: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                selectedRows: {
                    ...state.selectedRows,
                    [payload]: [],
                },
            };
        },
        setSettingContentActive: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                settingsContentActive: payload.contentName || 'general',
                settingsContentActiveParams: payload.params || '',
            };
        },
        setOrdersFilters: (state, action) => {
            const { payload } = action;

            if (Object.keys(payload).length === 0) {
                return {
                    ...state,
                    ordersFilters: {
                        ...initialOrderFilters,
                    },
                };
            }

            return {
                ...state,
                ordersFilters: {
                    ...state.ordersFilters,
                    ...payload,
                },
            };
        },
        setOrdersV3Filters: (state, action) => {
            const { payload } = action;

            if (Object.keys(payload).length === 0) {
                return {
                    ...state,
                    ordersV3Filters: {
                        ...initialOrdersV3Filters,
                    },
                };
            }

            return {
                ...state,
                ordersV3Filters: {
                    ...state.ordersV3Filters,
                    ...payload,
                },
            };
        },
        setPreparationFilters: (state, action) => {
            const { payload } = action;
            if (Object.keys(payload).length === 0 && !payload.scanMode) {
                return {
                    ...state,
                    preparationsFilters: {
                        ...initialPreparationFilters,
                    },
                };
            }

            if (Array.from(payload?.status ?? []).length === 0 && !payload.scanMode) {
                if (initialPreparationFilters.status) {
                    payload.status = initialPreparationFilters.status;
                }
            }
            return {
                ...state,
                preparationsFilters: {
                    ...state.preparationsFilters,
                    ...payload,
                },
            };
        },
        setDeliveriesFilters: (state, action) => {
            const { payload } = action;

            if (Object.keys(payload).length === 0) {
                return {
                    ...state,
                    deliveriesFilters: {
                        ...initialDeliveriesFilters,
                    },
                };
            }

            if (Array.from(payload?.status ?? []).length === 0) {
                payload.status = initialDeliveriesFilters.status;
            }

            return {
                ...state,
                deliveriesFilters: {
                    ...state.deliveriesFilters,
                    ...payload,
                },
            };
        },
        setPendingArrivalsFilters: (state, action) => {
            const { payload } = action;

            if (Object.keys(payload).length === 0) {
                return {
                    ...state,
                    pendingArrivalsFilters: {
                        ...initialPendingArrivalFilters,
                    },
                };
            }

            if (Array.from(payload?.preparationcd_transfer_pickupstore ?? []).length === 0) {
                payload.preparationcd_transfer_pickupstore =
                    initialPendingArrivalFilters.preparationcd_transfer_pickupstore;
            }

            return {
                ...state,
                pendingArrivalsFilters: {
                    ...state.pendingArrivalsFilters,
                    ...payload,
                },
            };
        },
        setCrossdockingFilters: (state, action) => {
            const { payload } = action;

            if (Object.keys(payload).length === 0) {
                return {
                    ...state,
                    crossdockingFilters: {
                        ...initialCrossdockingFilters,
                    },
                };
            }

            if (Array.from(payload?.preparationcd_transfer_pickupstore ?? []).length === 0) {
                payload.preparationcd_transfer_pickupstore =
                    initialCrossdockingFilters.preparationcd_transfer_pickupstore;
            }

            return {
                ...state,
                crossdockingFilters: {
                    ...state.crossdockingFilters,
                    ...payload,
                },
            };
        },
        setManifestsFilters: (state, action) => {
            const { payload } = action;
            if (Object.keys(payload).length === 0) {
                return {
                    ...state,
                    manifestsFilters: {
                        ...initialManifestsFilters,
                    },
                };
            }

            return {
                ...state,
                manifestsFilters: {
                    ...initialManifestsFilters,
                    ...state.manifestsFilters,
                    ...payload,
                },
            };
        },
        setErrorsFilters: (state, action) => {
            const { payload } = action;

            if (Object.keys(payload).length === 0) {
                return {
                    ...state,
                    errorsFilters: {
                        ...initialErrorsFilters,
                    },
                };
            }
            return {
                ...state,
                errorsFilters: {
                    ...state.errorsFilters,
                    ...payload,
                },
            };
        },
        toggleUpdaterFilters: (state, { payload }) => {
            return {
                ...state,
                externallyUpdatedFilters:
                    `${payload}${Date.now()}` || !state.externallyUpdatedFilters,
            };
        },
        toggleFulfillmentsViewScanMode: (state, action) => {
            return {
                ...state,
                fulfillmentsViewScanMode: action.payload,
            };
        },
        replaceFulfillmentToMassivePreparation: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                massiveFulfillmentPreparation: payload,
            };
        },
        addFulfillmentToMassivePreparation: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                massiveFulfillmentPreparation: [payload, ...state.massiveFulfillmentPreparation],
            };
        },
        clearFullfilmentMassivePreparation: (state) => {
            return {
                ...state,
                massiveFulfillmentPreparation: [],
            };
        },
        addFulfillmentToMassiveDelivery: (state, action) => {
            const { fulfillment, position = 'first' } = action.payload;

            const newMassiveFulfillmentDelivery =
                position === 'first'
                    ? [fulfillment, ...state.massiveFulfillmentDelivery]
                    : [...state.massiveFulfillmentDelivery, fulfillment];

            return {
                ...state,
                massiveFulfillmentDelivery: newMassiveFulfillmentDelivery,
            };
        },
        removeFulfillmentToMassiveDelivery: (state, action) => {
            const { fulfillment_id } = action.payload;

            const newMassiveFulfillmentDelivery = state.massiveFulfillmentDelivery.filter(
                (fulfillment) => fulfillment._id !== fulfillment_id
            );

            return {
                ...state,
                massiveFulfillmentDelivery: newMassiveFulfillmentDelivery,
            };
        },
        replaceFulfillmentToMassiveDelivery: (state, action) => {
            let { index, newFulfillment } = action.payload;

            if (!index) {
                const existingIndex = state.massiveFulfillmentDelivery.findIndex(
                    (item) => item._id === newFulfillment._id
                );
                index = existingIndex;
            }

            if (index >= 0 && index < state.massiveFulfillmentDelivery.length) {
                const updatedMassiveDelivery = [...state.massiveFulfillmentDelivery];
                updatedMassiveDelivery[index] = newFulfillment;
                return {
                    ...state,
                    massiveFulfillmentDelivery: updatedMassiveDelivery,
                };
            }
            return state;
        },
        clearFullfilmentMassiveDelivery: (state) => {
            return {
                ...state,
                massiveFulfillmentDelivery: [],
            };
        },
        setFulfillmentMassiveDelivery: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                massiveFulfillmentDelivery: [...state.massiveFulfillmentDelivery, ...payload],
            };
        },
        addManifestsToMassiveProcess: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                massiveManifestsToProcess: [...state.massiveManifestsToProcess, payload],
            };
        },
        clearManifestsMassiveProcess: (state) => {
            return {
                ...state,
                massiveManifestsToProcess: [],
            };
        },
        setDetailOrderForChangeCourier: (state, action) => {
            return {
                ...state,
                detailOrderForChangeCourier: action.payload,
            };
        },
    },
});

export const {
    toggleSidebar,
    changeTheme,
    toggleDrawer,
    openDrawer,
    closeDrawer,
    toggleModal,
    openModal,
    closeModal,
    setSelectedRows,
    clearSelectedRows,
    setFulfillmentForPreparation,
    removeFromFulfillmentForPreparation,
    setFulfillmentForDelivery,
    removeFromFulfillmentForDelivery,
    setSettingContentActive,
    setOrdersFilters,
    setPreparationFilters,
    setDeliveriesFilters,
    setPendingArrivalsFilters,
    setCrossdockingFilters,
    setManifestsFilters,
    setErrorsFilters,
    toggleUpdaterFilters,
    toggleFulfillmentsViewScanMode,
    addFulfillmentToMassivePreparation,
    replaceFulfillmentToMassivePreparation,
    clearFullfilmentMassivePreparation,
    addFulfillmentToMassiveDelivery,
    removeFulfillmentToMassiveDelivery,
    clearFullfilmentMassiveDelivery,
    replaceFulfillmentToMassiveDelivery,
    addManifestsToMassiveProcess,
    clearManifestsMassiveProcess,
    setFulfillmentMassiveDelivery,
    setDetailOrderForChangeCourier,
    setOrdersV3Filters,
} = utilsSlice.actions;

export default utilsSlice.reducer;
