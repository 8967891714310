import { useGetValueFeatureFlag } from 'app-config/useGetValueFeatureFlag';
import OrdersPageV3 from 'pages/OrdersV3';
import OrdersPage from 'pages/OrdersV2';

const OrdersProxy = () => {
    // Este componente proxy se creo solamente para poder activar la feature flag, ya que no se puede activar fuera del router del app
    const use_orders_v3 = useGetValueFeatureFlag('use_orders_v3', 'enabled');
    return use_orders_v3 ? <OrdersPageV3 /> : <OrdersPage />;
};

export default OrdersProxy;
