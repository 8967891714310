import { Col, Form, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import useCurrentUser from 'hooks/useCurrentUser';
import { useGetPreparersBySupervisor } from 'hooks/useGetPreparersByCustom';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUpdateAssignOperatorMutation } from 'redux/features/Fulfillments';

export const ModalAssignOperator = ({ isOpen, onOk, onCancel, dataFulfillment }) => {
    const history = useHistory();
    const [assignedOperators, setAssignedOperators] = useState([]);
    const [, users] = useGetPreparersBySupervisor();

    const [updateAssignOperator, { isSuccess, isLoading }] = useUpdateAssignOperatorMutation();
    const { userData = {} } = useCurrentUser();
    const [form] = useForm();

    const preparers = useMemo(() => {
        const combinedData = [...(users || []), { ...userData }];

        const uniquePreparersSet = new Set(combinedData.map((item) => item._id));

        const uniquePreparers = [...uniquePreparersSet].map((_id) =>
            combinedData.find((item) => item._id === _id)
        );

        return uniquePreparers;
    }, [users, userData]);

    const handleOnCancel = () => {
        setAssignedOperators([]);
        onCancel();
        history.goBack();
    };

    const handleOnOk = () => {
        updateAssignOperator({
            _id: dataFulfillment._id,
            assigned_operators: assignedOperators,
        });
    };

    const dataPreparers = useMemo(() => {
        return preparers.filter(
            (userPerLocation) => userPerLocation.firstname && userPerLocation.lastname
        );
    }, [preparers]);

    useEffect(() => {
        if (isSuccess) {
            onOk(assignedOperators);
            form.resetFields();
            setAssignedOperators([]);
        }
    }, [isSuccess]);

    return (
        <div>
            <Modal
                closable={false}
                title="Preparación"
                open={isOpen}
                onOk={handleOnOk}
                onCancel={handleOnCancel}
                confirmLoading={isLoading}
                okText="Asignar operadores"
            >
                <Form form={form} layout="vertical">
                    <Col>
                        <Form.Item name="assigned_operators" label="Responsables">
                            <Select
                                showArrow
                                mode="multiple"
                                onChange={(e) => setAssignedOperators(e)}
                                value={assignedOperators}
                            >
                                {dataPreparers.map((preparer) => (
                                    <Select.Option key={preparer._id} value={preparer._id}>
                                        {preparer.firstname} {preparer.lastname}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Form>
            </Modal>
        </div>
    );
};
