import PropTypes from 'prop-types';
import { createContext, useReducer, useContext } from 'react';
import { reducer, initialState, actionTypes } from './reducer';

const StateContext = createContext();

export const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return <StateContext.Provider value={{ state, dispatch }}>{children}</StateContext.Provider>;
};

// haz el prototype de useStateValue en el return
export const useStateValue = () => {
    const { state, dispatch } = useContext(StateContext);

    const setSelectedIds = (value) =>
        dispatch({ type: actionTypes.SET_SELECTED_IDS, payload: value });

    const setIdsWithIncidencesReady = (value) =>
        dispatch({ type: actionTypes.SET_IDS_WITH_INCIDENCES_READY, payload: value });

    const setSelectedData = (value) =>
        dispatch({ type: actionTypes.SET_SELECTED_DATA, payload: value });

    const setData = (value) => dispatch({ type: actionTypes.SET_DATA, payload: value });

    const setStatusIncidence = (value) =>
        dispatch({ type: actionTypes.SET_STATUS_INCIDENCE, payload: value });

    const setType = (value) => dispatch({ type: actionTypes.SET_TYPE, payload: value });

    const setTooltipTitle = (value) =>
        dispatch({ type: actionTypes.SET_TOOLTIP_TITLE, payload: value });

    const setIncidencesTypes = (value) =>
        dispatch({ type: actionTypes.SET_INCIDENCES_TYPES, payload: value });

    return {
        ...state,
        setSelectedIds,
        setIdsWithIncidencesReady,
        setSelectedData,
        setData,
        setStatusIncidence,
        setType,
        setTooltipTitle,
        setIncidencesTypes,
    };
};

useStateValue.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    setSelectedIds: PropTypes.func.isRequired,
    setIdsWithIncidencesReady: PropTypes.func.isRequired,
    setSelectedData: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    setStatusIncidence: PropTypes.func.isRequired,
    setType: PropTypes.func.isRequired,
    setTooltipTitle: PropTypes.func.isRequired,
    setIncidencesTypes: PropTypes.func.isRequired,
};
