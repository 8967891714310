import { ReactComponent as ChevronDownIcon } from 'assets/chevron-down.svg';
import CreateManualIncidenceOrderDrawer from 'components/ManualIncidences/Order/CreateIncidenceDrawer';
import PrimaryButton from 'components/V2/Buttons/PrimaryButton';
import Dropdown from 'components/V2/Dropdown';
import Menu from 'components/V2/Menu';
import ChangeFulfillmentsCourier from './ChangeFulfillmentsCourier';

const Actions = ({ selectedRows = [], disabled = false, data, setSelectedRows }) => {
    const handleIncidenceDrawerClose = ({ idsWithIncidences }) => {
        setSelectedRows((state) => state.filter((id) => !idsWithIncidences?.includes(id)));
    };

    const menu = (
        <Menu>
            {/* <MenuItem onClick={handleMultipleDeliveries}>
                Procesar Entrega ({selectedRows.length})
            </MenuItem> */}

            <CreateManualIncidenceOrderDrawer
                isMenuItem
                ids={selectedRows}
                fulfillments={data?.docs}
                viewPage="delivered"
                onCloseDrawer={handleIncidenceDrawerClose}
            />

            <ChangeFulfillmentsCourier selectedRows={selectedRows} />
        </Menu>
    );

    return (
        <Dropdown disabled={disabled} overlay={menu}>
            <PrimaryButton icon={<ChevronDownIcon width={24} height={24} />} iconPosition="right">
                Acciones ({selectedRows.length})
            </PrimaryButton>
        </Dropdown>
    );
};

export default Actions;
