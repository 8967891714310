import { Col, Form, Select } from 'antd';
import { CountryDirectionContext } from 'context/CountryDirectionContext';
import useCountryData from 'hooks/useCountryData';
import { useContext, useMemo } from 'react';

export const LocationByCountryForm = ({
    form,
    valueCountry,
    nameCountry,
    nameProvince,
    nameCity,
    nameNeighborhood,
}) => {
    const { getCountryInfo, countries } = useContext(CountryDirectionContext);
    const country = valueCountry ?? Form.useWatch(nameCountry || 'chile', form);
    const province = Form.useWatch(nameProvince, form);
    const city = Form.useWatch(nameCity, form);
    const countryData = useCountryData(country);

    const { provinces, municipalities, districts } = useMemo(
        () => ({
            provinces: getCountryInfo(country),
            municipalities: getCountryInfo(country, province || 'NA'),
            districts: getCountryInfo(country, province || 'NA', city || 'NA'),
        }),
        [countries, country, province, city]
    );

    return (
        <>
            <Col span={12}>
                <Form.Item
                    name={nameProvince}
                    initialValue=""
                    label={countryData.provinceLabel}
                    rules={[
                        {
                            required: true,
                            message: `Se necesita una ${countryData.provinceLabel?.toLowerCase()}`,
                        },
                    ]}
                >
                    <Select
                        placeholder={`Selecciona una ${countryData.provinceLabel.toLowerCase()}`}
                        onChange={() => {
                            form.setFieldValue(nameCity, undefined);
                            !!nameNeighborhood?.length &&
                                form.setFieldValue(nameNeighborhood, undefined);
                        }}
                    >
                        {provinces.map((r, i) => (
                            <Select.Option key={i} value={r.id}>
                                {r.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item
                    name={nameCity}
                    label={countryData.cityLabel}
                    rules={[
                        {
                            required: true,
                            message: `Se necesita un ${countryData.cityLabel?.toLowerCase()}`,
                        },
                    ]}
                >
                    <Select
                        placeholder={`Selecciona un ${countryData.cityLabel?.toLowerCase()}`}
                        disabled={!province}
                        onChange={() =>
                            !!nameNeighborhood?.length &&
                            form.setFieldValue(nameNeighborhood, undefined)
                        }
                    >
                        {municipalities?.map((r, i) => (
                            <Select.Option key={i} value={r.id}>
                                {r.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            {country === 'peru' && !!nameNeighborhood?.length && (
                <Col span={12}>
                    <Form.Item
                        name={nameNeighborhood}
                        initialValue=""
                        label={countryData.neighborhoodLabel}
                        rules={[
                            {
                                required: true,
                                message: `Se necesita una ${countryData.neighborhoodLabel?.toLowerCase()}`,
                            },
                        ]}
                    >
                        <Select
                            placeholder={`Selecciona un ${countryData.neighborhoodLabel?.toLowerCase()}`}
                            disabled={!province || !city}
                        >
                            {districts.map((district) => (
                                <Select.Option key={district.id} value={district.id}>
                                    {district.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            )}
        </>
    );
};
