import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';
import toast from 'react-hot-toast';

export const returnPreferenceApi = createApi({
    reducerPath: 'return_preference',
    baseQuery,
    tagTypes: ['return_preference'],
    endpoints: (builder) => ({
        getReturnPreferenceByMerchant: builder.query({
            query: () => ({
                url: 'settings/return-preferences/by-merchant',
                method: 'get',
            }),
            providesTags: ['return_preference'],
        }),
        updateReturnPreferences: builder.mutation({
            method: 'put',
            query: (data) => {
                return {
                    url: `settings/return-preferences/${data._id}`,
                    method: 'put',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Guardando cambios...</b>,
                    success: <b>Cambios guardado con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['return_preference'],
        }),
        createReturnPreferences: builder.mutation({
            method: 'post',
            query: (data) => {
                return {
                    url: `settings/return-preferences`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Guardando cambios...</b>,
                    success: <b>Cambios guardado con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['return_preference'],
        }),
    }),
});

export const {
    useCreateReturnPreferencesMutation,
    useGetReturnPreferenceByMerchantQuery,
    useUpdateReturnPreferencesMutation,
} = returnPreferenceApi;
