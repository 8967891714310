import { Button, Collapse } from 'antd';
import styled, { css } from 'styled-components';

export const ButtonReport = styled(Button)`
    display: inline-flex;
    padding: 5px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 3px;
    border: 1px solid #2d3d76;
    background-color: #fff;
    color: #2d3d76;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 188.6%;

    :active {
        background-color: #2d3d76;
        color: #fff;
    }
    :focus {
        background-color: #2d3d76;
        color: #fff;
    }
`;

export const ContainerCollapse = styled.div`
    background: #fff;
    border: 1px solid #036896;
    border-radius: 3px;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #b3b3b3;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    }

    ::-webkit-scrollbar-thumb:active {
        background-color: #999999;
    }
`;

export const CustomCollapse = styled(Collapse)`
    border: 0;
    background-color: #fff;

    .ant-collapse-item {
        border: 0px !important;
    }
    .ant-collapse-content {
        border: 0px !important;
    }
    .ant-collapse-header {
        padding: 8px !important;
    }
    .ant-collapse-content-box {
        background-color: #fff;
        padding: 0px !important;
    }
    .ant-collapse-item-active
        > .ant-collapse-header
        > .ant-collapse-expand-icon
        > .ant-collapse-arrow {
        transform: rotate(180deg) !important;
    }

    .anticon-right {
        top: 40% !important;
        transform: rotate(90deg) !important;
        svg {
            color: #41515e;
            font-size: 14px;
        }
    }

    .ant-collapse-item > :hover {
        background-color: #dce5ec;
    }

    .ant-collapse-item-active {
        background-color: ${({ bgColorActive }) => bgColorActive || '#037fb9'} !important;
    }

    .ant-collapse-header-text {
        color: #41515e;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 188.3%;
    }

    .ant-collapse-item-active > .ant-collapse-header > .ant-collapse-header-text {
        color: ${({ colorActive }) => colorActive || '#fff'};
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 188.3%;
    }

    .ant-collapse-item-active > .ant-collapse-header > .ant-collapse-expand-icon > .anticon > svg {
        color: ${({ colorActive }) => colorActive || '#fff'};
    }
`;

export const CollapseButton = styled.div`
    cursor: pointer;
    color: #41515e !important;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 188.3%;
    :hover {
        background-color: #dce5ec;
    }
    ${({ padding }) =>
        css`
            padding: ${padding};
        `}
    .ant-collapse-header-text {
        ${({ paddingLeft }) =>
            css`
                padding-left: ${paddingLeft};
            `}
    }
`;
