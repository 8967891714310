/*
    Componente adaptado para poder usarse en cualquier parte de la aplicación
    donde se necesite seleccionar una, varias o todas las tiendas.

    Solo debes importat el componente, pasarle el campo isGenericLocationPicker en true
    y el campo handleChangeLocations que es la función que se ejecutará al seleccionar una tienda.

    Ejemplo:

    const handleChangeLocationFilters = (updatedFilters) => {
        console.log(updatedFilters);
        ...
    }

    <LocationPicker
        handleChangeLocations={handleChangeLocationFilters}
        isGenericLocationPicker={true}
        // isSingleSelection={true} // si necesitas que solo se pueda seleccionar una tienda
    />
*/

import { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import Select from '../Select';
import SelectOption from '../Select/SelectOption';
import useAllShops from 'hooks/useAllShops';
import useCurrentUser from 'hooks/useCurrentUser';
import useGetCurrentPermissions from 'hooks/useGetCurrentPermissions';
import { ReactComponent as PinIcon } from 'assets/map-pin.svg';
import { DownOutlined as DownOutlinedIcon } from '@ant-design/icons';
import { PinIconWrapper } from './styles';

const LocationPicker = ({
    showVirtuals = false,
    loading,
    handleChangeLocations,
    isGenericLocationPicker = false,
    isSingleSelection = false,
    initialSelectedStores = [],
}) => {
    const [selectedStores, setSelectedStores] = useState([]);
    const [, dataStores] = useAllShops();
    const { userData } = useCurrentUser();
    const [permissions, isAdmin] = useGetCurrentPermissions();

    const isAllowed =
        permissions?.includes('sac') || permissions?.includes('supervisor') || isAdmin;

    // Efecto para actualizar la tienda seleccionada cuando se carga la data
    useEffect(() => {
        if (dataStores && dataStores?.length > 0 && !isGenericLocationPicker) {
            setSelectedStores([userData?.store_warehouse_id || 'all']);
        }

        if (isGenericLocationPicker) {
            if (initialSelectedStores.length) {
                setSelectedStores(initialSelectedStores);
            } else {
                if (!isSingleSelection) {
                    setSelectedStores(['all']);
                }
            }
        }
    }, [dataStores]);

    useEffect(() => {
        if (selectedStores && selectedStores?.includes('all')) {
            setSelectedStores(['all']);
        }
    }, []);

    useEffect(() => {
        if (selectedStores && isGenericLocationPicker && isSingleSelection) {
            if (typeof selectedStores === 'string') {
                handleChangeLocations(selectedStores);
            } else {
                handleChangeLocations(null);
            }
        }
    }, [selectedStores]);

    const handleSelectStore = (values) => {
        if (values?.includes('all') && values?.length > 1) {
            values = ['all'];
        }
        setSelectedStores(values);
    };
    const isCheckboxChecked = (value) => {
        return selectedStores?.includes(value);
    };

    const handleChangeAllStores = (e) => {
        if (e.target.checked == true) {
            setSelectedStores(['all']);
        }
    };

    const handleUpdateLocationFilters = () => {
        // Cuando se cierra el select, se actualizan los filtros
        handleChangeLocations(selectedStores);
    };

    return (
        <Select
            showSearch={false}
            placeholder={
                isGenericLocationPicker ? 'Seleccione' : selectedStores ? 'Tiendas' : 'Cargando...'
            }
            style={{
                minWidth: '150px',
                width: isGenericLocationPicker ? '100%' : '16rem',
                height: '2.5rem',
            }}
            suffixIcon={
                !isGenericLocationPicker ? (
                    <PinIconWrapper>
                        <PinIcon />
                    </PinIconWrapper>
                ) : (
                    <DownOutlinedIcon />
                )
            }
            maxTagCount="responsive"
            optionLabelProp="title"
            disabled={!isAllowed || !selectedStores || loading}
            value={selectedStores}
            type="location"
            mode={!isSingleSelection ? 'multiple' : null}
            showArrow={true}
            onChange={(values) => handleSelectStore(values)}
            onBlur={handleUpdateLocationFilters}
            tagRender={(props) => {
                if (props.value === 'all') {
                    return (
                        <span
                            style={{
                                ...(!isGenericLocationPicker && { color: '#536d8f' }),
                                paddingLeft: '8px',
                            }}
                        >
                            Todas las tiendas
                        </span>
                    );
                }

                const store = dataStores.find((item) => item._id === props.value);

                return (
                    <span
                        style={{ color: !isGenericLocationPicker ?? '#536d8f', paddingLeft: '8px' }}
                    >
                        {store ? store.name : ''}
                    </span>
                );
            }}
        >
            {!isSingleSelection && (
                <SelectOption key="all" value="all" title="Todas las tiendas">
                    <Checkbox
                        style={{ marginRight: '10px' }}
                        value={'all'}
                        checked={isCheckboxChecked('all')}
                        onChange={handleChangeAllStores}
                    />

                    <label>Todas las tiendas</label>
                </SelectOption>
            )}

            {Array.from(dataStores ?? [])
                .filter((location) => {
                    if (!location?.location_type || showVirtuals) return true;
                    return location?.location_type !== 'virtual';
                })
                .filter((location) => location.is_enabled)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((store) => (
                    <SelectOption
                        key={store._id}
                        value={store._id}
                        title={store.name}
                        disabled={selectedStores?.includes('all')}
                    >
                        {!isSingleSelection && (
                            <Checkbox
                                style={{ marginRight: '10px' }}
                                value={store._id}
                                checked={
                                    selectedStores?.includes('all')
                                        ? false
                                        : isCheckboxChecked(store._id)
                                }
                                disabled={selectedStores?.includes('all')}
                            />
                        )}

                        <label>{store.name}</label>
                    </SelectOption>
                ))}
        </Select>
    );
};

export default LocationPicker;
