// reducer.js
export const initialState = {
    selectedIds: [],
    idsWithIncidencesReady: [],
    selectedData: [],
    data: [],
    statusIncidence: 'only',
    type: 'order',
    tooltipTitle: '',
    incidencesTypes: [],
};

export const actionTypes = {
    SET_SELECTED_IDS: 'SET_SELECTED_IDS',
    SET_IDS_WITH_INCIDENCES_READY: 'SET_IDS_WITH_INCIDENCES_READY',
    SET_SELECTED_DATA: 'SET_SELECTED_DATA',
    SET_DATA: 'SET_DATA',
    SET_STATUS_INCIDENCE: 'SET_STATUS_INCIDENCE',
    SET_TYPE: 'SET_TYPE',
    SET_TOOLTIP_TITLE: 'SET_TOOLTIP_TITLE',
    SET_INCIDENCES_TYPES: 'SET_INCIDENCES_TYPES',
};

export const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_SELECTED_IDS:
            return { ...state, selectedIds: action.payload };
        case actionTypes.SET_IDS_WITH_INCIDENCES_READY:
            return { ...state, idsWithIncidencesReady: action.payload };
        case actionTypes.SET_SELECTED_DATA:
            return { ...state, selectedData: action.payload };
        case actionTypes.SET_DATA:
            return { ...state, data: action.payload };
        case actionTypes.SET_STATUS_INCIDENCE:
            return { ...state, statusIncidence: action.payload };
        case actionTypes.SET_TYPE:
            return { ...state, type: action.payload };
        case actionTypes.SET_TOOLTIP_TITLE:
            return { ...state, tooltipTitle: action.payload };
        case actionTypes.SET_INCIDENCES_TYPES:
            return { ...state, incidencesTypes: action.payload };
        default:
            return state;
    }
};
