import normalizeString from 'helpers/normalizeString';
import useAllShops from 'hooks/useAllShops';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetLocationsByItemsQuery } from 'redux/features/Locations';

export const useLocation = ({
    lineItems,
    fulfillmentId,
    order,
    fulfillmentSelected,
    searchTerms,
}) => {
    const changeStoreData = useSelector((state) => state.changeStore);
    const lineItemsForStock = lineItems && !fulfillmentId ? lineItems : changeStoreData.line_items;
    const { data: locations, isLoading: loadingLocations } = useGetLocationsByItemsQuery(
        {
            line_items: lineItemsForStock?.map((item) => ({
                line_item_id: item.line_item || item._id,
                quantity: item.quantity,
            })),
            delivery_method: changeStoreData?.delivery_method ?? 'all',
            availability: lineItemsForStock?.map((item) => ({
                sku: item.sku,
                quantity: item.quantity,
            })),
        },
        {
            skip: !lineItemsForStock?.length,
        }
    );
    const [loadingShops, dataShops] = useAllShops();

    const data = useMemo(() => {
        const deliveryType = changeStoreData?.warehouseType
            ? 'alternative_solutions'
            : 'warehouse_available';

        const isDev =
            window.location?.host?.includes('localhost') || window.location?.host?.includes('dev');

        const locationsSupplies = fulfillmentId
            ? order?.fulfillments?.find((ful) => ful?._id === fulfillmentId)?.origin
                  ?.fulfillment_locations ?? order?.location_id?.fulfillment_locations
            : order?.location_id?.fulfillment_locations;

        const shopStage = isDev ? dataShops : locations;
        const locationPreparation = shopStage?.filter((shop) =>
            locationsSupplies?.includes(shop?.oms_location_id ?? shop?._id)
        );

        const dataShopToFilter =
            deliveryType === 'warehouse_available' ? locationPreparation : shopStage;

        const activeLocations = Array.from(dataShopToFilter ?? []).filter((loc) => loc.is_enabled);
        // .filter((loc) => order?.)
        // .sort((a, b) => (a.name ?? a.location_name).localeCompare(b.name ?? b.location_name));

        let selectedLocations = [];

        if (changeStoreData.warehouseType === 'all') {
            selectedLocations = activeLocations.filter((loc) => loc.functions.length === 2);
        } else {
            selectedLocations = activeLocations.filter((loc) =>
                Array.from(loc?.functions ?? []).includes(
                    changeStoreData.warehouseType !== undefined
                        ? changeStoreData?.warehouseType
                        : order?.delivery_method
                )
            );
        }

        if (deliveryType === 'warehouse_available') {
            selectedLocations = selectedLocations?.slice(0, 1);

            const consolidationId =
                fulfillmentSelected?.consolidation?.consolidation_location_id ??
                fulfillmentSelected?.origin?._id;
            const consolidationName =
                dataShops?.find(
                    (sh) => sh._id === fulfillmentSelected?.consolidation?.consolidation_location_id
                )?.name ?? fulfillmentSelected?.origin?.name;
            const cdPickupStoreId =
                fulfillmentSelected?.preparationcd_transfer_pickupstore?.pickup_location?._id ??
                fulfillmentSelected?.origin?._id;
            const cdPickupStoreName =
                fulfillmentSelected?.preparationcd_transfer_pickupstore?.pickup_location?.name ??
                fulfillmentSelected?.origin?.name;

            const locationId =
                fulfillmentSelected?.delivery_method === 'pickup'
                    ? cdPickupStoreId
                    : consolidationId;
            const locationName =
                fulfillmentSelected?.delivery_method === 'pickup'
                    ? cdPickupStoreName
                    : consolidationName;

            selectedLocations = selectedLocations?.map((x) => ({
                _id: x?._id ?? x?.location_id ?? x?.oms_location_id,
                location_name: x?.name || x?.location_name,
                name: x?.name || x?.location_name,
                location_id: x?._id ?? x?.location_id,
                oms_location_id: x?.oms_location_id,
                ...x,
                products: [],
                supply_available: {
                    ...fulfillmentSelected?.origin,
                    _id: locationId,
                    name: locationName,
                    location_name: locationName,
                    location_id: locationId,
                },
            }));
        }

        if (searchTerms.length > 0) {
            return selectedLocations.filter((loc) =>
                normalizeString(loc.name ?? loc.location_name).includes(
                    normalizeString(searchTerms)
                )
            );
        }

        return selectedLocations;
    }, [
        changeStoreData.warehouseType,
        locations,
        searchTerms,
        dataShops,
        fulfillmentId,
        order?.delivery_method,
        order?.location_id?._id,
        order?._id,
    ]);

    return {
        locations,
        dataShops,
        data,
        loadingLocations,
        loadingShops,
    };
};
