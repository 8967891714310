export const siesaInvoiceLogs = ({ log, attempts }) => ({
    SIESA_DOCUMENT_READ: `Se ha leído documento de tipo Factura por parte del ESB para el pedido ${
        log?.other?.order_name ?? ''
    }`,
    SIESA_DOCUMENT_READ_FAILED: `Ha ocurrido un error en el intento de lectura del pedido ${log?.other?.order_name}, por parte del ESB para su posterior integración. El motivo: ${log.other.reason}, ${attempts}`,
    SIESA_CREDIT_NOTE_PENDING: `Se ha leído documento de tipo Nota de crédito por parte del ESB para el pedido ${
        log?.other?.order_name ?? ''
    }`,
    SIESA_CREDIT_NOTE_ERROR: `Ha ocurrido un error en el intento de lectura de la nota de crédito para el pedido ${log?.other?.order_name}, al ESB / Siesa. El motivo: ${log.other.reason}, ${attempts}`,
    SIESA_DOCUMENT_INTEGRATED: `Se ha integrado documento de tipo Factura para el pedido ${
        log.other?.order_name ?? ''
    } al ESB / Siesa`,
    SIESA_DOCUMENT_INTEGRATED_FAILED: `Ha ocurrido un error en el intento de integración del pedido ${log?.other?.order_name} al ESB / Siesa, el motivo ${log.other.reason}, ${attempts}`,
    SIESA_CREDIT_NOTE_COMPLETED: `Se ha integrado documento de tipo Nota de crédito para el pedido ${
        log.other?.order_name ?? ''
    } al ESB / Siesa`,
    SIESA_INVOICE_COMPLETED: `Se ha completado la integración del documento por parte del ESB / Siesa para el pedido  ${
        log.other?.order_name ?? ''
    }`,
    SIESA_INVOICE_COMPLETED_FAILED: `Ha ocurrido un error al reportar el documento al ESB / Siesa para el pedido ${
        log.other?.order_name ?? ''
    }`,
    DOCUMENT_DTE_INVOICE_ESB_REPORTED: `Se ha informado un documento de tipo Factura ${log.other?.folio ?? ''} del pedido ${
        log.other?.order_name ?? ''
    } al ESB`,
    DOCUMENT_DTE_CREDIT_NOTE_ESB_REPORTED: `Se ha informado un documento de tipo Nota crédito ${log.other?.folio ?? ''} del pedido ${
        log.other?.order_name ?? ''
    } al ESB`,
    DOCUMENT_DTE_TICKET_ESB_REPORTED: `Se ha informado un documento de tipo Boleta ${log.other?.folio ?? ''} del pedido ${
        log.other?.order_name ?? ''
    } al ESB`,
});
