import { createApi } from '@reduxjs/toolkit/query/react';
import getFromEnv from 'helpers/getFromEnv';
import qs from 'qs';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';

const stockApi = {
    dev: 'https://api-pim-demo.be-flow.com/',
    demo: 'https://api-pim-demo.be-flow.com/',
    prod: 'https://api-pim.be-flow.com/',
};

export const stocksApi = createApi({
    reducerPath: 'stocks',
    baseQuery,
    tagTypes: ['stocks', 'stocks_history'],
    endpoints: (builder) => ({
        getStockProducts: builder.query({
            method: 'get',
            query: (args) => {
                const querystring = qs.stringify(args ?? {});

                return `${stockApi[getFromEnv('REACT_APP_STAGE')]}products${
                    querystring ? `?${querystring}` : ''
                }`;
            },
            providesTags: ['stocks'],
            invalidatesTags: ['stocks_history'],
        }),
        getStockProductsHistory: builder.query({
            method: 'get',
            query: (args) => {
                const querystring = qs.stringify(args ?? {});

                return `${stockApi[getFromEnv('REACT_APP_STAGE')]}products/${
                    args.product_id
                }/history${querystring ? `?${querystring}` : ''}`;
            },
            providesTags: ['stocks_history'],
        }),
        getTrackingSalesChannels: builder.query({
            method: 'get',
            query: ({ stock_update_id, sku }) =>
                `${
                    stockApi[getFromEnv('REACT_APP_STAGE')]
                }products/tracking/sales-channels/${stock_update_id}?sku=${sku}`,
        }),
        reportStockByAllLocation: builder.mutation({
            method: 'post',
            query: (data) => {
                return {
                    url: `${stockApi[getFromEnv('REACT_APP_STAGE')]}reports/locations`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Generando reporte...</b>,
                    success: (
                        <b>El reporte sera enviado al email: {data.email}, en unos minutos.</b>
                    ),
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        updateStock: builder.mutation({
            method: 'put',
            query: (data) => {
                return {
                    url: `${stockApi[getFromEnv('REACT_APP_STAGE')]}stock-updates/by-product`,
                    method: 'put',
                    body: data,
                };
            },
            invalidatesTags: ['stocks', 'stocks_history'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando stock</b>,
                    success: <b>Stock actualizado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
    }),
});

export const {
    useLazyGetStockProductsQuery,
    useLazyGetStockProductsHistoryQuery,
    useGetTrackingSalesChannelsQuery,
    useUpdateStockMutation,
    useReportStockByAllLocationMutation,
} = stocksApi;
