import { Col, Form, Input, InputNumber, Row } from 'antd';
import { ReactComponent as AlertIconIncidence } from 'assets/alert-icon-incidence.svg';
import ModalWithButton from 'components/ModalWithButton';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from 'redux/features/Utils';
import { PurposeAnnulment } from './PurposeAnnulment';
import { modalProps } from './constants';
import { ContainerTitleIncidence, Description } from './styles';

export const ModalRequestCancel = ({
    lineItems,
    lineItemsManualIncidences,
    modalName,
    order,
    fulfillment,
    onConfirmCancelItem,
}) => {
    const [totalQuantityItemIncidences, setTotalQuantityItemIncidences] = useState(0);

    const dispatch = useDispatch();
    const { lineItemIds: selectedItemIds, isIncidence } = useSelector(
        (state) => state.lineItemsCancel
    );

    const [form] = Form.useForm();
    const itemsSelectedToShow = Form.useWatch('items', form);
    const commentToCancel = Form.useWatch(['comment_to_cancel'], form);

    const handleSubmit = (values) => {
        if (!values.items?.length) return;

        const totalShippingCost = order?.shipping_items?.reduce((acc, item) => {
            if (
                (item?.fulfillment_id ?? item?.logistic_id) === fulfillment?._id &&
                item.quantity > (item.removed_quantity ?? 0) + (item.refunded_quantity ?? 0)
            )
                acc += item?.price ?? 0;

            return acc;
        }, 0);

        let totalItemsCancel = totalShippingCost > 0;

        if (totalShippingCost) {
            totalItemsCancel = lineItems.reduce((acc, curr) => {
                if (!acc) return acc;

                const lineItem = values.items.find((item) => item._id === curr._id);

                if (
                    (!lineItem && curr.quantity > curr.removed_quantity + curr.refunded_quantity) ||
                    (lineItem &&
                        lineItem.quantity <
                            curr.quantity - curr.removed_quantity - curr.refunded_quantity)
                )
                    return false;

                return acc;
            }, true);
        }

        onConfirmCancelItem({
            ...values,
            has_shipping_cost: totalItemsCancel,
        });

        dispatch(toggleModal(modalName));
    };

    const handleCloseModal = () => {
        dispatch(toggleModal(modalName));
    };

    useEffect(() => {
        if (!selectedItemIds?.length || !lineItems?.length) {
            form.setFieldValue('items', []);
            return;
        }

        let totalIncidence = 0;
        const structureLineItems = lineItems
            .map((lineItem) => {
                const existsLineItem = selectedItemIds.includes(lineItem._id);
                const existsLineItemInIncidence = lineItemsManualIncidences?.filter(
                    (manualIncidence) =>
                        manualIncidence.line_items.some(
                            (lineItemIncidence) =>
                                selectedItemIds.includes(lineItemIncidence.line_item) ||
                                selectedItemIds.includes(lineItemIncidence.line_item._id)
                        )
                );

                if (!existsLineItem) return;

                const totalIncidenceQuantity = isIncidence
                    ? existsLineItemInIncidence?.reduce((prev, currentValue) => {
                          const incidenceQuantity = currentValue?.line_items.reduce(
                              (prevLineItem, currentValueLineItem) => {
                                  if (
                                      currentValueLineItem.line_item === lineItem._id ||
                                      currentValueLineItem.line_item._id === lineItem._id
                                  ) {
                                      prevLineItem += currentValueLineItem.incident_quantity ?? 0;
                                  }

                                  return prevLineItem;
                              },
                              0
                          );

                          return prev + incidenceQuantity;
                      }, 0)
                    : undefined;

                const totalIncidenceQuantityGeneral = !isIncidence
                    ? order?.line_items_manual_incidences?.reduce((prev, currentValue) => {
                          const incidenceQuantity = currentValue?.line_items.reduce(
                              (prevLineItem, currentValueLineItem) => {
                                  if (
                                      currentValueLineItem.line_item === lineItem._id ||
                                      currentValueLineItem.line_item._id === lineItem._id
                                  ) {
                                      prevLineItem += currentValueLineItem.incident_quantity ?? 0;
                                  }

                                  return prevLineItem;
                              },
                              0
                          );

                          return prev + incidenceQuantity;
                      }, 0)
                    : undefined;

                totalIncidence += totalIncidenceQuantity;

                const maxValue =
                    totalIncidenceQuantity ||
                    lineItem.quantity -
                        totalIncidenceQuantityGeneral -
                        lineItem.removed_quantity -
                        lineItem.refunded_quantity;

                return {
                    _id: lineItem._id,
                    name: lineItem.name,
                    maxValue,
                    quantity: 1,
                };
            })
            .filter((value) => !!value);

        setTotalQuantityItemIncidences(totalIncidence);
        form.setFieldValue('items', structureLineItems);
    }, [lineItems, selectedItemIds, lineItemsManualIncidences]);

    const isRefund = useMemo(
        () =>
            ['delivered'].includes(fulfillment?.status ?? order?.fulfillment_status) &&
            fulfillment?.preparationcd_transfer_pickupstore?.status !==
                'delivered_to_pickup_location',
        [fulfillment]
    );

    return (
        <div>
            <ModalWithButton
                modalProps={{
                    onOk: () => form.submit(),
                    onCancel: handleCloseModal,
                    name: modalName,
                    title: (
                        <span style={{ color: '#2D3D76', fontWeight: 700 }}>
                            Solicitud de cancelación de producto(s)
                        </span>
                    ),
                    ...modalProps,
                }}
                buttonProps={{
                    style: { display: 'none', visibility: 'hidden' },
                }}
            >
                <Form form={form} onFinish={handleSubmit}>
                    {isIncidence ? (
                        <div className="flex flex-cols">
                            <span
                                className="text-xs"
                                style={{ color: '#2D3D76', fontWeight: 500, paddingBottom: '1rem' }}
                            >
                                Unidad(es) con incidencia de un total de
                            </span>
                            <span className="text-xs ml-1" style={{ color: '#2D3D76' }}>
                                <strong>{totalQuantityItemIncidences}</strong>
                            </span>
                        </div>
                    ) : (
                        <>
                            <ContainerTitleIncidence className="text-xs">
                                <AlertIconIncidence />
                                No hay incidencias asociadas a los artículos seleccionados
                            </ContainerTitleIncidence>

                            {!!order?.sales_channel?.has_bank_data_for_return_money && (
                                <PurposeAnnulment />
                            )}

                            <div>
                                <span
                                    className="text-xs"
                                    style={{ color: '#142C4A', fontWeight: 500 }}
                                >
                                    Escribir el motivo de la {isRefund ? 'devolución' : 'anulación'}{' '}
                                </span>
                                <Form.Item style={{ margin: 0 }} name="comment_to_cancel">
                                    <Input.TextArea
                                        name="comment_to_cancel"
                                        rows={3}
                                        placeholder="Escribe aquí"
                                        maxLength={100}
                                        style={{
                                            height: '76px',
                                            fontSize: 12,
                                            fontWeight: 500,
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <span
                                className="flex justify-end"
                                style={{
                                    color: '#536D8F',
                                    fontSize: '9px',
                                    marginTop: '4px',
                                }}
                            >
                                {commentToCancel?.length ?? 0}/100
                            </span>
                        </>
                    )}

                    <Form.List name="items" initialValue={[]}>
                        {(fields) =>
                            fields.map(({ key, name: nameField, ...restField }) => {
                                if (!itemsSelectedToShow?.[key]) return;

                                const { maxValue, _id, name } = itemsSelectedToShow[key];

                                return (
                                    <div key={`item-${_id}`}>
                                        <div className="mb-2">
                                            <b
                                                className="text-xs"
                                                style={{ color: '#142C4A', fontWeight: 500 }}
                                            >
                                                Producto a {isRefund ? 'devolver' : 'anular'}{' '}
                                            </b>
                                        </div>
                                        <b
                                            className="text-xs"
                                            style={{ color: '#2D3D76', fontWeight: 500 }}
                                        >
                                            {name}
                                        </b>
                                        <Row gutter={8} className="mt-2">
                                            <Col xs={12}>
                                                <span
                                                    className="text-xs"
                                                    style={{ color: '#142C4A', fontWeight: 500 }}
                                                >
                                                    Seleccione las unidades a{' '}
                                                    {isRefund ? 'devolver' : 'anular'}{' '}
                                                </span>
                                            </Col>
                                            <Col xs={12}>
                                                <div>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[nameField, 'quantity']}
                                                    >
                                                        <InputNumber
                                                            onKeyDown={(e) => e.preventDefault()}
                                                            onInput={(e) => e.preventDefault()}
                                                            style={{ width: '60px' }}
                                                            defaultValue={1}
                                                            max={maxValue}
                                                            min={1}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="flex">
                                                    <Description>
                                                        Máx {maxValue < 10 ? '0' : ''}
                                                        {maxValue} unidad(es)
                                                    </Description>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })
                        }
                    </Form.List>

                    <div className="mt-5 mb-2">
                        <span
                            className="text-xs"
                            style={{
                                color: '#142C4A',
                                fontWeight: 500,
                            }}
                        >
                            ¿Deseas confirmar la solicitud de cancelación de producto(s)?
                        </span>
                    </div>
                </Form>
            </ModalWithButton>
        </div>
    );
};
