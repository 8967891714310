import { Modal } from 'antd';
import MoneyFormat from 'helpers/MoneyFormat';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRemoveShippingPriceV2Mutation } from 'redux/features/Orders';
import { toggleModal } from 'redux/features/Utils';
import styled from 'styled-components';

const WrapperModal = styled.div`
    display: flex;
    flex-direction: column;
    color: #142c4a;
    gap: 10px;
    .span_bold_modal {
        font-weight: 500;
    }
`;

const TitleModalRemoveShippingPrice = styled.div`
    color: #2d3d76;
    font-weight: bold;
    font-size: 16px;
`;
const RemoveShippingPrice = ({
    order_id,
    id,
    total_shipping,
    this_shipping_cost,
    modalName,
    isRefund,
}) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.utils);
    const [disabled, setDisabled] = useState(false);
    const isActive = state.modal[modalName] || false;
    const [removeShippingPriceV2] = useRemoveShippingPriceV2Mutation();

    const handleSubmit = () => {
        setDisabled(true);
        const body = { order_id, logistic_id: id, fulfillment_id: id };

        removeShippingPriceV2(body);
        dispatch(toggleModal(modalName));
    };

    return (
        <>
            <Modal
                title={
                    <TitleModalRemoveShippingPrice>
                        {isRefund ? 'Devolver' : 'Anular'} costo de envío
                    </TitleModalRemoveShippingPrice>
                }
                open={isActive}
                onCancel={() => dispatch(toggleModal(modalName))}
                onOk={handleSubmit}
                okButtonProps={{
                    disabled,
                }}
            >
                <WrapperModal>
                    <span>
                        Costo de envío actual{' '}
                        <span className="span_bold_modal">{MoneyFormat(total_shipping)}</span>
                    </span>
                    <span>
                        Al confirmar remover el costo de envío este será de{' '}
                        <span className="span_bold_modal">
                            {MoneyFormat(total_shipping - this_shipping_cost)} ¿Deseas confirmar
                            esta acción?
                        </span>{' '}
                    </span>
                </WrapperModal>
            </Modal>
        </>
    );
};

export default RemoveShippingPrice;
