import { createApi } from '@reduxjs/toolkit/query/react';
import getFromEnv from 'helpers/getFromEnv';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';

const createIncidentApis = {
    dev: '',
    demo: '',
    prod: '',
};

export const incidentsApi = createApi({
    reducerPath: 'incidents',
    baseQuery,
    tagTypes: [],
    keepUnusedDataFor: 30,
    endpoints: (builder) => ({
        createIncident: builder.mutation({
            query: (body) => {
                const currentUrl =
                    createIncidentApis[getFromEnv('REACT_APP_STAGE')] ||
                    '/incidents-v2/manual-incidences';
                return {
                    url: currentUrl,
                    method: 'POST',
                    body: body,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Creando incidencia...</b>,
                    success: <b>Incidencia creada con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
    }),
});

export const { useCreateIncidentMutation } = incidentsApi;
