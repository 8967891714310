import ModalWithButton from 'components/ModalWithButton';
import FormStyled from 'components/Utils/Form';
import CouriersSelector from 'pages/Metafields/components/CouriersSelector';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { useChangeCourierMutation } from 'redux/features/Deliveries';
import { toggleDrawer, toggleModal } from 'redux/features/Utils';
import { useMemo } from 'react';
import { verifyDisabledButton } from './validations';

const ChangeCourier = ({
    order_ids,
    orders,
    fulfillment_id,
    order_id,
    logistic_id,
    buttonProps = {},
    buttonType = 'menuitem',
    refetch,
}) => {
    const [form] = Form.useForm();
    const [changeCourier] = useChangeCourierMutation();
    const { drawer, modal } = useSelector((state) => state.utils);
    const dispatch = useDispatch();
    const globalDrawer = 'global-actions-order-drawer';
    const modalChangeCourier = 'orders-change-couriers';

    const disabledButton = useMemo(
        () => verifyDisabledButton({ order_ids, orders, fulfillment_id, order_id, logistic_id }),
        [order_ids, orders, fulfillment_id, order_id, logistic_id]
    );

    const handleSubmit = async () => {
        const values = form?.getFieldsValue();

        await form?.validateFields();

        if (!values?.courier_id) return;

        const response = await changeCourier({
            order_id,
            order_ids,
            fulfillment_id,
            logistic_id,
            ...values,
        });

        if (response.error) return;

        if (drawer[globalDrawer]) {
            dispatch(toggleDrawer(globalDrawer));
        }

        if (modal[modalChangeCourier]) {
            dispatch(toggleModal(modalChangeCourier));
        }

        setTimeout(() => {
            refetch && refetch(true);
        }, 3000);
    };

    return (
        <ModalWithButton
            onOk={() => handleSubmit()}
            modalProps={{
                cancelText: 'Cancelar',
                okText: 'Guardar',
                title: `Cambiar Courier/Tipo de servicio`,
                name: 'orders-change-couriers',
                destroyOnClose: true,
                okButtonProps: {
                    style: {
                        backgroundColor: '#F27127',
                        color: 'white',
                        border: '1px solid #E79B09 ',
                    },
                },
                cancelButtonProps: {
                    style: {
                        backgroundColor: 'none',
                        color: '#2D3D76',
                        border: '1px solid #2D3D76 ',
                    },
                },
            }}
            buttonType={buttonType}
            disabledType={'actions-order-details'}
            buttonProps={{
                buttonText: 'Cambiar Courier/Tipo de servicio',
                ...buttonProps,
                disabled: disabledButton,
            }}
        >
            <FormStyled form={form} layout="vertical">
                <CouriersSelector form={form} />
            </FormStyled>
        </ModalWithButton>
    );
};

export default ChangeCourier;
