import styled, { css } from 'styled-components';

export const TitleHeader = styled.span`
    color: #2d3d76;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    padding: 0px;
`;

export const Label = styled.span`
    font-weight: ${({ fontWeight }) => fontWeight ?? 400};
    font-size: 14px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: ${({ color }) => color ?? '#142c4a'};
    font-family: Poppins;
    display: inline-flex;
    ${({ textAlign }) =>
        textAlign &&
        css`
            text-align: ${textAlign};
        `};
`;

export const Description = styled.span`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: ${({ color }) => color ?? '#2d3d76'};
`;

// Comment
export const CommentLabel = styled.span`
    color: #2d3d76;
    font-weight: 500;
    font-size: 14px;
    line-height: 188.6%;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    margin-top: 10px;
    gap: 5px;
`;
