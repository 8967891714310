import { useEffect, useState } from 'react';
import useGetGenerals from './useGetGenerals';

const countryDict = {
    chile: {
        country: 'chile',
        capitalName: 'Metropolitana',
        cityLabel: 'Ciudad',
        cityLabelPlural: 'Ciudades',
        neighborhoodLabel: 'Comuna',
        neighborhoodLabelPlural: 'Comunas',
        provinceLabel: 'Región',
        provinceLabelPlural: 'Regiones',
        provinceLabelPluralOther: 'Otras',
    },
    peru: {
        country: 'peru',
        capitalName: 'Lima',
        cityLabel: 'Provincia',
        cityLabelPlural: 'Provincias',
        neighborhoodLabel: 'Distrito',
        neighborhoodLabelPlural: 'Distritos',
        provinceLabel: 'Departamento',
        provinceLabelPlural: 'Departamentos',
        provinceLabelPluralOther: 'Otros',
    },
    colombia: {
        country: 'colombia',
        capitalName: 'Bogota',
        cityLabel: 'Municipio',
        cityLabelPlural: 'Municipios',
        neighborhoodLabel: 'Barrio',
        neighborhoodLabelPlural: 'Barrios',
        provinceLabel: 'Departamento',
        provinceLabelPlural: 'Departamentos',
        provinceLabelPluralOther: 'Otros',
    },
};

const useCountryData = (countrySelected) => {
    const [countryData, setCountryData] = useState({
        // Valores por defecto
        country: 'chile',
        capitalName: 'Metropolitana',
        cityLabel: 'Ciudad',
        cityLabelPlural: 'Ciudades',
        neighborhoodLabel: 'Comuna',
        neighborhoodLabelPlural: 'Comunas',
        provinceLabel: 'Región',
        provinceLabelPlural: 'Regiones',
        provinceLabelPluralOther: 'Otras',
    });

    const [, generalSettings] = useGetGenerals();

    useEffect(() => {
        if (generalSettings?.country ?? countrySelected) {
            const country = countrySelected ?? generalSettings?.country;
            const selectedCountryDict = countryDict[String(country ?? '')?.toLowerCase()];

            if (selectedCountryDict?.capitalName) {
                setCountryData(selectedCountryDict);
            }
        }
    }, [generalSettings, countrySelected]);

    return countryData;
};

export default useCountryData;
