import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';
import { userPreferences } from 'helpers/constants/userPreferencesConfig';
import toast from 'react-hot-toast';

export const usersApi = createApi({
    reducerPath: 'users',
    baseQuery,
    tagTypes: ['users', 'user_preferences', 'user_logged'],
    endpoints: (builder) => ({
        getAllUsers: builder.query({
            method: 'get',
            query: (params) => {
                return {
                    url: `/users`,
                    method: 'get',
                    params: params,
                };
            },
            providesTags: ['users'],
        }),
        getUserPreferences: builder.query({
            method: 'get',
            query: (params) => {
                return {
                    url: `/users/preferences`,
                    method: 'get',
                    params: params,
                };
            },
            transformResponse: (response) => {
                if (response) {
                    return {
                        ...response,
                        preferences: {
                            orders: {
                                ...userPreferences.orders,
                                tabs: [
                                    ...userPreferences.orders.tabs,
                                    ...(response?.preferences?.orders?.tabs ?? []),
                                ],
                            },
                            orders_v3: {
                                ...userPreferences.orders_v3,
                                tabs: [
                                    ...userPreferences.orders_v3.tabs,
                                    ...(response?.preferences?.orders_v3?.tabs ?? []),
                                ],
                            },
                            preparations: {
                                ...userPreferences.preparations,
                                tabs: [
                                    ...userPreferences.preparations.tabs,
                                    ...(response?.preferences?.preparations?.tabs ?? []),
                                ],
                            },
                            pendingArrivals: {
                                ...userPreferences.pendingArrivals,
                                tabs: [
                                    ...userPreferences.pendingArrivals.tabs,
                                    ...(response?.preferences?.pendingArrivals?.tabs ?? []),
                                ],
                            },
                            crossdocking: {
                                ...userPreferences.crossdocking,
                                tabs: [
                                    ...userPreferences.crossdocking.tabs,
                                    ...(response?.preferences?.crossdocking?.tabs ?? []),
                                ],
                            },
                            deliveries: {
                                ...userPreferences.deliveries,
                                tabs: [
                                    ...userPreferences.deliveries.tabs,
                                    ...(response?.preferences?.deliveries?.tabs ?? []),
                                ],
                            },
                            manifests: {
                                ...userPreferences.manifests,
                                tabs: [
                                    ...userPreferences.manifests.tabs,
                                    ...(response?.preferences?.manifests?.tabs ?? []),
                                ],
                            },
                        },
                        /* Por mientras para no romper pedidos */
                        tabs: [...userPreferences.orders.tabs, ...(response?.tabs ?? [])],
                    };
                }
                return { error: '' };
            },
            providesTags: ['user_preferences'],
        }),
        updateUserPreferences: builder.mutation({
            query: (body) => ({
                url: `/users/preferences/update`,
                method: 'put',
                body,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Guardando configuración de usuario...</b>,
                    success: <b>Configuración guardada con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['user_preferences'],
        }),
        updateUserPreferencesColumns: builder.mutation({
            query: (body) => ({
                url: `/users/preferences/update`,
                method: 'put',
                body,
            }),
        }),
        changeUserMerchant: builder.mutation({
            query: (body) => ({
                url: `/users/changeUserMerchant`,
                method: 'post',
                body,
            }),
        }),
        getUserLogged: builder.query({
            query: (username) => ({
                url: `/users/getUserLogged/${username}`,
                method: 'get',
                providesTags: ['user_logged'],
            }),
        }),
    }),
});

export const {
    useGetAllUsersQuery,
    useGetUserPreferencesQuery,
    useUpdateUserPreferencesMutation,
    useUpdateUserPreferencesColumnsMutation,
    useChangeUserMerchantMutation,
    useLazyGetUserLoggedQuery,
} = usersApi;
