import { ReactComponent as BackLine } from 'assets/back-line.svg'; // entregado a courier
import { ReactComponent as PartiallyFulfilledIcon } from 'assets/box-unpackec.svg';
import { ReactComponent as FulfilledIcon } from 'assets/box.svg';
import { ReactComponent as DeliveredToCourierIcon } from 'assets/estado-despachado.svg'; // entregado a courier
import { ReactComponent as PausedIcon } from 'assets/pause-circle-blue.svg'; //  pausado
import { ReactComponent as ReturnIcon } from 'assets/return.svg';
import Drawer from 'components/Drawer';
import FulfillmentFlow from 'components/FulfillmentFlow';
import CurrentIncidence from 'components/ManualIncidences/LineItems/ShowDetailsDrawer/CurrentIncidence';
import LineItemsManualIncidencesDetails from 'components/ManualIncidences/LineItems/ShowDetailsDrawer/OpenDrawerByIconography';
import ProblemProducts from 'components/ManualIncidences/LineItems/ShowDetailsDrawer/ProblemProducts';
import { HeaderManualIncidenceDetailsOrderDrawer } from 'components/ManualIncidences/Order/ShowDetailsDrawer/HeaderManualIncidenceDetailsOrderDrawer';
import Tag from 'components/Tag';
import ThumbnailImage from 'components/ThumbnailImage';
import Flex from 'components/Utils/Flex';
import MoneyFormat from 'helpers/MoneyFormat';
import { deliveryPromiseDateFormatDic } from 'helpers/constants/deliveryPromiseDateFormat';
import { isObjectEmpty } from 'helpers/filterObjectByCustom';
import useAllShops from 'hooks/useAllShops';
import useMasterLabels from 'hooks/useMasterLabels';
import FulfillmentDeadline from 'pages/OrdersV2/components/Table/FulfillmentDeadline';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { BiHourglass } from 'react-icons/bi'; //pendiente
import { BsFillExclamationDiamondFill, BsPersonCheck } from 'react-icons/bs';
import { FiEdit, FiShoppingBag } from 'react-icons/fi';
import { HiChevronDoubleRight } from 'react-icons/hi';
import { ImCancelCircle } from 'react-icons/im';
import { MdOutlineCancel, MdOutlineLocalShipping } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { changeDetailsManualIncidenceDrawerField } from 'redux/features/ManualIncidences/detailsManualIncidenceDrawer';
import { changeDetailsManualIncidenceOrderDrawerField } from 'redux/features/ManualIncidences/detailsManualIncidenceOrderDrawer';
import { haveIncidences, selectedLineItemIds } from 'redux/features/Orders/lineItemsCancel';
import { toggleDrawer } from 'redux/features/Utils';
import { ActionsProductTable } from './Actions';
import { ModalDeliveryInfo } from './Actions/ModalDeliveryInfo';
import { ModalEditSku } from './Actions/ModalEditSku';
import { ModalInfo } from './Actions/ModalInfo';
import { FulfillmentSummary } from './fulfillmentSummary';
import {
    DisabledBox,
    InfoButtonProductTable,
    ProductTableDetails,
    ProductTableHeader,
    ProductTableWrapper,
    SkuCell,
    TableStyled,
    TextPointer,
    TotalContainerColumns,
} from './styles';
import useCurrentUser from 'hooks/useCurrentUser';
// import useGetCurrentPermissions from 'hooks/useGetCurrentPermissions';

const ProductTable = ({
    is_fulfillment,
    is_logistic,
    line_items = [],
    data = {},
    dataOrder = {},
    number = 0,
    setInfoSelected,
    name,
    tableColor,
    fulfillment_status,
    isActive,
    refetch,
    deliveryPromiseDateFormat,
    // hasMoreThanOneFulfillment,
    setButtonSelected,
    returnPreferences,
}) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsIncidences, setSelectedRowsIncidences] = useState([]);
    const [showIncidenceIcon, setShowIncidenceIcon] = useState(true);
    const [incidenceQuantity, setIncidenceQuantity] = useState([]);
    const [iconStatusFulfillment, setIconStatusFulfillment] = useState('');
    const [titleTableRemovedQuantity, setTitleTableRemovedQuantity] = useState('');
    const [notSuccessQuantity, setNotSuccessQuantity] = useState([]);
    const [productSelected, setProductSelected] = useState();
    const [isOpenModalEditSku, setIsOpenModalEditSku] = useState(false);
    const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
    const [consolidationLocations, setConsolidationLocations] = useState({});
    const [isOpenModalDeliveryInfo, setIsOpenModalDeliveryInfo] = useState(false);
    // const [permissions, isAdmin] = useGetCurrentPermissions();
    const user = useCurrentUser();

    const originalRaw = dataOrder?.original_line_items_raw?.find(
        (el) =>
            (el?.type === 'line_item' && el?.logistic_id === data?._id) ||
            el?.logistic_id === data?.logistic_id
    );

    const originalRawData = originalRaw
        ? {
              location_name: originalRaw?.location_name,
              shipping_method: originalRaw?.shipping_method,
              courier_name: originalRaw?.courier_name,
              courier_service: originalRaw?.courier_service,
          }
        : null;

    const { getLabel } = useMasterLabels();
    const dispatch = useDispatch();
    const [, dataStores] = useAllShops();

    const readyForDeliverToCdLocation =
        data?.consolidation?.status === 'ready_for_deliver_to_cd_location';

    useEffect(() => {
        if (
            data?.consolidation &&
            data?.consolidation?.consolidation_location_id &&
            !data?.consolidation?.is_consolidation_fulfillment
        ) {
            setConsolidationLocations({
                consolidation_location: dataStores?.find(
                    (store) => store._id === data?.consolidation?.consolidation_location_id
                )?.name,
                preparation_location: dataStores?.find(
                    (store) => store._id === data?.consolidation?.preparation_location_id
                )?.name,
            });
        }
    }, [dataStores, data]);

    useEffect(() => {
        let icon;

        if (data?.status === 'open') {
            icon = <BiHourglass className="header-table-icon-status" />;
        }
        if (data?.status === 'paused') {
            icon = <PausedIcon className=" " />;
        }
        if (data?.status === 'delivered_to_courier') {
            icon = <DeliveredToCourierIcon className="icon-header-delivered-to-courier" />;
        }
        if (data?.status === 'delivered') {
            icon = <BsPersonCheck className="header-table-icon-delivered" />;
        }
        if (data?.status === 'cancelled') {
            icon = <MdOutlineCancel className="header-table-icon-cancelled" />;
        }
        if (data?.status === 'success' || data?.status === 'ready_for_deliver') {
            icon = <FulfilledIcon className="header-table-icon-status fulfilled-icon" />;
        }
        if (data?.status === 'sac') {
            icon = <BsFillExclamationDiamondFill className="header-table-icon-status icon-sac" />;
        }
        if (data?.status === 'partially_processed') {
            icon = (
                <PartiallyFulfilledIcon className="header-table-icon-status  partially_fulfilled-icon" />
            );
        }
        setIconStatusFulfillment(icon);
    }, [data]);

    // 659d10642472368ad47ae08d

    useEffect(() => {
        if (
            dataOrder?.line_items_manual_incidences?.length > 0 ||
            data?.line_items_manual_incidences?.length > 0
        ) {
            setShowIncidenceIcon(true);
        }
    }, [dataOrder, data]);

    useEffect(() => {
        if (dataOrder?.line_items_manual_incidences?.length) {
            const order_incidences = Array.from(dataOrder?.line_items_manual_incidences ?? []);

            const resultArray = order_incidences.reduce((acc, incidence) => {
                if (
                    String(incidence?.fulfillment_id) === String(data?._id) ||
                    (!incidence?.fulfillment_id && is_logistic)
                ) {
                    Array.from(incidence?.line_items ?? []).forEach((incidenceLi) => {
                        const findItemIndexInAcc = acc.findIndex(
                            (item) => item.id === incidenceLi?.line_item?._id
                        );

                        if (findItemIndexInAcc === -1) {
                            acc.push({
                                id: incidenceLi?.line_item?._id,
                                quantity: incidenceLi?.incident_quantity ?? 0,
                            });
                        } else {
                            acc[findItemIndexInAcc].quantity += incidenceLi?.incident_quantity ?? 0;
                        }
                    });
                }

                return acc;
            }, []);

            setIncidenceQuantity(resultArray);

            const isDelivered = data?.status_history?.filter((history) =>
                ['delivered']?.includes(history?.status)
            );

            if (dataOrder?.shipping_items?.[0]?.removed_quantity > 0 && isDelivered?.length > 0) {
                setTitleTableRemovedQuantity('Devolución de cobro por despacho');
            } else {
                setTitleTableRemovedQuantity('Productos cancelados');
            }
        }

        // any item with removed_quantity in data
        const anyItemWithRemovedQuantity = Array.from(
            data?.line_items ?? data?.line_items_ids ?? data?.line_item_ids ?? []
        )?.some((item) => item?.line_item?.removed_quantity > 0 || item?.removed_quantity > 0);

        if (anyItemWithRemovedQuantity) {
            setTitleTableRemovedQuantity('Productos cancelados');
        }
    }, [dataOrder, data]);

    const handleClickInfo = (_id, number) => {
        setInfoSelected(_id);
        setButtonSelected(number);
        // setIsOpenModalInfo(true);
    };

    const handleClickEditSku = (product) => {
        setProductSelected(product);
        setIsOpenModalEditSku(true);
    };

    // ......................................................TABLAS.........................................................
    // Tabla de productos (normal)
    const tableData = useMemo(() => {
        if (
            dataOrder?.fulfillment_status === 'cancelled' &&
            name !== 'product_table_original_order'
        ) {
            return [];
        } else if (name === 'product_table_original_order') {
            if (dataOrder?.original_line_items_raw?.[0]) {
                const filteredLineItems = dataOrder?.original_line_items_raw?.filter(
                    (item) =>
                        (item?.logistic_id === data?._id ||
                            item?.logistic_id === data?.logistic_id) &&
                        item?.type === 'line_item'
                );

                if (dataOrder?.special_line_items?.length) {
                    filteredLineItems.push(...dataOrder.special_line_items);
                }

                return filteredLineItems;
            }

            return Array.from(line_items ?? []);
        } else {
            const lineItemsUnitsWithoutIncidence = [
                ...line_items,
                ...(dataOrder?.special_line_items ?? []),
            ]
                ?.map((line_i) => {
                    const lineItem = line_i?.line_item ?? line_i;

                    const lineItemWithIncidence = incidenceQuantity?.find(
                        (item) => item?.id === lineItem?._id
                    );

                    const newQuantity =
                        (lineItem?.quantity ?? 0) -
                        (lineItemWithIncidence?.quantity ?? 0) -
                        (lineItem?.removed_quantity ?? 0) -
                        (lineItem?.refunded_quantity ?? 0) -
                        (lineItem?.pre_devolution_quantity ?? 0) -
                        (lineItem?.cancelation_requested_quantity ?? 0);

                    return {
                        ...lineItem,
                        quantity: newQuantity,
                    };
                })
                .filter((el) => (el?.quantity ?? 0) > 0);

            return lineItemsUnitsWithoutIncidence;

            // return Array.from(lineItemsUnitsWithoutIncidence ?? []).filter((line_i) => {
            //     return (
            //         line_i?.quantity > 0
            //         // line_i?.removed_quantity !== line_i.original_quantity &&
            //         // line_i?.refunded_quantity !== line_i.original_quantity
            //         // line_i?.manual_incidences.length === 0 &&
            //         // !notSuccessQuantity?.includes(line_i?._id)
            //     );
            // });
        }
    }, [dataOrder?.fulfillment_status, line_items, notSuccessQuantity, incidenceQuantity]);

    // Tablas de productos cancelados con y sin fulfillment
    const tableDataRemovedQuantityFulfillment = useMemo(() => {
        const shippingItems = dataOrder?.shipping_items?.filter(
            (shippingItem) => shippingItem.fulfillment_id === data._id
        );

        const tableData = line_items?.filter((item) => {
            const lineItem = item?.line_item ?? item;
            const isLineItemRemoved = lineItem.removed_quantity > 0;

            return (
                data?._id === lineItem.fulfillment_id &&
                (data?.status === 'cancelled' || isLineItemRemoved)
            );
        });
        const addedCustomNames = [];

        shippingItems?.forEach((shippingItem) => {
            if (
                shippingItem.removed_quantity > 0 &&
                !addedCustomNames.includes('Despacho removido') &&
                data?.delivery_method === 'shipping'
            ) {
                tableData.push({
                    custom_name: 'Despacho removido',
                    ...shippingItem,
                });
                addedCustomNames.push('Despacho removido');
            }
            if (
                dataOrder?.fulfillment_status === 'cancelled' &&
                !addedCustomNames.includes('Despacho removido') &&
                data?.delivery_method === 'shipping'
            ) {
                tableData.push({
                    custom_name: 'Despacho removido',
                    ...shippingItem,
                });
                addedCustomNames.push('Despacho removido');
            }
        });

        const allQuantitiesEqual = dataOrder?.line_items?.every(
            (item) => item?.quantity === item?.removed_quantity
        );

        let optionalTableData = [];

        if (
            tableData?.length === 0 &&
            allQuantitiesEqual &&
            dataOrder?.fulfillment_status === 'cancelled'
        ) {
            optionalTableData = dataOrder?.line_items?.filter((item) => {
                return data?._id === item?.fulfillment_id && item?.removed_quantity > 0;
            });
        }

        const returnTableData = tableData.length > 0 ? tableData : optionalTableData;

        if (allQuantitiesEqual && dataOrder?.special_line_items?.[0]) {
            const parseSpecialLineItems = dataOrder?.special_line_items?.map((item) => {
                return {
                    ...item,
                    removed_quantity: item?.quantity,
                };
            });
            returnTableData.push(...parseSpecialLineItems);
        }

        return Array.from(returnTableData ?? [])?.filter((li) => li?.removed_quantity > 0);
    }, [data, dataOrder, fulfillment_status, line_items]);

    // tabla de logisticas anuladas
    const tableDataRemovedQuantityLogistic = useMemo(() => {
        if (line_items !== undefined && !is_fulfillment) {
            const lineItemsCancelled = line_items.filter((item) => {
                const lineItem = item?.line_item ?? item;
                return lineItem?.removed_quantity > 0;
            });

            const shippingItems = dataOrder?.shipping_items
                ?.filter(
                    (shippingItem) =>
                        shippingItem.logistic_id === data._id && shippingItem?.removed_quantity > 0
                )
                ?.map((shippingItem) => ({ ...shippingItem, unit_price: shippingItem.price }));

            return [...lineItemsCancelled, ...shippingItems];
        } else {
            return [];
        }
    }, [data]);

    // Tabla de productos devueltos
    const tableDataRefundedQuantityFulfillment = useMemo(() => {
        const tableData = line_items?.filter((item) => {
            return (
                data?._id === item?.fulfillment_id && item?.refunded_quantity > 0 /*  &&
                item.quantity !== 0 */
            );
        });

        const tableDataShippingItems = dataOrder?.shipping_items
            ?.filter(
                (shippingItem) =>
                    shippingItem.fulfillment_id === data._id && shippingItem?.refunded_quantity > 0
            )
            ?.map((shippingItem) => ({ ...shippingItem, unit_price: shippingItem.price }));

        const allQuantitiesEqual = dataOrder?.line_items?.every(
            (item) => item?.quantity === item?.refunded_quantity
        );

        let optionalTableData;
        if (
            tableData?.length === 0 &&
            allQuantitiesEqual &&
            dataOrder?.fulfillment_status === 'cancelled'
        ) {
            optionalTableData = dataOrder?.line_items?.filter((item) => {
                return data?._id === item?.fulfillment_id && item?.refunded_quantity > 0;
            });
        }

        return tableData.length > 0 ? [...tableData, ...tableDataShippingItems] : optionalTableData;
    }, [data, dataOrder, line_items]);

    // Tabla de productos con solicitud de cancelación
    const tableDataCancelationRequestedQuantity = useMemo(() => {
        if (name === 'product_table_logistic' && dataOrder?.line_items) {
            return (
                line_items?.filter(
                    (item) =>
                        dataOrder?.line_items?.find((li) => li._id === item._id) &&
                        item?.cancelation_requested_quantity > 0
                ) ?? []
            );
        }

        const tableData = line_items?.filter((item) => {
            return (
                (data?._id === item?.fulfillment_id || item?.logistic_id === data?._id) &&
                item?.cancelation_requested_quantity > 0
            );
        });

        const allQuantitiesEqual = dataOrder?.line_items?.every(
            (item) => item?.quantity === item?.cancelation_requested_quantity
        );

        let optionalTableData;
        if (
            tableData?.length === 0 &&
            allQuantitiesEqual // && dataOrder?.fulfillment_status === 'pre-devolution'
        ) {
            optionalTableData = dataOrder?.line_items?.filter((item) => {
                return data?._id === item?.fulfillment_id && 1 > 0;
            });
        }

        return tableData.length > 0 ? tableData : optionalTableData;
    }, [data, dataOrder, line_items]);

    // Tabla de pre-devoluciones
    const tableDataPreDevolutionQuantityFulfillment = useMemo(() => {
        if (name === 'product_table_logistic' && dataOrder?.line_items) {
            return (
                line_items?.filter(
                    (item) =>
                        dataOrder?.line_items?.find((li) => li._id === item._id) &&
                        item?.pre_devolution_quantity > 0
                ) ?? []
            );
        }

        const tableData = line_items?.filter((item) => {
            return (
                (data?._id === item?.fulfillment_id || item?.logistic_id === data?._id) &&
                item?.pre_devolution_quantity > 0
            );
        });

        const allQuantitiesEqual = dataOrder?.line_items?.every(
            (item) => item?.quantity === item?.pre_devolution_quantity
        );

        let optionalTableData;
        if (
            tableData?.length === 0 &&
            allQuantitiesEqual // && dataOrder?.fulfillment_status === 'pre-devolution'
        ) {
            optionalTableData = dataOrder?.line_items?.filter((item) => {
                return data?._id === item?.fulfillment_id && item?.pre_devolution_quantity > 0;
            });
        }

        return tableData.length > 0 ? tableData : optionalTableData;
    }, [data, dataOrder, line_items]);

    // Tabla de incidencias de productos
    const tableDataManualIncidences = useMemo(() => {
        const arrayToSearch = Array.from(dataOrder?.line_items_manual_incidences ?? [])?.reduce(
            (acc, manualIncidence) => {
                const itemsFromThisFulfillmentOrLogistic = manualIncidence?.line_items?.filter(
                    (item) =>
                        item?.line_item?.fulfillment_id === data?._id ||
                        item?.line_item?.logistic_id === data?._id ||
                        (!item?.line_item?.fulfillment_id && !item?.line_item?.logistic_id)
                );

                if (itemsFromThisFulfillmentOrLogistic?.length > 0) {
                    acc.push(
                        itemsFromThisFulfillmentOrLogistic?.map((item) => item?.line_item?._id)
                    );
                }

                return acc;
            },
            []
        );

        if (
            (is_fulfillment || is_logistic) &&
            data?.fulfillment_status !== 'sac' &&
            data?.status !== 'sac' &&
            dataOrder?.status !== 'sac' &&
            dataOrder?.fulfillment_status !== 'sac'
        ) {
            return [];
        }

        const flattenedArray = arrayToSearch?.flat();

        return dataOrder?.line_items?.filter((item) => {
            return flattenedArray?.includes(item?._id);
        });
    }, [dataOrder, incidenceQuantity]);

    const hasTitle =
        tableData?.length === 0 &&
        (tableDataRemovedQuantityFulfillment?.length === 0 ||
            tableDataRemovedQuantityFulfillment === undefined) &&
        (tableDataRefundedQuantityFulfillment?.length === 0 ||
            tableDataRefundedQuantityFulfillment === undefined);

    // ......................................................COLUMNAS........................................................
    const tableColumns = useMemo(
        () => [
            {
                title: (
                    <span>
                        Productos (<strong>{tableData?.length ?? 0}</strong>)
                    </span>
                ),
                dataIndex: 'name',
                key: 'name',
                render: (value, record) => {
                    return (
                        <Flex direction="row" gap="0.5rem" alignItems="center">
                            <ThumbnailImage imageSrc={record?.image} alt={record?.name} />
                            {value}
                        </Flex>
                    );
                },
            },
            {
                dataIndex: 'sku',
                title: 'SKU',
                key: 'sku',
                render: (value, record) => {
                    const hasItemPrismaValidationFailed = dataOrder?.tags?.includes(
                        'VERIFY_ITEMS_PRISM_FAILED'
                    );
                    return (
                        <SkuCell>
                            <span>
                                {record?.line_item?.remote_variant_id ??
                                    record?.sku ??
                                    record?.remote_variant_id}
                            </span>
                            {hasItemPrismaValidationFailed && (
                                <FiEdit
                                    cursor="pointer"
                                    size={14}
                                    onClick={() => handleClickEditSku(record)}
                                />
                            )}
                        </SkuCell>
                    );
                },
                // width: '200px',
            },
            {
                dataIndex: 'quantity',
                title: 'Unidades',
                key: 'quantity',
                render: (val, row) => {
                    // const removedQuantity = row.removed_quantity ?? 0;
                    // const refundedQuantity = row.refunded_quantity ?? 0;
                    // const quantity = val ?? 0;
                    const finalQuantity = val ?? 0;
                    // const line_item = row?.line_item ?? row;

                    const finalQuantityOk = finalQuantity;
                    // -
                    // (incidenceQuantity?.find((item) => {
                    //     return item?.id === line_item?._id;
                    // })?.quantity ?? 0);

                    if (finalQuantityOk === 0 && !notSuccessQuantity?.includes(row?._id)) {
                        setNotSuccessQuantity([...notSuccessQuantity, row?._id]);
                    }

                    if (finalQuantity === 0) {
                        return `${val} un.`;
                    }

                    return `${name === 'product_table_original_order' ? val : finalQuantityOk} un.`;
                },
                // width: '100px',
            },
            {
                dataIndex: 'total_price',
                title: 'Total',
                key: 'total_price',
                render: (_, row) => {
                    const line_item = row?.line_item === undefined ? row : row?.line_item;

                    const finalQuantityOk =
                        incidenceQuantity?.find((item) => {
                            return item?.id === line_item?._id;
                        })?.quantity ?? 0;

                    const totalItems =
                        (row?.original_quantity ?? row?.quantity ?? 0) -
                        (row?.removed_quantity ?? 0) -
                        (row?.refunded_quantity ?? 0) -
                        (row?.pre_devolution_quantity ?? 0) -
                        finalQuantityOk;

                    const totalValue = totalItems * row?.unit_price;

                    const valueWithDiscount =
                        row?.unit_discount > 0 ? totalValue + row?.unit_discount * totalItems : 0;

                    return (
                        <TotalContainerColumns>
                            {valueWithDiscount > 0 && (
                                <span className="value_with_discount">
                                    {name === 'product_table_original_order'
                                        ? MoneyFormat(row?.total_discount ?? 0, data?.currency)
                                        : MoneyFormat(valueWithDiscount ?? 0, data?.currency)}
                                </span>
                            )}
                            <span className="total_value">
                                {name === 'product_table_original_order'
                                    ? MoneyFormat(row?.total_price ?? 0, data?.currency)
                                    : MoneyFormat(totalValue ?? 0, data?.currency)}
                            </span>
                        </TotalContainerColumns>
                    );
                },
            },
        ],
        [data, incidenceQuantity]
    );

    const tableColumnsOriginal = useMemo(
        () => [
            {
                title: (
                    <span>
                        Productos (<strong>{tableData?.length ?? 0}</strong>)
                    </span>
                ),
                dataIndex: 'name',
                key: 'name',
                render: (value, record) => {
                    return (
                        <Flex direction="row" gap="0.5rem" alignItems="center">
                            <ThumbnailImage imageSrc={record?.image} alt={record?.name} />
                            {value}
                        </Flex>
                    );
                },
            },
            {
                dataIndex: 'sku',
                title: 'SKU',
                key: 'sku',
                render: (value, record) => {
                    return (
                        <SkuCell>
                            <span>
                                {record?.line_item?.remote_variant_id ??
                                    record?.sku ??
                                    record?.remote_variant_id}
                            </span>
                        </SkuCell>
                    );
                },
            },
            {
                dataIndex: 'quantity',
                title: 'Unidades',
                key: 'quantity',
                render: (val) => {
                    return val ?? 0;
                },
                // width: '100px',
            },
            {
                dataIndex: 'total_price',
                title: 'Total',
                key: 'total_price',
                render: (_, row) => {
                    const totalValue = row?.total_price ?? 0;

                    const valueWithDiscount =
                        row?.total_discount > 0 ? totalValue + row?.total_discount : 0;

                    return (
                        <TotalContainerColumns>
                            {row?.total_discount > 0 && (
                                <span className="value_with_discount">
                                    {MoneyFormat(valueWithDiscount, data?.currency)}
                                </span>
                            )}
                            <span className="total_value">
                                {MoneyFormat(totalValue, data?.currency)}
                            </span>
                        </TotalContainerColumns>
                    );
                },
            },
        ],
        [data]
    );

    const tableColumnsRemovedQuantity = useMemo(
        () => [
            {
                title: (
                    <ProductTableHeader>
                        <div className="icon_table_header">
                            <ImCancelCircle />
                        </div>
                        Productos cancelados (
                        <strong>
                            {tableDataRemovedQuantityFulfillment?.length
                                ? tableDataRemovedQuantityFulfillment?.length
                                : tableDataRemovedQuantityLogistic?.length ?? 0}
                        </strong>
                        )
                    </ProductTableHeader>
                ),
                dataIndex: 'name',
                key: 'name',
                render: (value, record) => {
                    return (
                        <Flex direction="row" gap="0.5rem" alignItems="center">
                            <ThumbnailImage imageSrc={record?.image} alt={record?.name} />
                            {value}
                        </Flex>
                    );
                },
            },
            {
                title: 'SKU',
                dataIndex: 'sku',
                key: 'sku',
                // width: '200px',
            },
            {
                title: 'Unidades',
                dataIndex: 'removed_quantity',
                key: 'removed_quantity',
                render: (val, row) => {
                    if (val === 0) {
                        return `${row?.quantity} un.`;
                    } else {
                        return `${
                            row?.custom_name === 'Despacho removido' ? row?.quantity : val
                        } un.`;
                    }
                },
                // width: '100px',
            },
            {
                title: 'Total',
                dataIndex: 'total_price',
                key: 'total_price',
                render: (_, row) => {
                    const isShippingItem = row?.custom_name === 'Despacho removido';
                    const totalValue = (row?.removed_quantity ?? 0) * (row?.unit_price ?? 0);
                    const priceToShow = isShippingItem ? row?.price : totalValue;

                    const valueWithDiscount =
                        row?.unit_discount > 0
                            ? priceToShow + row?.unit_discount * (row?.removed_quantity ?? 0)
                            : 0;

                    // return MoneyFormat(priceToShow ?? 0, data?.currency);
                    return (
                        <TotalContainerColumns>
                            {valueWithDiscount > 0 && (
                                <span className="value_with_discount">
                                    {MoneyFormat(valueWithDiscount ?? 0, data?.currency)}
                                </span>
                            )}
                            <span className="total_value">
                                {MoneyFormat(priceToShow ?? 0, data?.currency)}
                            </span>
                        </TotalContainerColumns>
                    );
                },
            },
        ],
        [data, titleTableRemovedQuantity]
    );

    const tableColumnsRefundedQuantity = useMemo(
        () => [
            {
                title: (
                    <ProductTableHeader>
                        <div className="icon_table_header">
                            <BackLine />
                        </div>
                        Productos devueltos (
                        <strong>{tableDataRefundedQuantityFulfillment?.length ?? 0}</strong>)
                    </ProductTableHeader>
                ),
                dataIndex: 'name',
                key: 'name',
                render: (value, record) => {
                    return (
                        <Flex direction="row" gap="0.5rem" alignItems="center">
                            <ThumbnailImage imageSrc={record?.image} alt={record?.name} />
                            {value}
                        </Flex>
                    );
                },
            },
            {
                title: 'SKU',
                dataIndex: 'sku',
                key: 'sku',
                // width: '200px',
            },
            {
                title: 'Unidades',
                dataIndex: 'refunded_quantity',
                key: 'refunded_quantity',
                render: (val) => {
                    return `${val} un.`;
                },
                // width: '100px',
            },
            {
                title: 'Total',
                dataIndex: 'total_price',
                key: 'total_price',
                render: (_, row) => {
                    const totalValue = (row?.refunded_quantity ?? 0) * (row?.unit_price ?? 0);

                    const valueWithDiscount =
                        row?.unit_discount > 0
                            ? totalValue + row?.unit_discount * (row?.refunded_quantity ?? 0)
                            : 0;

                    return (
                        <TotalContainerColumns>
                            {valueWithDiscount > 0 && (
                                <span className="value_with_discount">
                                    {MoneyFormat(valueWithDiscount ?? 0, data?.currency)}
                                </span>
                            )}
                            <span className="total_value">
                                {MoneyFormat(totalValue ?? 0, data?.currency)}
                            </span>
                        </TotalContainerColumns>
                    );
                },
            },
        ],
        [data]
    );

    const tableColumnsCancelationRequestedQuantity = useMemo(
        () => [
            {
                title: (
                    <ProductTableHeader>
                        <div className="icon_table_header">
                            <ImCancelCircle />
                        </div>
                        Solicitud de cancelación (
                        <strong>{tableDataCancelationRequestedQuantity?.length ?? 0}</strong>)
                    </ProductTableHeader>
                ),
                dataIndex: 'name',
                key: 'name',
                render: (value, record) => {
                    return (
                        <Flex direction="row" gap="0.5rem" alignItems="center">
                            <ThumbnailImage imageSrc={record?.image} alt={record?.name} />
                            {value}
                        </Flex>
                    );
                },
            },
            {
                title: tableData?.length === 0 && 'SKU',
                dataIndex: 'sku',
                key: 'sku',
                // width: '200px',
            },
            {
                title: tableData?.length === 0 && 'Unidades',
                dataIndex: 'cancelation_requested_quantity',
                key: 'cancelation_requested_quantity',
                render: (val) => {
                    return `${val} un.`;
                },
            },
            {
                title:
                    tableData?.length === 0 &&
                    tableDataRemovedQuantityFulfillment?.length === 0 &&
                    'Total',
                dataIndex: 'total_price',
                key: 'total_price',
                render: (_, row) => {
                    const totalValue = row?.cancelation_requested_quantity * row?.unit_price;

                    const valueWithDiscount =
                        row?.unit_discount > 0
                            ? totalValue +
                              row?.unit_discount * (row?.cancelation_requested_quantity ?? 0)
                            : 0;

                    return (
                        <TotalContainerColumns>
                            {valueWithDiscount > 0 && (
                                <span className="value_with_discount">
                                    {MoneyFormat(valueWithDiscount ?? 0, data?.currency)}
                                </span>
                            )}
                            <span className="total_value">
                                {MoneyFormat(totalValue ?? 0, data?.currency)}
                            </span>
                        </TotalContainerColumns>
                    );
                },
            },
        ],
        [data]
    );

    const tableColumnsPreDevolutionQuantity = useMemo(
        () => [
            {
                title: (
                    <ProductTableHeader>
                        <div className="icon_table_header">
                            <ReturnIcon className="order-page__product-table-button-return-icon" />
                        </div>
                        Productos Pre-devueltos (
                        <strong>{tableDataPreDevolutionQuantityFulfillment?.length ?? 0}</strong>)
                    </ProductTableHeader>
                ),
                dataIndex: 'name',
                key: 'name',
                render: (value, record) => {
                    return (
                        <Flex direction="row" gap="0.5rem" alignItems="center">
                            <ThumbnailImage imageSrc={record?.image} alt={record?.name} />
                            {value}
                        </Flex>
                    );
                },
            },
            {
                title: tableData?.length === 0 && 'SKU',
                dataIndex: 'sku',
                key: 'sku',
                // width: '200px',
            },
            {
                title: tableData?.length === 0 && 'Unidades',
                dataIndex: 'pre_devolution_quantity',
                key: 'pre_devolution_quantity',
                render: (val) => {
                    return `${val} un.`;
                },
            },
            {
                title:
                    tableData?.length === 0 &&
                    tableDataRemovedQuantityFulfillment?.length === 0 &&
                    'Total',
                dataIndex: 'total_price',
                key: 'total_price',
                render: (_, row) => {
                    const totalValue = row?.pre_devolution_quantity * row?.unit_price;

                    const valueWithDiscount =
                        row?.unit_discount > 0
                            ? totalValue + row?.unit_discount * (row?.pre_devolution_quantity ?? 0)
                            : 0;

                    return (
                        <TotalContainerColumns>
                            {valueWithDiscount > 0 && (
                                <span className="value_with_discount">
                                    {MoneyFormat(valueWithDiscount ?? 0, data?.currency)}
                                </span>
                            )}
                            <span className="total_value">
                                {MoneyFormat(totalValue ?? 0, data?.currency)}
                            </span>
                        </TotalContainerColumns>
                    );
                },
            },
        ],
        [data]
    );

    const tableColumnsManualIncidences = useMemo(
        () => [
            {
                title: (
                    <ProductTableHeader>
                        <div className="icon_table_header">
                            <BsFillExclamationDiamondFill
                                onClick={() => {
                                    dispatch(toggleDrawer('manual-incidence-details-drawer'));
                                    dispatch(
                                        changeDetailsManualIncidenceDrawerField([
                                            'orderData',
                                            dataOrder,
                                        ])
                                    );
                                }}
                            />
                        </div>
                        Productos con incidencias (
                        <strong>
                            {incidenceQuantity?.filter(
                                (el) =>
                                    data?.line_items?.find(
                                        (fulfillmentLineItem) =>
                                            fulfillmentLineItem?.line_item?._id === el?.id
                                    ) ??
                                    dataOrder?.line_items?.find(
                                        (lineItem) => lineItem?._id === el?.id
                                    )
                            ).length ?? 0}
                        </strong>
                        )
                    </ProductTableHeader>
                ),
                dataIndex: 'name',
                key: 'name',
                // width: '61%',
                render: (value, record) => {
                    return (
                        <Flex direction="row" gap="0.5rem" alignItems="center">
                            <ThumbnailImage imageSrc={record?.image} alt={record?.name} />
                            {value}
                            {showIncidenceIcon && (
                                <div>
                                    {record?.manual_incidences?.[0] && (
                                        <LineItemsManualIncidencesDetails order={dataOrder} />
                                    )}
                                </div>
                            )}
                        </Flex>
                    );
                },
            },
            {
                title: hasTitle && 'SKU',
                dataIndex: 'sku',
                key: 'slu',
                // width: '200px',
            },
            {
                title: hasTitle && 'Unidades',
                dataIndex: 'quantity',
                key: 'quantity',
                render: (_, row) => {
                    const targetObject = incidenceQuantity?.find((item) => item?.id === row._id);
                    const incidenceCount = targetObject?.quantity ? targetObject.quantity : 0;

                    return <span>{incidenceCount} un.</span>;
                },
                // width: '100px',
            },
            {
                title: hasTitle && 'Total',
                dataIndex: 'total_price',
                key: 'total_price',
                render: (_, row) => {
                    const withIncidenceQuantity =
                        incidenceQuantity?.find((item) => item?.id === row?._id)?.quantity ?? 0;

                    const totalValue = row?.unit_price * withIncidenceQuantity;

                    const valueWithDiscount =
                        row?.unit_discount > 0
                            ? totalValue + row?.unit_discount * withIncidenceQuantity
                            : 0;

                    return (
                        <TotalContainerColumns>
                            {valueWithDiscount > 0 && (
                                <span className="value_with_discount">
                                    {MoneyFormat(valueWithDiscount ?? 0, data?.currency)}
                                </span>
                            )}
                            <span className="total_value">
                                {MoneyFormat(totalValue ?? 0, data?.currency)}
                            </span>
                        </TotalContainerColumns>
                    );
                },
            },
        ],
        [data, dataOrder, incidenceQuantity]
    );

    const getLogistic = (logistic_id) => {
        return dataOrder?.logistics_info?.find((logistic) => logistic._id === logistic_id) ?? {};
    };

    const isLogisticConsolidationLogistic = getLogistic(data?.logistic_id)?.consolidation
        ?.is_consolidation_fulfillment;

    const hasConsolidationFulfillmentWithoutItems = dataOrder?.logistics_info?.some(
        (logistic) =>
            logistic?.consolidation?.is_consolidation_fulfillment && !logistic?.line_item_ids?.[0]
    );

    const isConsolidationFulfillmentWithoutItems =
        (data?.consolidation?.is_consolidation_fulfillment || isLogisticConsolidationLogistic) &&
        !line_items?.[0];

    const commonTableRowSelection = useMemo(
        () => ({
            renderCell: (checked, record, index, originNode) => {
                const isAdjustDecimalItem = dataOrder?.special_line_items?.find(
                    (item) => item?._id === record?._id
                );
                if (isAdjustDecimalItem) return null;
                return originNode;
            },
            getCheckboxProps: (record) => {
                const isAdjustDecimalItem = dataOrder?.special_line_items?.find(
                    (item) => item?._id === record?._id
                );
                if (isAdjustDecimalItem) {
                    return {
                        disabled: true,
                    };
                }
            },
        }),
        [dataOrder]
    );

    const validationInfo = useMemo(() => {
        const lineItemsByLogistics = data.line_item_ids;

        if (lineItemsByLogistics?.length) {
            const totalCancelledLineItem =
                lineItemsByLogistics.filter(
                    (lineItem) =>
                        lineItem.quantity ===
                        (lineItem.removed_quantity ?? 0) + (lineItem.refunded_quantity ?? 0)
                ).length === lineItemsByLogistics.length;

            const totalCancelledShippingItem = dataOrder.shipping_items?.length
                ? dataOrder.shipping_items.every((shippingItem) =>
                      shippingItem.logistic_id === data._id
                          ? shippingItem.quantity ===
                            (shippingItem.removed_quantity ?? 0) +
                                (shippingItem.refunded_quantity ?? 0)
                          : true
                  )
                : true;

            if (totalCancelledLineItem && totalCancelledShippingItem) {
                // data.status = 'cancelled';
                return true;
            }
        }

        // const existsOnlyShippingItems = dataOrder.shipping_items?.some((shippingItem) => {
        //     const isShippingItem =
        //         (shippingItem?.fulfillment_id ?? shippingItem?.logistic_id) === data?._id;

        //     if (!isShippingItem) return false;

        //     const haveQuantity =
        //         shippingItem.quantity -
        //         (shippingItem.removed_quantity ?? 0) -
        //         (shippingItem.refunded_quantity ?? 0);

        //     return haveQuantity > 0;
        // });

        // Validacion solo para logistica

        return !!(
            ((!data?.only_show_table || name === 'product_table_original_order') &&
                (data?.line_items_manual_incidences?.length ||
                    tableData?.length ||
                    data?.consolidation?.is_consolidation_fulfillment)) ||
            !tableData?.length ||
            data.status === 'cancelled'
        );
    }, [data, dataOrder, tableData]);


    // quitar este flag cuando se active la vista por fulfillments
    const blockUserFulfillment = false

    const isAdmin = !!user?.userData?.profile_id?.user_profile_superadmin;

    const isDisabledFulfillment = user?.userData?.store_warehouse_id !== data?.origin?._id && !isAdmin && blockUserFulfillment;
    

    return (
        <ProductTableWrapper $tableColor={tableColor}>
            {isDisabledFulfillment && <DisabledBox />}
            <Drawer
                buttonProps={{
                    backgroundColor: '#ffffff',
                    textColor: '#043540',
                    buttonText: 'Revisar Detalles',
                }}
                drawerProps={{
                    name: 'manual-incidence-details-drawer',
                    title: (
                        <HeaderManualIncidenceDetailsOrderDrawer
                            data={dataOrder}
                            name="product_table_order_v2"
                        />
                    ),
                    width: '500px',
                    dontShowFooter: true,
                }}
            >
                <ProblemProducts lineItems={dataOrder?.line_items} />
                <div className=" overflow-auto">
                    {!isObjectEmpty(dataOrder) &&
                        dataOrder?.line_items_manual_incidences[0] &&
                        dataOrder?.line_items_manual_incidences.map((li) => {
                            return <CurrentIncidence incidence={li} key={li?._id} />;
                        })}
                </div>
            </Drawer>
            {validationInfo ? (
                <ProductTableDetails>
                    <div className="order_page_product_table_title">
                        <b
                            className={
                                isConsolidationFulfillmentWithoutItems
                                    ? 'order-page__product-table-name-consolidation'
                                    : 'order-page__product-table-name'
                            }
                        >
                            {name === 'product_table_original_order' &&
                                !isConsolidationFulfillmentWithoutItems &&
                                'Pedido original'}
                            {name !== 'product_table_original_order' &&
                            !isConsolidationFulfillmentWithoutItems &&
                            dataOrder?.fulfillment_status === 'cancelled' &&
                            dataOrder?.fulfillments.length === 0
                                ? 'Pedido cancelado'
                                : dataOrder?.fulfillment_status === 'sac' &&
                                  !isConsolidationFulfillmentWithoutItems &&
                                  dataOrder?.fulfillments.length === 0
                                ? 'SAC'
                                : name === 'product_table_fulfillment' &&
                                  !isConsolidationFulfillmentWithoutItems
                                ? 'Fulfillment'
                                : name !== 'product_table_original_order' &&
                                  dataOrder?.disable_movements &&
                                  !isConsolidationFulfillmentWithoutItems
                                ? `Fulfilled by: ${dataOrder?.external_content?.origin}`
                                : !isConsolidationFulfillmentWithoutItems &&
                                  name !== 'product_table_original_order' &&
                                  'Pendiente'}{' '}
                            {fulfillment_status !== 'cancelled' &&
                                !isConsolidationFulfillmentWithoutItems &&
                                name === 'product_table_fulfillment' &&
                                !hasConsolidationFulfillmentWithoutItems &&
                                number}
                            {fulfillment_status !== 'cancelled' &&
                                !isConsolidationFulfillmentWithoutItems &&
                                name === 'product_table_fulfillment' &&
                                hasConsolidationFulfillmentWithoutItems &&
                                number - 1}
                            {data?.remote_order_id && isConsolidationFulfillmentWithoutItems
                                ? data?.remote_order_id
                                : ''}{' '}
                            {data?.remote_order_id &&
                                !isConsolidationFulfillmentWithoutItems &&
                                ':'}{' '}
                        </b>

                        {line_items?.[0] ? (
                            <small
                                id={`fulfillment_${data?._id}`}
                                className="order-page__product-table-id"
                            >
                                <Link
                                    // className="preparation-header__order-detail-link"
                                    to={`/fulfillments/${data?._id}`}
                                    style={{
                                        fontSize: '16px',
                                        color: '#037FB9',
                                        // fontWeight: 'bold',
                                    }}
                                >
                                    <u>{data?.name ?? ''}</u>
                                </Link>
                            </small>
                        ) : (
                            ''
                        )}

                        {name !== 'product_table_original_order' && (
                            <InfoButtonProductTable
                                onClick={() => handleClickInfo(data?._id, number)}
                                className="order-page__product-table-button-info"
                                // variation={isActive ? 'primary' : 'secondary'}
                                is_active={isActive}
                                icon={
                                    <HiChevronDoubleRight className="order-page__product-table-button-info-icon" />
                                }
                                iconPosition="right"
                                size="small"
                            >
                                <span>Info</span>
                            </InfoButtonProductTable>
                        )}
                    </div>

                    {name !== 'product_table_original_order' && (
                        <div className="order_page_product_table_term_limit">
                            <Flex gap="6px" direction="column">
                                <Flex gap="6px" direction="row">
                                    <span>
                                        {
                                            deliveryPromiseDateFormatDic(deliveryPromiseDateFormat)
                                                .title
                                        }
                                        :{' '}
                                    </span>

                                    <Flex
                                        justifyContent="center"
                                        alignItems="center"
                                        direction="column"
                                    >
                                        <Suspense fallback={<div>Cargando...</div>}>
                                            <FulfillmentDeadline
                                                isDeadline={
                                                    deliveryPromiseDateFormatDic(
                                                        deliveryPromiseDateFormat
                                                    ).isHours
                                                }
                                                row={dataOrder}
                                                logistic={getLogistic(
                                                    is_logistic ? data?._id : data?.logistic_id
                                                )}
                                                fulfillment={is_fulfillment ? data : {}}
                                                isLogistic={is_logistic}
                                                isFulfillment={is_fulfillment}
                                            />
                                        </Suspense>
                                    </Flex>
                                </Flex>

                                {data?.promised_date_range && (
                                    <Flex gap="6px">
                                        <span className="order_page_product_table_promised_date_label">
                                            Rango de entrega:
                                        </span>
                                        <span className="order_page_product_table_promised_date_value">
                                            <Suspense fallback={<div>Cargando...</div>}>
                                                {data.promised_date_range}
                                            </Suspense>
                                        </span>
                                    </Flex>
                                )}
                            </Flex>

                            {name !== 'product_table_original_order' && (
                                <div
                                    className="order_page_product_table_status"
                                    style={{
                                        display: isConsolidationFulfillmentWithoutItems
                                            ? 'none'
                                            : 'inherit',
                                    }}
                                >
                                    Estado del fulfillment:{' '}
                                    <Tag
                                        activeHover
                                        onClick={() => {
                                            const isDelivered = data?.status === 'delivered';

                                            isDelivered && setIsOpenModalDeliveryInfo(true);

                                            if (dataOrder?.fulfillment_status !== 'sac') return;

                                            if (dataOrder?.line_items_manual_incidences?.length) {
                                                dispatch(
                                                    toggleDrawer('manual-incidence-details-drawer')
                                                );
                                                dispatch(
                                                    changeDetailsManualIncidenceDrawerField([
                                                        'orderData',
                                                        dataOrder,
                                                    ])
                                                );
                                            } else {
                                                dispatch(
                                                    toggleDrawer(
                                                        'manual-incidence-details-order-drawer'
                                                    )
                                                );
                                                dispatch(
                                                    changeDetailsManualIncidenceOrderDrawerField([
                                                        dataOrder,
                                                        data,
                                                    ])
                                                );
                                            }
                                        }}
                                        className="order-page__product-table-tag-status-styles"
                                    >
                                        {data?.status !== undefined ? (
                                            <div>
                                                {/* Consolidation Status */}
                                                {!isConsolidationFulfillmentWithoutItems
                                                    ? !['cancelled', 'sac'].includes(data?.status)
                                                        ? getLabel(
                                                              isConsolidationFulfillmentWithoutItems
                                                                  ? dataOrder?.fulfillment_status
                                                                  : readyForDeliverToCdLocation
                                                                  ? 'ready_for_deliver_to_cd_location'
                                                                  : data?.status
                                                          )
                                                        : getLabel(data?.status)
                                                    : ''}
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '5px',
                                                }}
                                            >
                                                <span>
                                                    {dataOrder?.fulfillment_status === 'sac' &&
                                                    dataOrder?.logistics_info?.length <= 1
                                                        ? 'SAC'
                                                        : dataOrder?.fulfillment_status ===
                                                          'cancelled'
                                                        ? 'Cancelado'
                                                        : 'Pendiente'}
                                                </span>{' '}
                                                {dataOrder?.fulfillment_status === 'sac' &&
                                                dataOrder?.logistics_info <= 1 ? (
                                                    <BsFillExclamationDiamondFill
                                                        className="header-table-icon-status icon-sac"
                                                        style={{ marginTop: '3px' }}
                                                    />
                                                ) : dataOrder?.fulfillment_status ===
                                                  'cancelled' ? (
                                                    <MdOutlineCancel
                                                        style={{
                                                            width: '13px',
                                                            height: '15px',
                                                        }}
                                                    />
                                                ) : (
                                                    <BiHourglass
                                                        style={{
                                                            width: '10px',
                                                            marginTop: '3px',
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                        {iconStatusFulfillment}
                                    </Tag>
                                    {data?.status === 'delivered' && (
                                        <TextPointer
                                            onClick={() => setIsOpenModalDeliveryInfo(true)}
                                        >
                                            Ver detalle de la entrega
                                        </TextPointer>
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    {/* {data?.promised_date_range && (
                        <Flex gap="6px" className="order_page_product_table_promised_date">
                            <span className="order_page_product_table_promised_date_label">
                                Rango de entrega:
                            </span>
                            <span className="order_page_product_table_promised_date_value">
                                <Suspense fallback={<div>Cargando...</div>}>
                                    {data.promised_date_range}
                                </Suspense>
                            </span>
                        </Flex>
                    )} */}

                    <div className="order_page_product_table_location">
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                            <span className="order_page_product_table_location_label">
                                Tienda/Bodega:{' '}
                            </span>
                            {data?.consolidation &&
                            data?.consolidation?.consolidation_location_id &&
                            !data?.consolidation?.is_consolidation_fulfillment ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '8px',
                                    }}
                                >
                                    <span
                                        className="order_page_product_table_value_location"
                                        style={{
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {consolidationLocations?.preparation_location}
                                    </span>
                                    <span>
                                        <DeliveredToCourierIcon
                                            style={{ width: '20px', height: '20px' }}
                                        />
                                    </span>
                                    <span className="order_page_product_table_value_location">
                                        {consolidationLocations?.consolidation_location}
                                    </span>
                                </div>
                            ) : data?.preparationcd_transfer_pickupstore ? (
                                <>
                                    <div className="order_page_product_table_value_location">
                                        {
                                            data?.preparationcd_transfer_pickupstore?.cd_location
                                                ?.name
                                        }
                                    </div>

                                    <DeliveredToCourierIcon
                                        style={{ width: '20px', height: '20px' }}
                                    />
                                    <div className="order_page_product_table_value_location">
                                        {
                                            data?.preparationcd_transfer_pickupstore
                                                ?.pickup_location?.name
                                        }
                                    </div>
                                </>
                            ) : (
                                <div className="order_page_product_table_value_location">
                                    {originalRawData && name === 'product_table_original_order'
                                        ? originalRawData?.location_name ?? ''
                                        : data?.origin?.name ??
                                          data?.location_id?.name ??
                                          dataOrder?.location_id?.name}
                                </div>
                            )}
                            <span
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '8px',
                                }}
                            >
                                {getLogistic(is_logistic ? data?._id : data?.logistic_id)
                                    ?.consolidation?.is_consolidation_fulfillment && (
                                    <b style={{ color: '#142C4A', fontWeight: '700' }}>
                                        (Consolidación)
                                    </b>
                                )}
                            </span>
                        </div>
                    </div>

                    <div className="order_page_product_table_delivery_method">
                        <span className="order_page_product_table_delivery_method_label">
                            Tipo de entrega:{' '}
                        </span>
                        <span className="order_page_product_table_delivery_method_value">
                            <Tag className="order-page__product-table-tag-delivery_method-styles">
                                {originalRawData && name === 'product_table_original_order' ? (
                                    <>
                                        {data?.have_pickup_point
                                            ? 'Retiro en punto de tercero'
                                            : data?.preparationcd_transfer_pickupstore ||
                                              originalRawData?.shipping_method === 'pickup'
                                            ? 'Retiro'
                                            : originalRawData?.shipping_method === 'shipping'
                                            ? 'Despacho'
                                            : ''}

                                        {data?.preparationcd_transfer_pickupstore ||
                                        originalRawData?.shipping_method === 'pickup' ? (
                                            <FiShoppingBag className="order-page__product-table-icon-delivery_method" />
                                        ) : (
                                            <MdOutlineLocalShipping className="order-page__product-table-icon-delivery_method" />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {data?.courier_pickup?.store_id
                                            ? 'Retiro en punto de tercero'
                                            : data?.preparationcd_transfer_pickupstore ||
                                              data?.delivery_method === 'pickup'
                                            ? 'Retiro'
                                            : data?.delivery_method === 'shipping'
                                            ? 'Despacho'
                                            : ''}
                                        {data?.preparationcd_transfer_pickupstore ||
                                        data?.delivery_method === 'pickup' ? (
                                            <FiShoppingBag className="order-page__product-table-icon-delivery_method" />
                                        ) : (
                                            <MdOutlineLocalShipping className="order-page__product-table-icon-delivery_method" />
                                        )}
                                    </>
                                )}
                            </Tag>
                        </span>
                    </div>
                    {data?.courier_pickup?.store_id && (
                        <div className="order_page_product_table_delivery_method_label order_page_product_table_agency">
                            <span className="order_page_product_table_delivery_method_label">
                                Punto de retiro:{' '}
                            </span>
                            <span className="order_page_product_table_delivery_method_value">
                                {data?.courier_pickup?.store_name}
                            </span>
                        </div>
                    )}
                    {name !== 'product_table_original_order' && !!line_items?.length && (
                        <ActionsProductTable
                            returnPreferences={returnPreferences}
                            dataOrder={dataOrder}
                            data={data}
                            name={name}
                            type={
                                selectedRowsIncidences?.length && selectedRows?.length
                                    ? 'multiple'
                                    : selectedRowsIncidences?.length
                                    ? 'incidences'
                                    : 'item'
                            }
                            selectedRows={
                                selectedRows?.length === 0 ? selectedRowsIncidences : selectedRows
                            }
                            line_items={line_items}
                            is_fulfillment={is_fulfillment}
                            refetch={refetch}
                        />
                    )}
                    <div className="order_page_product_table_courier">
                        <span className="order_page_product_table_courier_label">Courier: </span>
                        <span className="order_page_product_table_courier_value">
                            {originalRawData && name === 'product_table_original_order' ? (
                                originalRawData?.courier_name
                            ) : (
                                <>
                                    {is_logistic
                                        ? getLogistic(data?._id)?.courier_id?.public_name
                                        : data?.courier?.public_name}
                                </>
                            )}
                        </span>
                    </div>
                    <div className="order_page_product_table_shipping_service">
                        <span className="order_page_product_table_shipping_service_label">
                            Tipo de servicio:{' '}
                        </span>
                        <span className="order_page_product_table_shipping_service_value">
                            {originalRawData && name === 'product_table_original_order' ? (
                                originalRawData?.courier_service
                            ) : (
                                <>
                                    {is_logistic
                                        ? getLogistic(data?._id)?.courier_service ?? 'Sin servicio'
                                        : data?.shipping_service?.name ??
                                          data?.shipping_service ??
                                          'Sin servicio'}
                                </>
                            )}
                        </span>
                    </div>
                </ProductTableDetails>
            ) : null}
            {/* Fulfillment Flow  */}
            {name !== 'product_table_original_order' &&
                line_items?.[0] &&
                !data?.only_show_table &&
                name !== 'product_table_original_order' && (
                    <FulfillmentFlow
                        order={dataOrder}
                        fulfillment={is_fulfillment ? data : null}
                        logistic={is_logistic ? data : null}
                    />
                )}
            {/* Tables */}
            {/* TABLA NORMAL */}
            {name !== 'product_table_original_order' &&
            data?.status !== 'cancelled' &&
            tableData?.length > 0 ? (
                <TableStyled
                    $tableColor={tableColor}
                    onRow={() => {
                        return {
                            rowSelection: false,
                        };
                    }}
                    rowSelection={{
                        preserveSelectedRowKeys: true,
                        selectedRowKeys: selectedRows,
                        onChange: (selectedRowKeys) => {
                            dispatch(haveIncidences(!!selectedRowsIncidences.length));
                            dispatch(
                                selectedLineItemIds([...selectedRowKeys, ...selectedRowsIncidences])
                            );
                            setSelectedRows(selectedRowKeys);
                        },
                        ...commonTableRowSelection,
                    }}
                    rowKey="_id"
                    loading={false}
                    dataSource={tableData}
                    columns={tableColumns}
                    pagination={{
                        hideOnSinglePage: true,
                    }}
                    name="product_table"
                />
            ) : null}
            {/* TABLA DE ORDEN ORIGINAL */}
            {name === 'product_table_original_order' ? (
                <>
                    {tableData?.[0] ? (
                        <TableStyled
                            $tableColor={tableColor}
                            rowKey="_id"
                            loading={false}
                            dataSource={tableData}
                            columns={tableColumnsOriginal}
                            pagination={{
                                hideOnSinglePage: true,
                            }}
                            name="product_table"
                        />
                    ) : null}
                </>
            ) : null}
            {/* TABLA DE ARTICULOS REMOVIDOS DE FULFILLMENT O LOGISTICA */}
            {(tableDataRemovedQuantityFulfillment?.length
                ? tableDataRemovedQuantityFulfillment
                : tableDataRemovedQuantityLogistic?.length
                ? tableDataRemovedQuantityLogistic
                : false) && name !== 'product_table_original_order' ? (
                <TableStyled
                    rowSelection={{
                        preserveSelectedRowKeys: true,
                        selectedRowKeys: [],
                        ...commonTableRowSelection,
                    }}
                    $tableColor={tableColor}
                    $dontShowCheckbox={true}
                    className="order-page__product-table-removed-quantity"
                    dataSource={
                        tableDataRemovedQuantityFulfillment?.length
                            ? tableDataRemovedQuantityFulfillment
                            : tableDataRemovedQuantityLogistic
                    }
                    columns={tableColumnsRemovedQuantity}
                    pagination={{
                        hideOnSinglePage: true,
                    }}
                    name="product_table_removed_quantity"
                />
            ) : null}
            {/* TABLA DE ARTICULOS DEVUELTOS DE FULFILLMENT O LOGISTICA */}
            {tableDataRefundedQuantityFulfillment?.length
                ? name !== 'product_table_original_order' && (
                      <TableStyled
                          rowSelection={{
                              preserveSelectedRowKeys: true,
                              selectedRowKeys: [],
                              ...commonTableRowSelection,
                          }}
                          $tableColor={tableColor}
                          $dontShowCheckbox={true}
                          className="order-page__product-table-refunded-quantity"
                          dataSource={tableDataRefundedQuantityFulfillment}
                          columns={tableColumnsRefundedQuantity}
                          pagination={{
                              hideOnSinglePage: true,
                          }}
                          name="product_table_refunded_quantity"
                      />
                  )
                : null}
            {/* TABLA DE ARTICULOS PRE DEVUELTOS DE FULFILLMENT */}
            {tableDataCancelationRequestedQuantity?.length ? (
                <TableStyled
                    rowSelection={{
                        preserveSelectedRowKeys: false,
                        selectedRowKeys: [],
                        hideSelectAll: true,
                        ...commonTableRowSelection,
                    }}
                    $tableColor={tableColor}
                    className="order-page__product-table-pre-devolution-quantity"
                    dataSource={tableDataCancelationRequestedQuantity}
                    columns={tableColumnsCancelationRequestedQuantity}
                    pagination={{
                        hideOnSinglePage: true,
                    }}
                    name="product_table_removed_quantity"
                />
            ) : null}
            {tableDataPreDevolutionQuantityFulfillment?.length ? (
                <TableStyled
                    rowSelection={{
                        preserveSelectedRowKeys: false,
                        selectedRowKeys: [],
                        hideSelectAll: true,
                        ...commonTableRowSelection,
                    }}
                    $tableColor={tableColor}
                    className="order-page__product-table-pre-devolution-quantity"
                    dataSource={tableDataPreDevolutionQuantityFulfillment}
                    columns={tableColumnsPreDevolutionQuantity}
                    pagination={{
                        hideOnSinglePage: true,
                    }}
                    name="product_table_pre_devolution_quantity"
                />
            ) : null}
            {/* TABLA DE ARTICULOS CON INCIDENCIA */}
            {tableDataManualIncidences?.length
                ? name !== 'product_table_original_order' && (
                      <TableStyled
                          $tableColor={tableColor}
                          dataSource={tableDataManualIncidences}
                          columns={tableColumnsManualIncidences}
                          pagination={{
                              hideOnSinglePage: true,
                          }}
                          name="product_table_manual_incidences"
                          rowSelection={
                              name === 'product_table_original_order' ||
                              data?.status === 'cancelled'
                                  ? null
                                  : {
                                        preserveSelectedRowKeys: true,
                                        selectedRowKeys: selectedRowsIncidences,
                                        onChange: (selectedRowKeys) => {
                                            dispatch(haveIncidences(!!selectedRowKeys.length));
                                            dispatch(
                                                selectedLineItemIds([
                                                    ...selectedRows,
                                                    ...selectedRowKeys,
                                                ])
                                            );
                                            setSelectedRowsIncidences(selectedRowKeys);
                                        },
                                        ...commonTableRowSelection,
                                    }
                          }
                          rowKey="_id"
                      />
                  )
                : null}
            {/* Resumen del fulfillment  */}
            {line_items?.[0] &&
                (dataOrder?.fulfillments?.length >= 1 ||
                    (dataOrder?.logistics_info?.length > 0 &&
                        dataOrder?.fulfillments?.length < 1)) && (
                    <FulfillmentSummary
                        name="fulfillment_summary"
                        data={data}
                        is_fulfillment={is_fulfillment}
                        is_logistic={is_logistic}
                        orderData={dataOrder}
                    />
                )}
            <ModalEditSku
                isOpen={isOpenModalEditSku}
                onClose={() => setIsOpenModalEditSku(false)}
                initialValues={{
                    remote_variant_id:
                        productSelected?.line_item?.remote_variant_id ??
                        productSelected?.remote_variant_id ??
                        '',
                    line_item_id: productSelected?.line_item?._id ?? productSelected?._id ?? '',
                    order_id: productSelected?.order_id,
                }}
            />
            <ModalInfo
                isOpen={isOpenModalInfo}
                onClose={() => setIsOpenModalInfo(false)}
                data={data}
            />
            <ModalDeliveryInfo
                fulfillmentIndex={number}
                dataFulfillment={data}
                onClose={() => setIsOpenModalDeliveryInfo(false)}
                isOpen={isOpenModalDeliveryInfo}
            />
        </ProductTableWrapper>
    );
};

export default ProductTable;
