import { ReactComponent as DownloadIcon } from 'assets/download-icon.svg';
import { Form } from 'antd';
import SearchInput from 'components/SearchInput';
import FormStyled from 'components/Utils/Form';
import debounce from 'helpers/debounce';
import { StockFormInputsStyled, StockFormStyled, StockParagraphStyled } from '../styles';
import LocationsSelector from './LocationsSelector';
import StockTable from './StockTable';
import { ButtonReport } from 'pages/Orders/components/OrdersDataTable/stylesReportButton';
import { useReportStockByAllLocationMutation } from 'redux/features/Stock';
import { useSelector } from 'react-redux';

const StockForm = ({ data, handleSearch, dataLoading, setRenderView, setSku, setProductId }) => {
    const [form] = Form.useForm();
    const {
        data: { email },
    } = useSelector((state) => state.userLogged);
    const [sendOrderReportStockAllLocation, sendOrderReportStockAllLocationStatus] =
        useReportStockByAllLocationMutation();

    const watchLocationId = Form.useWatch('location_id', form);

    const onValuesChanges = (changedValues, allValues) => {
        if (changedValues.location_id) handleSearch(allValues);
    };

    const onFinish = (values) => handleSearch(values);

    const debounceSearch = debounce(() => {
        const values = form.getFieldsValue();
        form.validateFields().then(() => handleSearch(values));
    }, 1000);

    const handleSendReport = () => {
        if (sendOrderReportStockAllLocationStatus.isLoading) return;
        
        const sku = form.getFieldValue('sku')

        sendOrderReportStockAllLocation({ email, ...(sku && { sku }) });
    };

    return (
        <StockFormStyled>
            <StockParagraphStyled>
                Para filtrar y gestionar el stock de los productos primero debes seleccionar la
                bodega/tienda y así se activará el buscador de productos
            </StockParagraphStyled>
            <br />
            <FormStyled
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onValuesChange={onValuesChanges}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <StockFormInputsStyled>
                        <LocationsSelector />
                        <Form.Item name="sku" label="2. Busca el producto por su código">
                            <SearchInput
                                placeholder="Copie y pegue el código aquí"
                                disabled={!watchLocationId}
                                onClickIcon={() => {
                                    form.submit();
                                }}
                                onClickIconDisabled={!watchLocationId}
                                onChange={debounceSearch}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        form.submit();
                                    }
                                }}
                            />
                        </Form.Item>
                    </StockFormInputsStyled>
                    <div style={{ marginTop: '1.5rem' }}>
                        <ButtonReport color="primary" onClick={() => handleSendReport()}>
                            <span style={{ marginRight: 8 }}>Exportar stock tienda/bodega</span>
                            <DownloadIcon style={{ width: '16px', height: '16px' }} />
                        </ButtonReport>
                    </div>
                </div>
            </FormStyled>
            <br />
            <br />
            <br />
            <StockTable
                data={data}
                dataLoading={dataLoading}
                setRenderView={setRenderView}
                setSku={setSku}
                setProductId={setProductId}
            />
        </StockFormStyled>
    );
};

export default StockForm;
