import { createSlice } from '@reduxjs/toolkit';

const cashOnDelivery = createSlice({
    name: 'cashOnDelivery',
    initialState: {
        fulfillment: {},
    },
    reducers: {
        addInfoData: (state, { payload }) => {
            state.fulfillment = payload;
        },
        removeInfoData: (state) => {
            state.fulfillment = {};
        },
    },
});

export const {
    addInfoData,
    removeInfoData,
    updateFilter,
    overrideFilters,
    setValueOptionByField,
    clearFilters,
} = cashOnDelivery.actions;
export default cashOnDelivery.reducer;
