import { Form, Modal } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import TextArea from 'antd/lib/input/TextArea';
import SelectTypeOne from 'components/Select';
import Flex from 'components/Utils/Flex';
import { renderFulFillmentTag } from 'helpers/renderTags';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from 'redux/features/Utils';
// import { updateOrderStatus } from 'redux/features/Orders/changeStatus';
import Tag from 'components/Tag';
import { Dot } from 'components/Utils/Dot';
import { allIncidences } from 'helpers/allIncidences';
import useMasterLabels from 'hooks/useMasterLabels';
import {
    useGetManualIncidencesByIdQuery,
    useUpdateOrderStatusMutation,
} from 'redux/features/Orders';
// import styled from 'styled-components';

// icons
import { ReactComponent as PartiallyFulfilledIcon } from 'assets/box-unpackec.svg'; // parcialmente preparado
import { ReactComponent as FulfilledIcon } from 'assets/box.svg'; // preparado
import { ReactComponent as DeliveredToCourierIcon } from 'assets/estado-despachado.svg';
import { ReactComponent as InProgressIcon } from 'assets/in_progress.svg'; // en proceso
import { ReactComponent as PausedIcon } from 'assets/pause-circle-blue.svg'; //  pausado
import { BiHourglass } from 'react-icons/bi'; //pendiente
import { BsFillExclamationDiamondFill, BsPersonCheck } from 'react-icons/bs'; // sac
import { MdOutlineCancel } from 'react-icons/md'; // cancelado
import { useCreateManualIncidenceMutation } from 'redux/features/ManualIncidences';
import { rulesRequired } from 'utils';
import { IncidenceOrderDetail } from './IncidenceOrderDetail';

const ChangeOrderStatus = ({
    order,
    fulfillment,
    modalProps,
    fulfillmentOrLogisticId,
    modalName,
}) => {
    const [hasNote, setHasNote] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { data } = useGetManualIncidencesByIdQuery(order?.id);
    const { getLabel } = useMasterLabels();
    const [iconStatus, setIconStatus] = useState('');
    const [newStatusSelected, setNewStatusSelected] = useState('');
    const [incidenceSelected, setIncidenceSelected] = useState('');
    const userDataLocalStorage = JSON.parse(localStorage.getItem('userLogged') ?? '{}') ?? null;
    const [createManualIncidence] = useCreateManualIncidenceMutation();
    const [updateOrderStatus] = useUpdateOrderStatusMutation();
    const [allStates, setAllStates] = useState([]);
    const state = useSelector((state) => state.utils);
    const isActive = state.modal[modalName] || false;

    const handleCancel = () => {
        setNewStatusSelected('');
        form.setFieldsValue({
            comment: '',
            new_status: '',
            incidence_type: '',
        });
        dispatch(toggleModal(modalName));
    };

    const handleSubmit = async (values) => {
        const obj = {
            ...values,
            order_id: order._id,
            fulfillment_id: fulfillment ? fulfillmentOrLogisticId : undefined,
            logistic_id: fulfillment ? undefined : fulfillmentOrLogisticId,
            old_status: order.fulfillment_status,
            incidences: allIncidencesAssociated ?? [],
        };

        try {
            dispatch(updateOrderStatus(obj));

            if (incidenceSelected) {
                await createManualIncidence({
                    incidence_type: incidenceSelected,
                    responsible_id: userDataLocalStorage._id,
                    order_ids: order._id,
                    fulfillments: [],
                    toast: false,
                });
            }
        } catch (error) {
            console.error('Error updating order status:', error);
        }

        // FORCE CLOSE MODAL
        dispatch(toggleModal(modalName));
    };

    useEffect(() => {
        let icon;

        const fulfillmentStatus =
            order?.logistics_info.length > 1 && !fulfillment
                ? 'unfulfilled'
                : order?.fulfillment_status;

        switch (fulfillmentStatus) {
            case 'unfulfilled':
                icon = <BiHourglass />;
                break;
            case 'sac':
                icon = <BsFillExclamationDiamondFill style={{ color: '#B00303' }} />;
                break;
            case 'delivered_to_courier':
                icon = <DeliveredToCourierIcon />;
                break;
            case 'cancelled':
                icon = <MdOutlineCancel />;
                break;
            case 'fulfilled':
                icon = <FulfilledIcon />;
                break;
            case 'delivered':
                icon = <BsPersonCheck />;
                break;
            case 'in_progress':
                icon = <InProgressIcon />;
                break;
            case 'partially_fulfilled':
                icon = <PartiallyFulfilledIcon />;
                break;
            case 'paused':
                icon = <PausedIcon className=" " />;
                break;

            default:
                break;
        }

        setIconStatus(icon);

        const allStateValues = [];

        if (!fulfillmentOrLogisticId || !fulfillment) {
            if (
                (order.order_status === 'open' && order.fulfillment_status === 'unfulfilled') ||
                (order?.fulfillment_status === 'sac' && !order.fulfillments?.length) ||
                (order.logistics_info.length > 1 && !fulfillment)
            ) {
                allStateValues.push('in_progress');
            }
            if (
                !['closed', 'sac', 'cancelled'].includes(order.order_status) &&
                !['closed', 'sac', 'cancelled'].includes(order.fulfillment_status) &&
                ((!fulfillmentOrLogisticId && order?.logistics_info.length < 1) ||
                    order.fulfillments?.length)
            ) {
                if (!(order.logistics_info.length > 1 && !fulfillment)) allStateValues.push('sac');
            }

            const preparationCdDelivered = order.fulfillments?.[0]
                ?.preparationcd_transfer_pickupstore?.status
                ? order.fulfillments?.[0]?.preparationcd_transfer_pickupstore?.status ===
                  'delivered'
                : true;
            const consolidationDelivered = order.fulfillments?.[0]?.consolidation?.status
                ? order.fulfillments?.[0]?.consolidation?.status === 'delivered_to_courier'
                : true;

            if (
                (order.order_status === 'delivered_to_courier' ||
                    order.fulfillment_status === 'delivered_to_courier') &&
                order.fulfillments?.length === 1 &&
                preparationCdDelivered &&
                consolidationDelivered
            ) {
                allStateValues.push('delivered');
            }
        } else {
            if (!['closed', 'sac', 'anulated', 'cancelled'].includes(fulfillment.status)) {
                allStateValues.push('sac');
            }

            const preparationCdDelivered = fulfillment.preparationcd_transfer_pickupstore?.status
                ? fulfillment.preparationcd_transfer_pickupstore?.status === 'delivered'
                : true;
            const consolidationDelivered = fulfillment.consolidation?.status
                ? fulfillment.consolidation?.status === 'delivered_to_courier'
                : true;

            if (
                fulfillment.status === 'delivered_to_courier' &&
                preparationCdDelivered &&
                consolidationDelivered
            ) {
                allStateValues.push('delivered');
            }
        }

        setAllStates(allStateValues);
    }, [fulfillmentOrLogisticId, order]);

    useEffect(() => {
        form.setFieldValue('user_id', userDataLocalStorage._id);
    }, [userDataLocalStorage]);

    const allIncidencesAssociated = useMemo(() => {
        const lineItemsManualIncidencesAssociated = order?.line_items_manual_incidences?.map(
            (incidence) => incidence?.incidence_type
        );

        const manualIncidencesAssociated = data?.manual_incidences?.map(
            (incidence) => incidence?.incidence_type
        );

        return manualIncidencesAssociated?.concat(lineItemsManualIncidencesAssociated);
    }, [order, data]);

    const status = useMemo(() => {
        const fulfillment =
            order.fulfillments?.find((f) => f._id === fulfillmentOrLogisticId) ??
            (order.fulfillments?.length === 1 ? order.fulfillments[0] : null);

        const statusConsolidationOrTransferCd =
            fulfillment?.consolidation?.status ??
            fulfillment?.preparationcd_transfer_pickupstore?.status;

        if (['received_in_cd_location'].includes(statusConsolidationOrTransferCd))
            return statusConsolidationOrTransferCd;

        return fulfillment?.status ?? order?.fulfillment_status;
    }, [order]);

    return (
        <Modal
            {...modalProps}
            cancelText="Cancelar"
            okText="Confirmar cambio"
            open={isActive}
            title={
                <span style={{ fontSize: '16px', color: '#2D3D76', fontWeight: '600' }}>
                    Cambiar estado del {fulfillment ? 'fulfillment' : 'pedido'}
                </span>
            }
            onOk={form.submit}
            okButtonProps={{
                disabled:
                    !newStatusSelected || newStatusSelected === 'sac' ? !incidenceSelected : false,
            }}
            destroyOnClose
            onCancel={handleCancel}
        >
            <Form form={form} name="order-change-status-form" onFinish={handleSubmit}>
                <Flex direction="column" rowGap="20px">
                    <Flex direction="row" columnGap="10px">
                        <p>Estado actual</p>

                        <Tag style={{ border: '1px solid #2D3D76', height: '30px' }}>
                            <div style={{ marginRight: '5px' }}>{getLabel(status)}</div>
                            {iconStatus}
                        </Tag>
                    </Flex>
                    <Flex direction="row" columnGap="10px">
                        <p style={{ marginTop: '5px' }}>Nuevo estado a cambiar</p>
                        <Form.Item
                            name="new_status"
                            style={{ fontWeight: '500', width: '50%' }}
                            rules={[rulesRequired]}
                        >
                            <SelectTypeOne
                                data={allStates}
                                optionProps={{
                                    renderLabel: renderFulFillmentTag,
                                }}
                                showArrow
                                allowClear
                                showSearch={false}
                                placeholder="Seleccione un estado"
                                onChange={(value) => setNewStatusSelected(value)}
                            />
                        </Form.Item>
                    </Flex>
                    {newStatusSelected === 'sac' && (
                        <Flex direction="row">
                            <IncidenceOrderDetail
                                setIncidenceSelected={setIncidenceSelected}
                                order={order}
                                fulfillmentOrLogisticId={fulfillmentOrLogisticId}
                                userDataLocalStorage={userDataLocalStorage}
                            />
                        </Flex>
                    )}

                    {!fulfillment && (
                        <Flex direction="row" alignItems="center" columnGap="10px">
                            <Checkbox
                                className="checkbox-reversed"
                                checked={hasNote}
                                onChange={(e) => setHasNote(e.target.checked)}
                            />
                            <p>Agregar nota por modificación</p>
                        </Flex>
                    )}

                    {hasNote && !fulfillment && (
                        <Form.Item name="comment">
                            <TextArea placeholder="Escriba aquí..." style={{ resize: 'none' }} />
                        </Form.Item>
                    )}
                </Flex>
                {order?.fulfillment_status === 'sac' && (
                    <div className="mt-5">
                        {allIncidencesAssociated?.length === 0 ? (
                            <span style={{ color: '#6c7575' }} className="flex justify-end text-xs">
                                * Esta orden no tiene incidencias
                            </span>
                        ) : (
                            <div>
                                <span>
                                    Esta orden posee la{!!allIncidencesAssociated?.length && 's'}{' '}
                                    siguiente{!!allIncidencesAssociated?.length && 's'} incidencia
                                    {!!allIncidencesAssociated?.length && 's'} asociada
                                    {!!allIncidencesAssociated?.length && 's'}:{' '}
                                </span>
                                <div className="mt-2">
                                    {allIncidencesAssociated?.map((incidence, i) => (
                                        <ul key={i}>
                                            <Flex columnGap="8px">
                                                <Dot
                                                    size="6px"
                                                    color="#2D3D76"
                                                    style={{ marginTop: '8px' }}
                                                />
                                                <li>
                                                    <b>{allIncidences(incidence)}</b>
                                                </li>
                                            </Flex>
                                        </ul>
                                    ))}
                                </div>

                                <span>
                                    Al confirmar el cambio de estado, esta
                                    {!!allIncidencesAssociated?.length && 's'} incidencia
                                    {!!allIncidencesAssociated?.length && 's'} se eliminará
                                    {!!allIncidencesAssociated?.length && 'n'}.
                                </span>
                                <br />
                                <div className="flex justify-end mt-1 mr-5">
                                    <span>¿Desea continuar?</span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Form>
        </Modal>
    );
};

export default ChangeOrderStatus;
