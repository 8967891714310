import {
    deliveredFailedIncidenceType,
    deliveryLogisticOperatorIncidenceType,
    devolutionIncidenceType,
    directionNotFoundIncidenceType,
    incidenceTypesViewCrossDocking,
    incidenceTypesViewDelivered,
    incidenceTypesViewOrders,
    incidenceTypesViewPendingArrival,
    incidenceTypesViewPreparation,
    insufficientStockIncidenceType,
    otherIncidenceType,
    pickupFailedIncidenceType,
    productInBadConditionIncidenceType,
} from 'helpers/renderManualIncidencesLabels';

export const getIncidenceTypesByStatus = (status, viewPage) => {
    if (
        [
            'sac',
            'cancelled',
            'paused',
            'archived',
            'partially_processed',
            'waiting_collect_manifest',
            'partially_delivered',
            'partially_fulfilled',
        ].includes(status)
    )
        return [];

    let allIncidenceTypes = [];

    if (viewPage === 'order') {
        allIncidenceTypes = incidenceTypesViewOrders;
    } else if (viewPage === 'preparation') {
        allIncidenceTypes = incidenceTypesViewPreparation;
    } else if (viewPage === 'delivered') {
        allIncidenceTypes = incidenceTypesViewDelivered;
    } else if (viewPage === 'pending_arrival') {
        allIncidenceTypes = incidenceTypesViewPendingArrival;
    } else if (viewPage === 'crossdocking') {
        allIncidenceTypes = incidenceTypesViewCrossDocking;
    } else {
        allIncidenceTypes = [
            devolutionIncidenceType,
            directionNotFoundIncidenceType,
            deliveredFailedIncidenceType,
            deliveryLogisticOperatorIncidenceType,
            pickupFailedIncidenceType,
            insufficientStockIncidenceType,
            productInBadConditionIncidenceType,
            otherIncidenceType,
        ];
    }

    switch (status) {
        case 'unfulfilled':
        case 'in_progress':
            allIncidenceTypes = allIncidenceTypes.filter((incidence) =>
                ['insufficient_stock', 'product_in_bad_condition', 'other'].includes(
                    incidence.value
                )
            );
            break;
        case 'fulfilled':
        case 'ready_for_deliver':
            allIncidenceTypes = allIncidenceTypes.filter((incidence) =>
                ['incident_with_order_delivery_by_logistics_operator', 'other'].includes(
                    incidence.value
                )
            );
            break;
        case 'success':
            allIncidenceTypes = allIncidenceTypes.filter((incidence) =>
                [
                    'incident_with_order_delivery_by_logistics_operator',
                    'failed_pickup',
                    'other',
                ].includes(incidence.value)
            );
            break;
        case 'received_in_cd_location':
            allIncidenceTypes = allIncidenceTypes.filter((incidence) =>
                ['incident_with_order_delivery_by_logistics_operator', 'other'].includes(
                    incidence.value
                )
            );
            break;
        case 'delivered_to_courier':
            allIncidenceTypes = allIncidenceTypes.filter((incidence) =>
                [
                    'incident_with_order_delivery_by_logistics_operator',
                    'address_not_found',
                    'failed_delivery',
                    'other',
                ].includes(incidence.value)
            );
            break;
        case 'delivered':
            allIncidenceTypes = allIncidenceTypes.filter((incidence) =>
                ['return_received', 'other'].includes(incidence.value)
            );
            break;
    }

    return allIncidenceTypes;
};

export const getIncidenceTypesByFulfillments = (fulfillments, viewPage) => {
    if (!fulfillments?.length)
        return { data: [], tooltip: 'No hay tipos de incidencias disponibles.' };

    const firstFulfillment = fulfillments[0];
    let statusFulfillment = firstFulfillment?.status;
    const statusPreparationCdFulfillment =
        firstFulfillment?.preparationcd_transfer_pickupstore?.status;
    const statusConsolidationFulfillment = firstFulfillment?.consolidation?.status;
    let incidenceTypeStatus = '';

    if (
        'received_in_cd_location' === statusPreparationCdFulfillment ||
        'received_in_cd_location' === statusConsolidationFulfillment
    ) {
        statusFulfillment = statusPreparationCdFulfillment ?? statusConsolidationFulfillment;
    }

    const equalStatus = fulfillments.every((fulfillment) => {
        const otherStatus =
            fulfillment?.preparationcd_transfer_pickupstore?.status ||
            fulfillment?.consolidation?.status;
        if ('received_in_cd_location' === otherStatus) return otherStatus === statusFulfillment;

        return fulfillment.status === statusFulfillment;
    });

    if (!equalStatus) {
        return {
            data: [],
            tooltip: 'No es posible seleccionar pedidos con estados diferentes.',
        };
    }

    if (
        'received_in_cd_location' === statusPreparationCdFulfillment ||
        'received_in_cd_location' === statusConsolidationFulfillment
    ) {
        incidenceTypeStatus = statusPreparationCdFulfillment ?? statusConsolidationFulfillment;
    } else {
        incidenceTypeStatus = statusFulfillment;
    }

    const res = getIncidenceTypesByStatus(incidenceTypeStatus, viewPage);

    return {
        data: res,
        tooltip: res.length ? '' : 'No hay tipos de incidencias disponibles para el estado.',
    };
};

export const getIncidenceTypesByOrders = (orders, viewPage) => {
    if (!orders?.length) return { data: [], tooltip: 'No hay tipos de incidencias disponibles.' };

    let incidenceTypeStatus = '';
    const firstOrder = orders[0];
    let statusFulfillment = firstOrder?.fulfillment_status;
    const statusPreparationCdFulfillment = firstOrder?.preparationcd_transfer_pickupstore?.status;
    const statusConsolidationFulfillment = firstOrder?.consolidation?.status;

    if (
        'received_in_cd_location' === statusPreparationCdFulfillment ||
        'received_in_cd_location' === statusConsolidationFulfillment
    ) {
        statusFulfillment = statusPreparationCdFulfillment ?? statusConsolidationFulfillment;
    }

    const equalStatus = orders.every((order) => {
        const otherStatus =
            order?.preparationcd_transfer_pickupstore?.status || order?.consolidation?.status;

        if ('received_in_cd_location' === otherStatus) return otherStatus === statusFulfillment;

        return order.fulfillment_status === statusFulfillment;
    });

    if (!equalStatus) {
        return {
            data: [],
            tooltip: 'No es posible seleccionar pedidos con estados diferentes.',
        };
    }

    if (
        'received_in_cd_location' === statusPreparationCdFulfillment ||
        'received_in_cd_location' === statusConsolidationFulfillment
    ) {
        incidenceTypeStatus = statusPreparationCdFulfillment ?? statusConsolidationFulfillment;
    } else {
        incidenceTypeStatus = statusFulfillment;
    }

    const res = getIncidenceTypesByStatus(incidenceTypeStatus, viewPage);

    return {
        data: res,
        tooltip: res.length ? '' : 'No hay tipos de incidencias disponibles para el estado.',
    };
};
