import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';

export const generalApi = createApi({
    reducerPath: 'general',
    baseQuery,
    tagTypes: ['general'],
    endpoints: (builder) => ({
        getPaymentMethods: builder.query({
            query: () => ({
                url: `settings/generals/payment-methods`,
                method: 'get',
            }),
        }),
    }),
});

export const { useGetPaymentMethodsQuery } = generalApi;
