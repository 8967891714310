import styled, { css } from 'styled-components';

export const CheckboxContent = styled.div`
    display: grid;

    margin-bottom: 5px;
    ${({ paddingLeft }) =>
        paddingLeft &&
        css`
            padding-left: ${paddingLeft};
        `}
    .ant-form-item {
        margin-bottom: 3px;
    }
`;
