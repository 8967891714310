import { useState, useEffect, Suspense, useMemo } from 'react';
import { Skeleton, Typography } from 'antd';
import dayjs from 'helpers/dayjs';
import Tag from 'components/Tag';
import Actions from '../Actions';
import { AssignedOperators } from './AssignedOperators';
import {
    HeaderLabelBody,
    HeaderLabelTitle,
    HeaderTitle,
    HeaderLabelWrapper,
    HeaderBody,
    HeaderCollapsible,
    HeaderFooter,
    BackButton,
    HeaderHomeStyle,
} from './styles';
import useGetPreparationPreference from 'hooks/useGetPreparationPreference';
import { AttachedFiles } from './AttachedFiles';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BiHourglass } from 'react-icons/bi'; //pendiente
import { BsFillExclamationDiamondFill, BsPersonCheck } from 'react-icons/bs'; // sac
import { MdOutlineCancel } from 'react-icons/md'; // cancelado
import { ReactComponent as DeliveredToCourierIcon } from 'assets/estado-despachado.svg'; // entregado a courier
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';
import { CopyOutlined } from '@ant-design/icons';
import { toggleDrawer } from 'redux/features/Utils';
import { deliveryPromiseDateFormatDic } from 'helpers/constants/deliveryPromiseDateFormat';
import { useGetOrdersConfigurationQuery } from 'redux/features/OrdersConfiguration';
import Flex from 'components/Utils/Flex';
import FulfillmentDeadline from 'pages/OrdersV2/components/Table/FulfillmentDeadline';
import DetailsIncidenceDrawerOrder from 'components/ManualIncidences/Order/ShowDetailsDrawer';
import { changeDetailsManualIncidenceOrderDrawerField } from 'redux/features/ManualIncidences/detailsManualIncidenceOrderDrawer';

// TODO: Delete when use_orders_v3 is deleted
import { ReactComponent as InProgressIcon } from 'assets/in_progress.svg'; // en proceso
import { ReactComponent as FulfilledIcon } from 'assets/box.svg'; // preparado
import { ReactComponent as PartiallyFulfilledIcon } from 'assets/box-unpackec.svg'; // parcialmente preparado
import { ReactComponent as PausedIcon } from 'assets/pause-circle-blue.svg'; //  pausado
import { useGetValueFeatureFlag } from 'app-config/useGetValueFeatureFlag';
import useMasterLabels from 'hooks/useMasterLabels';

const HeaderLabel = ({ children, title, ...otherProps }) => {
    return (
        <HeaderLabelWrapper {...otherProps}>
            <HeaderLabelTitle>{title ?? ''}</HeaderLabelTitle>
            <HeaderLabelBody>{children}</HeaderLabelBody>
        </HeaderLabelWrapper>
    );
};

const Header = ({ returnPreferences, data, loading, refetch = () => {}, showActions = true }) => {
    const [showMoreHeader, setShowMoreHeader] = useState(true);
    const [iconStatus, setIconStatus] = useState('');
    const [textStatus, setTextStatus] = useState('');
    const [, , preparationPreferences] = useGetPreparationPreference();
    const toggleHeader = () => setShowMoreHeader(!showMoreHeader);
    const history = useHistory();
    const dispatch = useDispatch();
    const { data: orderConfiguration } = useGetOrdersConfigurationQuery();
    const hasMoreThanOneFulfillment = data?.fulfillments?.length > 1;
    const deliveryPromiseDateFormat = orderConfiguration?.delivery_promise_date_format;
    const useOrdersV3 = useGetValueFeatureFlag('use_orders_v3', 'enabled');
    const { getLabel } = useMasterLabels();

    useEffect(() => {
        let icon;
        let text;
        console.log('-===', useOrdersV3, data.fulfillment_status);

        if (!useOrdersV3) {
            if (data?.fulfillment_status === 'unfulfilled') {
                icon = <BiHourglass className="header-table-icon-status" />;
            }
            if (data?.fulfillment_status === 'sac') {
                icon = <BsFillExclamationDiamondFill className="header-table-icon-status icon-sac" />;
            }
            if (data?.fulfillment_status === 'delivered_to_courier') {
                icon = <DeliveredToCourierIcon className="icon-header-delivered-to-courier" />;
            }
            if (data?.fulfillment_status === 'cancelled') {
                icon = <MdOutlineCancel className="header-table-icon-cancelled" />;
            }
            if (data?.fulfillment_status === 'fulfilled') {
                icon = <FulfilledIcon className="header-table-icon-status fulfilled-icon" />;
            }
            if (data?.fulfillment_status === 'delivered') {
                icon = <BsPersonCheck className="header-table-icon-delivered" />;
            }
            if (data?.fulfillment_status === 'in_progress') {
                icon = <InProgressIcon className="header-table-icon-status in-progress-icon" />;
            }
            if (data?.fulfillment_status === 'partially_fulfilled') {
                icon = (
                    <PartiallyFulfilledIcon className="header-table-icon-status  partially_fulfilled-icon" />
                );
            }
            if (data?.fulfillment_status === 'paused') {
                icon = <PausedIcon className=" " />;
            }

            setTextStatus(getLabel(data?.fulfillment_status));
            setIconStatus(icon);
            return;
        }


        if (data?.order_status === 'open') {
            text = 'Abierto';
            icon = <BiHourglass className="header-table-icon-status" />;
        }
        if (data?.order_status === 'sac') {
            text = 'SAC';
            icon = <BsFillExclamationDiamondFill className="header-table-icon-status icon-sac" />;
        }

        if (data?.order_status === 'cancelled') {
            text = 'Cancelado';
            icon = <MdOutlineCancel className="header-table-icon-cancelled" />;
        }

        if (data?.order_status === 'archived') {
            text = 'Archivado';
            icon = <BsPersonCheck className="header-table-icon-delivered" />;
        }

        setTextStatus(text);
        setIconStatus(icon);
    }, [data, useOrdersV3]);

    const transferPickupStoreLogistic = useMemo(() => {
        return (
            data?.logistics_info?.find(
                (logistic) => logistic?.preparationcd_transfer_pickupstore?.pickup_location
            )?.preparationcd_transfer_pickupstore ?? {}
        );
    }, [data]);

    return (
        <>
            <HeaderHomeStyle>
                <Link style={{ color: '#7998a7' }} to="/">
                    Home
                </Link>{' '}
                /{' '}
                <Link style={{ color: '#7998a7' }} to="/orders">
                    Pedidos
                </Link>
            </HeaderHomeStyle>

            <HeaderCollapsible>
                {loading ? (
                    <Skeleton active />
                ) : (
                    <>
                        <HeaderBody $showMore={showMoreHeader}>
                            <div className="order-page__header-back-button">
                                <BackButton onClick={() => history.goBack()}>
                                    <AiOutlineArrowLeft />
                                </BackButton>
                            </div>
                            <HeaderTitle className="order-page__header-title">
                                {data?.name ?? ''}
                                <HeaderLabel
                                    className="order-page__header-id"
                                    title="ID Ecommerce:"
                                >
                                    <Typography.Text
                                        style={{ color: '#037FB9' }}
                                        copyable={{
                                            text: data?.remote_order_id,
                                            icon: <CopyOutlined style={{ color: '#037FB9' }} />,
                                        }}
                                        underline
                                    >
                                        {data?.remote_order_id}
                                    </Typography.Text>
                                </HeaderLabel>
                            </HeaderTitle>
                            <HeaderLabel
                                className="order-page__header-date"
                                title="Fecha creación:"
                            >
                                <b>{dayjs(data?.createdAt).format('DD/MM/YYYY')}</b>
                            </HeaderLabel>
                            {!hasMoreThanOneFulfillment && (
                                <HeaderLabel
                                    className="order-page__header-promised_date"
                                    title={`${
                                        deliveryPromiseDateFormatDic(deliveryPromiseDateFormat)
                                            .title
                                    }:`}
                                >
                                    <b>
                                        <Flex justifyContent="center" alignItems="center">
                                            <Suspense fallback={<div>Cargando...</div>}>
                                                <FulfillmentDeadline
                                                    isDeadline={
                                                        deliveryPromiseDateFormatDic(
                                                            deliveryPromiseDateFormat
                                                        ).isHours
                                                    }
                                                    row={data}
                                                />
                                            </Suspense>
                                        </Flex>
                                    </b>
                                </HeaderLabel>
                            )}
                            <HeaderLabel className="order-page__header-location" title="Tienda:">
                                <b>
                                    {transferPickupStoreLogistic?.pickup_location ? (
                                        <Flex direction="row" alignItems="center" gap="8px">
                                            <div className="order_page_product_table_value_location">
                                                {transferPickupStoreLogistic?.cd_location?.name}
                                            </div>
                                            <DeliveredToCourierIcon
                                                style={{ width: '20px', height: '20px' }}
                                            />
                                            <div className="order_page_product_table_value_location">
                                                {transferPickupStoreLogistic?.pickup_location?.name}
                                            </div>
                                        </Flex>
                                    ) : (
                                        data?.location_id?.name
                                    )}
                                </b>
                            </HeaderLabel>
                            <HeaderLabel
                                className="order-page__header-sales-channel"
                                title="Canal de venta:"
                            >
                                <b>{data?.sales_channel?.alias}</b>
                            </HeaderLabel>

                            <HeaderLabel className="order-page__files-attachments">
                                <AttachedFiles
                                    data={data}
                                    preparationPreferences={preparationPreferences}
                                />
                            </HeaderLabel>
                            <HeaderLabel className="order-page__header-status" title="Estado del pedido:">
                                <Tag
                                    color="info"
                                    className="header-tag-status-styles"
                                    onClick={() => {
                                        if (data?.status === 'sac') {
                                            dispatch(
                                                toggleDrawer(
                                                    'manual-incidence-details-order-drawer'
                                                )
                                            );
                                            dispatch(
                                                changeDetailsManualIncidenceOrderDrawerField([data])
                                            );
                                        }
                                    }}
                                >
                                    <b>{textStatus}</b>
                                    {iconStatus}
                                </Tag>
                            </HeaderLabel>
                            {preparationPreferences?.preparations_preferences
                                ?.required_operator && (
                                <HeaderLabel
                                    className="order-page__header-responsables"
                                    title={`Responsables: ${
                                        data?.assigned_operators?.length === 0
                                            ? 'Pendientes por asignar'
                                            : ''
                                    } `}
                                >
                                    {data?.assigned_operators?.map((operator) => {
                                        return (
                                            <AssignedOperators
                                                key={operator?._id}
                                                operator={operator}
                                            />
                                        );
                                    })}
                                </HeaderLabel>
                            )}

                            {showActions ? (
                                <HeaderLabel className="order-page__header-action-button">
                                    <Actions
                                        returnPreferences={returnPreferences}
                                        orderData={data}
                                        refetch={refetch}
                                    />
                                </HeaderLabel>
                            ) : (
                                ''
                            )}
                        </HeaderBody>
                        <HeaderFooter $showMore={showMoreHeader}>
                            <u role="button" onClick={toggleHeader}>
                                {showMoreHeader ? 'Ver menos' : 'Ver más'}
                            </u>
                            <FiChevronDown />
                        </HeaderFooter>
                    </>
                )}
                <DetailsIncidenceDrawerOrder />
            </HeaderCollapsible>
        </>
    );
};
export default Header;
