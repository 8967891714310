export const devolutionIncidenceType = {
    value: 'return_received',
    label: 'Devolución recibida',
    need_select_package: false,
};

export const directionNotFoundIncidenceType = {
    value: 'address_not_found',
    label: 'Dirección no encontrada',
    need_select_package: false,
};

export const deliveredFailedIncidenceType = {
    value: 'failed_delivery',
    label: 'Entrega fallida',
    need_select_package: false,
};

export const deliveryLogisticOperatorIncidenceType = {
    value: 'incident_with_order_delivery_by_logistics_operator',
    label: 'Incidencia con entrega de pedido por operador logístico',
    need_select_package: false,
};

export const pickupFailedIncidenceType = {
    value: 'failed_pickup',
    label: 'Retiro fallido',
    need_select_package: false,
};

export const insufficientStockIncidenceType = {
    value: 'insufficient_stock',
    label: 'Stock insuficiente',
    need_select_package: false,
};

export const productInBadConditionIncidenceType = {
    value: 'product_in_bad_condition',
    label: 'Producto en mal estado',
    need_select_package: false,
};

export const otherIncidenceType = {
    value: 'other',
    label: 'Otro',
    need_select_package: false,
};

export const orderIncidencesTypesFromOrdersView = [insufficientStockIncidenceType];

export const orderIncidencesTypeFormPendingDeliveries = [deliveryLogisticOperatorIncidenceType];

export const orderIncidencesTypeFormArrivalPending = [
    pickupFailedIncidenceType,
    otherIncidenceType,
];

export const orderIncidencesTypesFromDeliveriesView = [
    directionNotFoundIncidenceType,
    deliveredFailedIncidenceType,
    deliveryLogisticOperatorIncidenceType,
    pickupFailedIncidenceType,
];

export const orderIncidencesTypesFromDeliveriesViewIsDelivered = [devolutionIncidenceType];

export const fulfillmentIncidencesTypes = [
    insufficientStockIncidenceType,
    productInBadConditionIncidenceType,
    otherIncidenceType,
];

export const incidenceTypesViewOrders = [
    devolutionIncidenceType,
    deliveryLogisticOperatorIncidenceType,
    directionNotFoundIncidenceType,
    deliveredFailedIncidenceType,
    pickupFailedIncidenceType,
    otherIncidenceType,
];

export const incidenceTypesViewPreparation = [
    insufficientStockIncidenceType,
    productInBadConditionIncidenceType,
    otherIncidenceType,
];

export const incidenceTypesViewDelivered = [
    devolutionIncidenceType,
    deliveryLogisticOperatorIncidenceType,
    directionNotFoundIncidenceType,
    deliveredFailedIncidenceType,
    pickupFailedIncidenceType,
    otherIncidenceType,
];

export const incidenceTypesViewPendingArrival = [pickupFailedIncidenceType, otherIncidenceType];

export const incidenceTypesViewCrossDocking = [
    deliveryLogisticOperatorIncidenceType,
    otherIncidenceType,
];
