import { createApi } from '@reduxjs/toolkit/query/react';
import getFromEnv from 'helpers/getFromEnv';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';

const createIncidentApis = {
    dev: '',
    demo: '',
    prod: '',
};

export const manualIncidencesApi = createApi({
    reducerPath: 'manual_incidences',
    baseQuery,
    tagTypes: ['manual_incidences'],
    endpoints: (builder) => ({
        getManualIncidences: builder.query({
            method: 'get',
            query: ({ order_id, fulfillment_id } = {}) => {
                let url = '/incidents-v2/manual-incidences';

                if (order_id) {
                    url += `?order_id=${order_id}`;
                } else if (fulfillment_id) {
                    url += `?fulfillment_id=${fulfillment_id}`;
                }
                return url;
            },
            providesTags: ['manual_incidences'],
        }),
        createManualIncidence: builder.mutation({
            method: 'post',
            query: (body) => {
                const currentUrl =
                    createIncidentApis[getFromEnv('REACT_APP_STAGE')] ||
                    '/incidents-v2/manual-incidences';
                return {
                    url: currentUrl,
                    method: 'POST',
                    body: body,
                };
            },
            onQueryStarted: (data, api) => {
                data.toast === false
                    ? ''
                    : toast.promise(api.queryFulfilled, {
                          loading: <b>Creando incidencia manual...</b>,
                          success: <b>Incidencia manual creada con éxito</b>,
                          error: <b>Ocurrió un error.</b>,
                      });
            },
        }),
    }),
});

export const { useLazyGetManualIncidencesQuery, useCreateManualIncidenceMutation } =
    manualIncidencesApi;
