/* eslint-disable no-unused-vars */
import { Tooltip } from 'antd';
import Tag from 'components/Tag';
import Flex from 'components/Utils/Flex';
import dayjs from 'helpers/dayjs';
import useMasterLabels from 'hooks/useMasterLabels';
import useRenderSLAByStatus from 'hooks/useRenderSLAByStatus';
// import renderFulfillmentLabel from './renderFulfillmentLabels';
import { ReactComponent as TimerSandIcon } from 'assets/timer-sand.svg';
import { ReactComponent as InProgressIcon } from 'assets/in_progress.svg';
import { ReactComponent as BoxUnpackedIcon } from 'assets/box-unpacked.svg';
import { ReactComponent as BoxIcon } from 'assets/box.svg';
import { ReactComponent as TrollerIcon } from 'assets/trolley-svgrepo-com.svg';
import { ReactComponent as TruckIcon } from 'assets/truck-delivery-outline.svg';
import { ReactComponent as CancelIcon } from 'assets/x-circle.svg';
import { ReactComponent as SACIcon } from 'assets/alert-rhombus.svg';
import { ReactComponent as ArchiveIcon } from 'assets/archive-icon.svg';
// icons
import { BiHourglass } from 'react-icons/bi'; //pendiente
import { BsFillExclamationDiamondFill } from 'react-icons/bs'; // sac
import { MdOutlineCancel } from 'react-icons/md'; // cancelado
import { BsPersonCheck } from 'react-icons/bs'; // entregado
// import { ReactComponent as InProgressIcon } from 'assets/in_progress.svg'; // en proceso
// import { ReactComponent as FulfilledIcon } from 'assets/box.svg'; // preparado
import { BsBox } from 'react-icons/bs'; //preparado
// import { FaBoxOpen } from 'react-icons/fa'; ///parcialmente preparado
import { BsBoxSeam } from 'react-icons/bs'; ///parcialmente preparado
// import { ReactComponent as PartiallyFulfilledIcon } from 'assets/box-unpackec.svg'; // parcialmente preparado
import { ReactComponent as PausedIcon } from 'assets/pause-circle-blue.svg'; //  pausado
import { ReactComponent as DeliveredToCourierIcon } from 'assets/estado-despachado.svg';
import { useEffect, useState } from 'react';
import { getSlaPercentage } from './getSlaPercentage';
import { semaphoreDefaultStates } from 'pages/Settings/components/Preparations/SLADeliveryPromise/SLALevelUrgency/constants';

export const renderOrderStatusTag = (status) => {
    const { getLabel } = useMasterLabels();

    switch (status) {
        case 'open':
            return <Tag color="success">{getLabel('open')}</Tag>;
        case 'closed':
            return <Tag color="archived">{getLabel('archived')}</Tag>;
        case 'cancelled':
            return <Tag color="archived">{getLabel('cancelled')}</Tag>;
        case 'archived':
            return (
                <Tag>
                    <span style={{ textDecoration: 'line-through' }}>Archivada</span>
                </Tag>
            );
        default:
            return <Tag>{status}</Tag>;
    }
};

export const renderFulfillmentStatus = (value) => {
    const { getLabel } = useMasterLabels();
    if (value === 'open') {
        return <Tag color="warning">{getLabel('open')}</Tag>;
    }
    if (value === 'paused') {
        return <Tag color="danger">{getLabel('paused')}</Tag>;
    }
    if (value === 'sac') {
        return <Tag color="danger">{getLabel('sac')}</Tag>;
    }
    return <Tag color="warning">{value}</Tag>;
};

export const renderOrderSacStatusTag = (status) => {
    const { getLabel } = useMasterLabels();
    if (status === 'in_normal_process') return <Tag> {getLabel('in_normal_process')}</Tag>;
    if (status === 'in_courier_shipping') return <Tag>{getLabel('in_courier_shipping')}</Tag>;
    if (status === 'in_dispatch_courier') return <Tag>{getLabel('in_dispatch_courier')}</Tag>;
    if (status === 'finished_normal') return <Tag>{getLabel('finished_normal')}</Tag>;
    if (status === 'waiting_for_solution') return <Tag>{getLabel('waiting_for_solution')}</Tag>;
    if (status === 'client_contacted') return <Tag>{getLabel('client_contacted')}</Tag>;
    if (status === 'finished_with_incidence')
        return <Tag>{getLabel('finished_with_incidence')}</Tag>;
};

const icons = {
    archived: <ArchiveIcon color="#2D3D76" width={18} height={18} />,
    unfulfilled: <TimerSandIcon width={18} height={18} />,
    in_progress: <InProgressIcon width={18} height={18} />,
    partially_fulfilled: <BoxUnpackedIcon width={18} height={18} />,
    fulfilled: <BoxIcon width={18} height={18} />,
    delivered: <TrollerIcon color="#2D3D76" width={18} height={18} />,
    delivered_to_courier: <TruckIcon color="#2D3D76" width={18} height={18} />,
    ready_for_deliver: <TrollerIcon color="#2D3D76" width={18} height={18} />,
    success: <TrollerIcon color="#2D3D76" width={18} height={18} />,
    cancelled: <CancelIcon width={18} height={18} />,
    sac: <SACIcon width={18} height={18} />,
};

const statusColors = {
    open: '#B00303',
    paused: '#B00303',
    cancelled: '#B00303',
    sac: '#B00303',
    partially_processed: '#E3CD06',
    pending_pdf: '#E3CD06',
    ready_for_deliver: '#089460',
    delivered_to_courier: '#089460',
    delivered: '#089460',
};

const StandardTag = ({ type, dateElement, slaColor, status, customLabel, onClick, cursor }) => {
    const { getLabel } = useMasterLabels();

    return (
        <Tag
            style={{
                border: '1px solid #2D3D76',
                backgroundColor: '#F5F5F5',
                color: '#536D8F',
            }}
        >
            {type === 'deadline' && (
                <>
                    <span
                        style={{
                            backgroundColor: slaColor,
                            width: '10px',
                            height: '10px',
                            marginRight: '5px',
                            borderRadius: '50%',
                        }}
                    ></span>
                    <span
                        style={{
                            fontWeight: '700',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {dateElement}
                    </span>
                </>
            )}

            {type === 'fulfillmentStatus' && (
                <>
                    <span
                        style={{
                            backgroundColor: statusColors[customLabel] ?? '#E3CD06',
                            width: '10px',
                            height: '10px',
                            marginRight: '5px',
                            borderRadius: '50%',
                        }}
                    ></span>
                    <span
                        style={{
                            fontWeight: '700',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {getLabel(customLabel)}
                    </span>
                </>
            )}

            {type === 'status' && (
                <div
                    onClick={onClick}
                    style={{
                        minWidth: '120px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        ...(cursor && {
                            cursor: 'pointer',
                        }),
                    }}
                >
                    <span
                        style={{
                            fontWeight: '400',
                            color: '#2d3d76',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginRight: '5px',
                        }}
                    >
                        {getLabel(customLabel)}
                    </span>
                    {icons[status] ?? icons.unfulfilled}
                </div>
            )}
        </Tag>
    );
};

export const renderFulFillmentTagSLA = ({
    status,
    onClick,
    createdAt,
    delivery_date,
    promised_date,
    customLabel,
    showDate = true,
    showStatus = true,
    cursor,
    // statusInTag = false,
    isDeadline = false,
    deadline = false,
    isFulfillmentStatus = false,
    timezone = 'America/Santiago',
}) => {
    //const current_date = dayjs();
    let currentPromiseDate = promised_date || createdAt;

    const haveUTCString = currentPromiseDate.includes('Z');

    if (!haveUTCString) {
        currentPromiseDate = `${currentPromiseDate}Z`;
    }

    const formatted_promised_date = dayjs(currentPromiseDate);

    const slaPercentage = getSlaPercentage({
        delivery_date,
        promise_date: currentPromiseDate,
        created_at: createdAt,
        timezone,
    });

    const localPreparationSettings = JSON.parse(
        localStorage.getItem('preparationSettings') || '{}'
    );

    const slaLevelUrgency = localPreparationSettings?.sla_level_urgency ?? semaphoreDefaultStates;

    let slaLevel = slaLevelUrgency?.find((sla) => {
        const greaterThan = slaPercentage > sla.range[0];
        const slaIsGreaterThan100 = sla.range[1] > 100;

        if (greaterThan && slaIsGreaterThan100) return true;

        return slaPercentage >= sla.range[0] && slaPercentage <= sla.range[1];
    });

    if (!slaLevel) {
        slaLevel = slaLevelUrgency.find((sla) => sla.state === 'serious');
    }

    let date_element = '';

    if (!isDeadline) {
        date_element = (
            <div className="flex flex-col items-center justify-center">
                {promised_date !== '' ? (
                    <Flex gap="5px">
                        <span>{dayjs(formatted_promised_date).format('DD/MM/YYYY')}</span>
                        <span>{dayjs(formatted_promised_date).format('HH:mm')}</span>
                    </Flex>
                ) : (
                    <span>Por definir</span>
                )}
            </div>
        );
    }

    if (isDeadline) {
        const now = dayjs(delivery_date || new Date());

        let diff = dayjs(currentPromiseDate).diff(now, 'hours');

        if (diff < 0) {
            diff = `${diff}`;
        }

        if (deadline) {
            diff = deadline;
        }

        date_element = (
            <div className="flex flex-col items-center justify-center">
                <Tooltip
                    placement="topLeft"
                    title={formatted_promised_date.utc(false).format('DD/MM/YYYY HH:mm')}
                >
                    {promised_date !== '' ? <span>{diff || 0} hrs</span> : <span>-</span>}
                </Tooltip>
            </div>
        );
    }

    return (
        <Flex direction="row">
            {showDate && (
                <StandardTag
                    type="deadline"
                    dateElement={date_element}
                    slaColor={slaLevel?.color}
                    status={status}
                />
            )}
            {showStatus && (
                <StandardTag
                    type="status"
                    customLabel={customLabel ?? status}
                    status={status}
                    onClick={onClick}
                    cursor={cursor}
                />
            )}

            {isFulfillmentStatus && (
                <StandardTag
                    type="fulfillmentStatus"
                    customLabel={customLabel ?? status}
                    status={status}
                />
            )}
        </Flex>
    );
};

export const renderFulFillmentTag = (status) => {
    const [iconStatus, setIconStatus] = useState('');

    // const [updateStatus] = useUpdateOrderStatusMutation();
    useEffect(() => {
        let icon;

        if (status === 'unfulfilled') {
            icon = <BiHourglass style={{ color: '#2D3D76' }} />;
        }
        if (status === 'sac') {
            icon = <BsFillExclamationDiamondFill style={{ color: '#B00303' }} />;
        }
        if (status === 'delivered_to_courier') {
            icon = <DeliveredToCourierIcon style={{ color: '#2D3D76' }} />;
        }
        if (status === 'cancelled') {
            icon = <MdOutlineCancel style={{ color: '#2D3D76' }} />;
        }
        if (status === 'fulfilled') {
            icon = <BsBox style={{ color: '#2D3D76' }} />;
        }
        if (['delivered'].includes(status)) {
            icon = <BsPersonCheck style={{ color: '#2D3D76' }} />;
        }
        if (['archived'].includes(status)) {
            icon = <ArchiveIcon style={{ color: '#2D3D76' }} />;
        }
        if (status === 'in_progress') {
            icon = <InProgressIcon style={{ color: '#2D3D76' }} />;
        }
        if (status === 'partially_fulfilled') {
            icon = <BsBoxSeam style={{ color: '#2D3D76' }} />;
        }
        if (status?.fulfillment_status === 'paused') {
            icon = <PausedIcon style={{ color: '#2D3D76' }} />;
        }

        setIconStatus(icon);
    }, [status]);

    const { getLabel } = useMasterLabels();

    if (status === 'delivered') {
        return (
            <Tag style={{ border: '1px solid #2D3D76' }} centered>
                <div style={{ marginRight: '5px' }}>{getLabel('delivered')}</div>
                {iconStatus}
            </Tag>
        );
    }

    if (status === 'fulfilled') {
        return (
            <Tag style={{ border: '1px solid #2D3D76' }} centered>
                <div style={{ marginRight: '5px' }}>{getLabel('fulfilled')}</div>
                {iconStatus}
            </Tag>
        );
    }

    if (status === 'unfulfilled' || !status) {
        return (
            <Tag style={{ border: '1px solid #2D3D76' }} centered>
                <div style={{ marginRight: '5px' }}>{getLabel('unfulfilled')}</div>
                {iconStatus}
            </Tag>
        );
    }

    if (status === 'in_progress') {
        return (
            <Tag style={{ border: '1px solid #2D3D76' }} centered>
                <div style={{ marginRight: '5px' }}>{getLabel('in_progress')}</div>
                {iconStatus}
            </Tag>
        );
    }

    if (status === 'partially_fulfilled') {
        return (
            <Tag style={{ border: '1px solid #2D3D76' }} centered>
                <div style={{ marginRight: '5px' }}>{getLabel('partially_fulfilled')}</div>
                {iconStatus}
            </Tag>
        );
    }

    if (status === 'sac') {
        return (
            <Tag style={{ border: '1px solid #2D3D76' }} centered>
                <div style={{ marginRight: '5px' }}>{getLabel('sac')}</div>
                {iconStatus}
            </Tag>
        );
    }

    return (
        <Tag style={{ border: '1px solid #2D3D76' }} centered>
            {status}
        </Tag>
    );
};

export const renderConsolidationTagInArrival = (status) => {
    const tagsDic = {
        created: {
            label: 'Pendiente',
            icon: <BiHourglass style={{ color: '#2D3D76' }} />,
        },
        ready_for_deliver_to_cd_location: {
            label: 'Listo para despachar a CD/Tienda',
            icon: <BsBox style={{ color: '#2D3D76' }} />,
        },
        delivered_to_cd_location: {
            label: 'Despachado a CD/Tienda',
            icon: <DeliveredToCourierIcon style={{ color: '#2D3D76' }} />,
        },
        received_in_cd_location: {
            label: 'Recepcionado en CD/Tienda',
            icon: <BsPersonCheck style={{ color: '#2D3D76' }} />,
        },
        ready_for_deliver_to_pickup_location: {
            label: 'Listo para despacho a tienda',
            icon: <BsBox style={{ color: '#2D3D76' }} />,
        },
        delivered_to_pickup_location: {
            label: 'Despachado a tienda',
            icon: <DeliveredToCourierIcon style={{ color: '#2D3D76' }} />,
        },
        cancelled: {
            label: 'Cancelado',
            icon: <MdOutlineCancel style={{ color: '#2D3D76' }} />,
        },
        delivered_to_courier: {
            label: 'Despachado',
            icon: <BsPersonCheck style={{ color: '#2D3D76' }} />,
        },
        received_in_pickup_location: {
            label: 'Listo para retiro',
            icon: <BsPersonCheck style={{ color: '#2D3D76' }} />,
        },
        delivered: {
            label: 'Entregado',
            icon: <BsPersonCheck style={{ color: '#2D3D76' }} />,
        },
        archived: {
            label: 'Archivado',
            icon: <ArchiveIcon style={{ color: '#2D3D76' }} />,
        },
        success: {
            label: 'Listo para despachar a cliente',
            icon: <BsPersonCheck style={{ color: '#2D3D76' }} />,
        },
        waiting_collect_manifest: {
            label: 'Listo para despachar a cliente',
            icon: <BsPersonCheck style={{ color: '#2D3D76' }} />,
        },
    };
    const currentTag = tagsDic[status];

    return (
        <Tag
            centered
            style={{
                border: `1px solid rgb(45, 61, 118)`,
                color: '#536D8F',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            <span style={{ marginRight: 5 }}>{currentTag?.label}</span>
            {currentTag?.icon}
        </Tag>
    );
};

export const renderPickupTag = (status) => {
    const tagsDic = {
        created: {
            label: 'Pendiente',
            icon: <BiHourglass style={{ color: '#2D3D76' }} />,
        },
        ready_for_deliver_to_cd_location: {
            label: 'Listo para despachar a CD/Tienda',
            icon: <BsBox style={{ color: '#2D3D76' }} />,
        },
        delivered_to_cd_location: {
            label: 'Despachado a CD/Tienda',
            icon: <DeliveredToCourierIcon style={{ color: '#2D3D76' }} />,
        },
        received_in_cd_location: {
            label: 'Recepcionado en CD/Tienda',
            icon: <BsPersonCheck style={{ color: '#2D3D76' }} />,
        },
        ready_for_deliver_to_pickup_location: {
            label: 'Listo para despacho a tienda',
            icon: <BsBox style={{ color: '#2D3D76' }} />,
        },
        delivered_to_pickup_location: {
            label: 'Despachado a tienda',
            icon: <DeliveredToCourierIcon style={{ color: '#2D3D76' }} />,
        },
        cancelled: {
            label: 'Cancelado',
            icon: <MdOutlineCancel style={{ color: '#2D3D76' }} />,
        },
        delivered_to_courier: {
            label: 'Recepcionado en CD/Tienda',
            icon: <BsPersonCheck style={{ color: '#2D3D76' }} />,
        },
        received_in_pickup_location: {
            label: 'Listo para retiro',
            icon: <BsPersonCheck style={{ color: '#2D3D76' }} />,
        },
        delivered: {
            label: 'Entregado',
            icon: <BsPersonCheck style={{ color: '#2D3D76' }} />,
        },
        archived: {
            label: 'Archivado',
            icon: <ArchiveIcon style={{ color: '#2D3D76' }} />,
        },
    };
    const currentTag = tagsDic[status];

    return (
        <Tag
            centered
            style={{
                border: `1px solid rgb(45, 61, 118)`,
                color: '#536D8F',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            <span style={{ marginRight: 5 }}>{currentTag?.label}</span>
            {currentTag?.icon}
        </Tag>
    );
};
