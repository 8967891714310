import { Card, Table } from 'antd';
import styled, { css } from 'styled-components';

export const TableCard = styled(Card)`
    width: 100%;

    .ant-card-body {
        padding: 1px 0rem;
        overflow-x: auto;
    }

    height: auto !important;

    ${({ borderColor }) =>
        borderColor &&
        css`
            border-color: ${borderColor};
        `};
`;

export const TableActions = styled.div`
    padding: ${({ actionsPadding }) => actionsPadding || '0.8rem'};
`;

export const TableStyled = styled(Table)`
    ${({ removeHeader }) =>
        removeHeader &&
        css`
            .ant-table-thead {
                display: none;
            }

            .ant-table-row-level-0 {
                display: table-row;
                position: relative;
            }

            .nombreOrderPending {
                padding-left: 8px;
            }

            .ant-table-cell .tagCanalVenta {
                position: relative;
                /* display: flex !important; */
                left: -120%;
            }

            .ant-table-row-expand-icon {
                color: #0497db;
            }

            .tablaInterna .ant-table-row-level-0 {
                display: table-row;

                & {
                    .ant-table-cell {
                        /* padding-left: 10px !important; */
                        display: table-cell;
                        /* width: 100%; */
                    }
                }
            }

            .ant-table-cell .rowOcuparEspacio {
                width: 500px !important;
            }

            .ant-checkbox .ant-checkbox-inner {
                border: 3px solid #2d3d76;
                border-radius: 3px;
            }

            .ant-table-expanded-row {
                .ant-table {
                    &:first-of-type {
                        position: relative;
                        left: -25px;
                        /* transform: translateX(-50%); */
                        border: 1px solid #9ebcc0;
                    }
                }

                .ant-table-scroll-horizontal {
                    width: 100%;
                }

                .ant-table-thead {
                    display: table-header-group;
                    border: 2px solid red !important;
                }
            }
        `}
    .ant-table-thead > tr > th {
        color: #036896;
        background-color: #f5f5f5;
        font-weight: 500;
        font-size: 0.8rem;
        cursor: pointer;
        /* cursor: grabbing;
        cursor: grab; */
    }

    /* .ant-table-thead:active {
        cursor: grabbing;
    } */

    letter-spacing: 0.3px;
    font-weight: 500;
    font-size: 0.8rem !important;

    a {
        color: inherit;
    }

    ${({ checkBlue }) =>
        checkBlue &&
        css`
            .ant-checkbox {
                margin-left: 12px;
            }

            .ant-checkbox .ant-checkbox-inner {
                border: 3px solid #2d3d76;
                border-radius: 3px;
            }

            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #2d3d76 !important;
            }

            .ant-checkbox .ant-checkbox-inner:focus,
            .ant-checkbox .ant-checkbox-inner:hover {
                border: 3px solid #2d3d76;
                border-radius: 3px;
            }

            .ant-table-thead > tr > th {
                color: #036896 !important;
                background-color: #f5f5f5 !important;
                text-transform: capitalize;
                font-weight: 500;
                font-size: 0.8rem;
            }
        `}
    b {
        font-weight: 700 !important;
        letter-spacing: 0.5px;
    }

    strong {
        font-weight: 800 !important;
        letter-spacing: 0.5px;
    }

    thead {
        th {
            padding: 4px 16px;
            color: #536d8f;
            font-size: 0.75rem;
            font-weight: 500;
            background: RGBA(220, 229, 236, 0.46);
            border-top: 1px solid rgb(229, 231, 235);
            border-bottom: 1px solid rgb(229, 231, 235);
        }
    }

    > tbody > td{
         
            font-size: 0.85rem;
            padding: 4px 16px;
            border-bottom: 1px solid rgba(229, 231, 235, 0.8);
            color: #1f2937;
        
    }

    .ant-table-row {
        &.disabled-table-row {
            color: #bacbd9;
     

            .tag__normal {
                color: #bacbd9;
            }

            .ant-tag {
                color: #bacbd9;
            }
        }
    }

    .ant-table-cell-row-hover {
        background-color: #DCE5EC !important;
        transition: unset !important;
        animation: none !important;
    }

    .ant-pagination {
        /* Se quitará si todos los paginadores deben tener el mismo estilo */
        ${({ paginationStyle }) =>
            paginationStyle &&
            css`
                border: 1px solid #dce5ec;
                border-radius: 6px;
                width: fit-content;
                padding: 4px;
                margin: 1rem auto;

                .ant-pagination-prev,
                .ant-pagination-next {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    width: 35px !important;
                    height: 32px !important;

                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 35px !important;
                        height: 32px !important;

                        &:disabled {
                            opacity: 0.6;
                        }
                    }
                }

                .ant-pagination-next,
                .ant-pagination-prev {
                    color: #0497db;

                    svg {
                        fill: #0497db;
                    }
                }

                .ant-pagination-disabled {
                    svg {
                        fill: #e4e8ec;
                    }
                }

                .ant-pagination-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    color: #536d8f;
                    width: 32px;
                    height: 32px;
                    background: #f27127;

                    button {
                        position: absolute;

                        color: #1d71b5;
                        width: 35px;
                        height: 32px;

                        &:disabled {
                            background-color: #e4e8ec;
                            color: #9ebcc0 !important;
                        }
                    }

                    .first-page {
                        left: -71px;
                        border-right: 0px;
                    }

                    .last-page {
                        right: -70px;
                        border-left: 0px;
                    }
                }

                .ant-pagination-jump-prev,
                .ant-pagination-jump-next {
                    height: 32px;
                    width: 32px;

                    .ant-pagination-item-link {
                        width: 100%;
                        height: 100%;

                        .ant-pagination-item-container {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;

                            span {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }

                .ant-pagination-item-active {
                    background: #f27127;
                    border-radius: 3px;
                    color: white;
                    border: none;

                    a {
                        color: white;
                    }
                }
            `}
    }


    .ant-table-cell {

        &:before {
            display: none;
        }
    }

    .paused-order-row {
        .ant-table-cell:nth-child(1) {
            border-left: 5px solid #e79b09 !important;
        }
    }

    .incidence-order-row {
        .ant-table-cell:nth-child(1) {
            border-left: 5px solid #e33512 !important;
        }
    }

    .tablePendingArrives {
        display: none !important;
    }

    ${({ removeHeaderBackground }) =>
        removeHeaderBackground &&
        css`
            thead,
            th {
                border-top-color: transparent !important;
                background: transparent !important;
                border-bottom: 1px solid #e4e8ec !important;
            }
        `}

    ${({ whiteHeader }) =>
        whiteHeader &&
        css`
            .ant-table-thead > tr > th {
                color: #036896 !important;
                background-color: white !important;
                text-transform: capitalize;
                font-weight: 400;
                font-size: 0.8rem;
            }

            .ant-table-selection-column {
                position: relative;
            }

            .ant-table-selection {
                top: -20px;
            }
        `}
    ${({ removeTr }) =>
        removeTr &&
        css`
            .ant-table-thead tr:first-child {
                display: none !important;
            }

            .ant-table-thead tr th:first-child {
                position: relative;
                left: 32px !important;
                margin-left: 50px !important !;
            }
        `}

    ${({ styleV2 }) =>
        styleV2 &&
        css`
            .ant-table-filter-column {
                width: 100%;
                display: flex !important;
                justify-content: center !important;
            }

            .ant-table-column-title {
                flex: 0;
                white-space: nowrap;
            }

            .ant-table-thead > tr {
                padding: 1rem !important;
            }

            .ant-table-thead > tr > th {
                text-align: center;
                color: #536d8f;
                background-color: #fff;
                font-weight: 400;
                font-size: 0.8rem;
                margin: 20px;
                height: 3rem;
            }

            .ant-table-column-sorter {
                /* display: none; */
            }

            /* .ant-table-column-sorters {
                min-width: 10rem;
            } */

            tbody {
                td {
                    background-color: #fff;
                    font-size: 0.8rem;
                    text-align: center;
                    padding: 4px 16px;
                    border-bottom: 1px solid rgba(229, 231, 235, 0.8);
                    color: #142c4a;
                    font-weight: 400;
                }
            }
        `}

    ${({ displayNone }) =>
        displayNone &&
        css`
            .ant-table {
                display: none !important;
            }

            .ant-pagination {
                display: none !important;
            }
        `}

        ${({ newHeader }) =>
            newHeader &&
            css`
            thead,
            th {
                font-weight: 400 !important;
                color: #536d8f !important;
                border-top-color: transparent !important;
                background: transparent !important;
                border-bottom: 1px solid #e4e8ec !important;
            }
            svg {
                color: #536d8f !important;
            }
            .ant-table-column-sorters {
                display: flex !important;
                justify-content: left !important;
            }
         

            .ant-table-column-title {
                flex: 0 0 !important;
                white-space: nowrap !important;
s            }
            .ant-table-filter-column {
                width: 100% !important;
                display: flex !important;
                justify-content: center !important;
            }
        `}

        ${(newTableCell) =>
            newTableCell &&
            css`
                .ant-table-cell {
                    // padding: 0px 0px 8px 0px !important;
                    font-weight: 400 !important;
                }
                .ant-table-row-expand {
                    color: red !important;
                }
                .ant-table-expanded-row td {
                    // border-left: 6px solid white;
                }
                // .ant-table-row {
                //     background-color: red !important;
                // }
            `}
            
            .ant-pagination {
                padding: 4px;
                /* Se quitará si todos los paginadores deben tener el mismo estilo */
                ${({ paginationStyle }) =>
                    paginationStyle &&
                    css`
                        .ant-pagination-prev,
                        .ant-pagination-next {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            width: 35px !important;
                            height: 32px !important;
                            button {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #1d71b5;
                                width: 35px !important;
                                height: 32px !important;
                            }
                        }
                        .ant-pagination-prev {
                            button {
                                border-right: 0px !important;
                            }
                        }
                        .ant-pagination-item {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            color: #536d8f;
                            border-right: 0px !important;
                            width: 32px;
                            height: 32px;
                            button {
                                position: absolute;
                                border: 1px solid #037fb9;
                                color: #1d71b5;
                                width: 35px;
                                height: 32px;
                                &:disabled {
                                    background-color: #e4e8ec;
                                    border-color: rgba(0, 0, 0, 0.25);
                                    color: #9ebcc0 !important;
                                }
                            }
                            .first-page {
                                left: -71px;
                                border-right: 0px;
                            }
                            .last-page {
                                right: -70px;
                                border-left: 0px;
                            }
                            .first-page-exceptions {
                                left: -71px;
                                border-right: 0px;
                            }
                            .last-page-exceptions {
                                right: -70px;
                                border-left: 0px;
                            }
                        }
                        .ant-pagination-jump-prev,
                        .ant-pagination-jump-next {
                            height: 32px;
                            width: 32px;
                            .ant-pagination-item-link {
                                width: 100%;
                                height: 100%;
                                .ant-pagination-item-container {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    height: 100%;
                                    span {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    `}
            }
    }
`;
