import { ordersApi } from 'redux/features/Orders';
import { fulfillmentsApi } from 'redux/features/Fulfillments';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import DrawerWithButton from 'components/DrawerWithButton';
import Flex from 'components/Utils/Flex';
import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toggleDrawer } from 'redux/features/Utils';
import IncidenceForm from './IncidenceForm/index';
import ProblemOrders from './ProblemOrders';
import { TitleHeader } from './styled';
import { getIncidenceTypesByFulfillments, getIncidenceTypesByOrders } from 'utils';
import { StateProvider, useStateValue } from './StateContext';

const CreateIncidenceDrawer = ({
    isMenuItem = false,
    ids,
    orders,
    fulfillments,
    onCloseDrawer,
    viewPage,
}) => {
    const dispatch = useDispatch();
    const {
        selectedIds,
        idsWithIncidencesReady,
        selectedData,
        data,
        statusIncidence,
        type,
        tooltipTitle,
        setSelectedIds,
        setIdsWithIncidencesReady,
        setSelectedData,
        setData,
        setStatusIncidence,
        setType,
        setTooltipTitle,
        setIncidencesTypes,
    } = useStateValue();

    useEffect(() => {
        if (!selectedData?.length || statusIncidence === 'reset') {
            setStatusIncidence(selectedData.length <= 1 ? 'only' : 'none');
            return;
        }

        if (type === 'order') {
            const { data: res, tooltip: tooltipMessage } = getIncidenceTypesByOrders(
                selectedData,
                viewPage
            );

            setIncidencesTypes(res);
            setTooltipTitle(tooltipMessage);
        } else {
            const { data: res, tooltip: tooltipMessage } = getIncidenceTypesByFulfillments(
                selectedData,
                viewPage
            );

            setIncidencesTypes(res);
            setTooltipTitle(tooltipMessage);
        }
    }, [selectedData, viewPage, type, statusIncidence]);

    useEffect(() => {
        if (!selectedIds.length) return;

        if (orders?.length) {
            const res = orders.filter((order) => selectedIds.includes(order._id));

            setSelectedData(res);
        } else if (fulfillments?.length) {
            const res = fulfillments.filter((fulfillment) => selectedIds.includes(fulfillment._id));

            setSelectedData(res);
        }
    }, [selectedIds, orders, fulfillments]);

    useEffect(() => {
        if (!ids?.length || (!orders?.length && !fulfillments?.length)) return;

        if (orders?.length) {
            const res = orders.filter((order) => ids.includes(order._id));

            setData(res);
            setType('order');
        } else {
            const res = fulfillments.filter((fulfillment) => ids.includes(fulfillment._id));

            setData(res);
            setType('fulfillment');
        }

        setStatusIncidence(ids?.length === 1 ? 'only' : 'none');
        setSelectedIds(ids);
    }, [ids, orders, fulfillments]);

    const closeDrawer = () => {
        setSelectedIds(ids);
        dispatch(toggleDrawer('manual-incidence-order-drawer'));
        onCloseDrawer?.({ idsWithIncidences: idsWithIncidencesReady });

        setTimeout(() => {
            setStatusIncidence('reset');

            dispatch(fulfillmentsApi.util.invalidateTags(['fulfillmentsToDelivery']));
            dispatch(fulfillmentsApi.util.invalidateTags(['fulfillmentsToPreparation']));
            dispatch(ordersApi.util.invalidateTags(['Orders', 'Order']));
        }, 1000);
    };

    const disabledButtonDrawer = useMemo(() => {
        return orders?.find((order) => {
            const findId =
                selectedIds?.includes(order._id) ?? selectedIds?.includes(order.order_id);
            if (!findId) return false;

            return (
                ['cancelled', 'anulated', 'archived'].includes(order?.order_status) ||
                ['cancelled', 'closed', 'archived'].includes(order?.fulfillment_status) ||
                ['cancelled', 'closed', 'archived'].includes(order?.status)
            );
        });
    }, [orders, selectedIds]);

    const cleanData = (valuesIds) => {
        const res = data.filter((item) => !valuesIds.includes(item._id));
        const otherIds = ids.filter(
            (id) => !valuesIds.includes(id) && !idsWithIncidencesReady.includes(id)
        );

        setIdsWithIncidencesReady([...idsWithIncidencesReady, ...valuesIds]);

        if (otherIds.length) {
            setData(res);
            setStatusIncidence('other');
            setSelectedIds(otherIds);

            return;
        }

        closeDrawer();
    };

    return (
        <DrawerWithButton
            forceRender
            drawerProps={{
                title: (
                    <Flex alignItems="center" justifyContent="space-between">
                        <TitleHeader>Crear incidencia en pedido(s)</TitleHeader>
                        <button type="button" onClick={() => closeDrawer()}>
                            <CloseIcon
                                style={{ color: '#2D3D76', width: '14px', height: '14px' }}
                            />
                        </button>
                    </Flex>
                ),
                name: 'manual-incidence-order-drawer',
                width: '457px',
                dontShowFooter: true,
                forceRender: true,
                maskClosable: false,
            }}
            buttonProps={{
                buttonText: `${
                    selectedIds?.length > 1
                        ? `Agregar incidencias (${selectedIds.length})`
                        : 'Agregar incidencia'
                }`,
                isMenuItem: isMenuItem,
                closeDrawer: closeDrawer,
                disabled: disabledButtonDrawer || !!tooltipTitle,
            }}
            titleTooltip={
                tooltipTitle ||
                (disabledButtonDrawer
                    ? 'No es posible agregar una incidencia a un pedido cancelado'
                    : undefined)
            }
        >
            <div className="manual-incidence-drawer">
                <div className="mb-5 p-1">
                    <ProblemOrders />
                </div>

                {!!selectedIds.length && ['only', 'ready'].includes(statusIncidence) && (
                    <IncidenceForm cleanData={cleanData} />
                )}
            </div>
        </DrawerWithButton>
    );
};

CreateIncidenceDrawer.propTypes = {
    fulfillments: PropTypes.array,
    isMenuItem: PropTypes.bool,
    ids: PropTypes.array,
    orders: PropTypes.array,
    onCloseDrawer: PropTypes.func,
    viewPage: PropTypes.string,
};

const CreateManualIncidenceOrderDrawer = (props) => (
    <StateProvider>
        <CreateIncidenceDrawer {...props} />
    </StateProvider>
);

export default CreateManualIncidenceOrderDrawer;
