import React, { Suspense, useEffect } from 'react';
import Container from 'components/Grid/Container';
import PageHeader from 'components/Layout/PageHeader';
import Flex from 'components/Utils/Flex';

import { useGetUserPreferencesQuery } from 'redux/features/Users';
import { setOrdersV3Filters } from 'redux/features/Utils';
import { clearFilters } from 'redux/features/Orders/orderV3TabFilters';
import store from '../../redux';
import useAllSalesChannel from 'hooks/useAllSalesChannel';
import useAllCouriers from 'hooks/useAllCouriers';
import { useDispatch } from 'react-redux';
import { setValueOptionByField } from 'redux/features/Orders/orderV3TabFilters';
import { deliveryMethodOptions, fulfillmentStatusOptions } from './components/Filters/constants';
import { promisedDateRanges } from 'data/promised_date_ranges';
import useAllShops from '../../hooks/useAllShops';
import {
    useLazyGetAllFulfillmentsAllIdsQuery,
    useLazyGetAllFulfillmentsQuery,
} from 'redux/features/Fulfillments';
import { useGetValueFeatureFlag } from 'app-config/useGetValueFeatureFlag';

const OrdersMainDisplay = React.lazy(() => import('pages/OrdersV3/components/OrdersMainDisplay'));

const OrdersV3Page = () => {
    const availabilityCashOnDelivery = useGetValueFeatureFlag('orders', 'cash_on_delivery');
    const [getAllFulfillments, { data, isLoading, isFetching }] = useLazyGetAllFulfillmentsQuery({
        refetchOnMountOrArgChange: true,
    });

    const [getFulfillmentsAllIds, { data: dataIds }] = useLazyGetAllFulfillmentsAllIdsQuery({
        refetchOnMountOrArgChange: true,
    });
    const {
        data: userPreferences,
        isLoading: userLoading,
        isFetching: userFetching,
    } = useGetUserPreferencesQuery();

    const [salesChannelsLoading, salesChannels] = useAllSalesChannel();
    const [couriersLoading, couriers] = useAllCouriers();
    const [locationsLoading, locations] = useAllShops();

    const loading =
        isLoading ||
        isFetching ||
        salesChannelsLoading ||
        locationsLoading ||
        couriersLoading ||
        userLoading ||
        userFetching;

    const dispatch = useDispatch();

    const handleRefetch = (forceRefetch) => {
        const utils = store.getState().utils;
        const filters = utils.ordersV3Filters;

        getAllFulfillments(filters, !forceRefetch);
        getFulfillmentsAllIds(filters, !forceRefetch);
    };

    useEffect(() => {
        return () => {
            dispatch(clearFilters());
            dispatch(setOrdersV3Filters({}));
        };
    }, []);

    useEffect(() => {
        if (loading) return;

        const origins = ['mercadolibre', 'dafiti', 'fcom', 'ripley', 'paris', 'walmart'];
        const sales_channels_with_origins = Array.from(salesChannels ?? [])
            .filter((sc) => ['Multivende'].includes(sc?.platform))
            .reduce((acc, ele) => {
                origins.forEach((origin) => {
                    acc.push({
                        label: `${ele?.alias ?? ''} - ${origin}`,
                        value: `${ele._id}::${origin}`,
                    });
                });
                return acc;
            }, []);

        const sales_channels = [
            ...Array.from(salesChannels ?? []).map((sc) => ({
                label: sc?.alias ?? sc?.integration?.api_name,
                value: sc?._id,
            })),
            ...sales_channels_with_origins,
        ].sort((a, b) => String(a.label).localeCompare(String(b.label)));

        const destination_stores = Array.from(locations ?? [])
            .filter((location) => location?.is_enabled && location?.location_type !== 'virtual')
            .map((location) => ({
                label: location?.name,
                value: location?._id,
            }))
            .sort((a, b) => String(a.label).localeCompare(String(b.label)));

        dispatch(
            setValueOptionByField({
                status: fulfillmentStatusOptions,
                sales_channel: sales_channels,
                delivery_method: deliveryMethodOptions,
                courier: couriers.map((courier) => ({
                    label: courier?.public_name ?? courier?.courier_name ?? '',
                    value: courier?._id,
                })),
                promised_date_range: promisedDateRanges,
                ['locations.destination']: destination_stores,
                'custom.cash_on_delivery': [
                    {
                        label: 'Verdadero',
                        value: true,
                    },
                    {
                        label: 'Falso',
                        value: false,
                    },
                ],
            })
        );
    }, [loading]);

    const forceRefetchWithButton = (event) => {
        const key = event?.key;
        if (key === '.') {
            handleRefetch(true);
        }
    };

    useEffect(() => {
        document.addEventListener('keypress', forceRefetchWithButton);
        return () => {
            document.removeEventListener('keypress', forceRefetchWithButton, true);
        };
    }, []);

    return (
        <Container extraTitle="Pedidos" backgroundColor="#fff">
            <PageHeader title="Pedidos" withMarginBottom={false} />
            <Flex direction="column">
                {/* <OrdersDataMenu /> */}
                <Suspense fallback={<></>}>
                    <OrdersMainDisplay
                        data={data}
                        dataIds={dataIds?.ids ?? []}
                        loading={loading}
                        userPreferences={
                            userPreferences
                                ? {
                                      ...(userPreferences ?? {}),
                                      ...(userPreferences?.preferences?.orders_v3?.tabs?.length && {
                                          preferences: {
                                              ...userPreferences.preferences,
                                              orders_v3: {
                                                  ...userPreferences.preferences.orders_v3,
                                                  tabs: userPreferences.preferences.orders_v3.tabs.filter(
                                                      (tab) =>
                                                          tab.value === 'cash_on_delivery'
                                                              ? availabilityCashOnDelivery
                                                              : true
                                                  ),
                                              },
                                          },
                                      }),
                                  }
                                : null
                        }
                        userFetching={userFetching}
                        getOrders={() => {
                            getAllFulfillments();
                            getFulfillmentsAllIds();
                        }}
                        refetch={handleRefetch}
                    />
                </Suspense>
            </Flex>
        </Container>
    );
};

export default OrdersV3Page;
