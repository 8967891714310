export const renderOrderSacStatusLabel = (status) => {
    if (status === 'awaiting_solution') return 'Esperando solución';
    if (status === 'contacting_client') return 'Contactando cliente';
    if (status === 'in_process_of_solution') return 'En proceso de solución';
    if (status === 'incident_resolved') return 'Incidencia solventada';
    if (status === 'in_normal_process') return 'En proceso normal';
    if (status === 'in_courier_shipping') return 'En despacho courier';
    if (status === 'finished_normal') return 'Finalizado normal';
    if (status === 'waiting_for_solution') return 'Esperando solución';
    if (status === 'client_contacted') return 'Cliente contactado';
    if (status === 'finished_with_incidence') return 'Finalizado con incidencia';
};
