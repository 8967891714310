import { createApi } from '@reduxjs/toolkit/query/react';
import getFromEnv from 'helpers/getFromEnv';
import qs from 'qs';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';
import { ERROR_CODE_DIC } from './errorCodeDic';

const ERROR_CODES = {
    MANIFEST_DTE_NOT_FOUND: 'Alguno de los documentos no tiene DTE generado',
    GENERIC_NOT_AUTHORIZED: 'No autorizado',
    INPUT_MISSING_VALUE:
        'Campos faltantes, es necesario fulfillment_id o order_id o logistic_id o order_ids',
    INPUT_NOT_VALID_IS_NECESSARY_ONLY_VALUE:
        'La entrada no válida es necesaria solo el valor fulfillment_id o order_id o logistic_id o order_ids',
    FULFILLMENT_NOT_FOUND: 'Fulfillment no encontrado',
    ORDER_NOT_FOUND: 'Pedido no encontrado',
    ORDER_NOT_FOUND_DELIVERY_METHOD_SHIPPING_IN_FULFILLMENT:
        'Uno de los pedidos no tiene fulfillment de tipo despacho',
    ORDER_NOT_FOUND_DELIVERY_METHOD_SHIPPING_IN_LOGISTICS:
        'Uno de los pedidos no tiene fulfillment de tipo despacho',
    ORDER_NOT_FOUND_DELIVERY_METHOD_SHIPPING: 'Un pedido no es de tipo despacho',
    FULFILLMENT_NOT_IS_SHIPPING: 'El fulfillment no es de tipo despacho',
    LOGISTIC_NOT_IS_SHIPPING: 'El fulfillment no es de tipo despacho',
    FULFILLMENT_HAVE_LABEL_COURIER:
        'El fulfillment tiene etiqueta de courier creada por lo que no es posible hacer el cambio',
    FULFILLMENT_STATUS_NOT_VALID:
        'El estado del fulfillment no es valido, los estados validos son: "Preparación pendiente, En proceso, Listo para entrega, SAC"',
    FULFILLMENT_NOT_HAVE_INCIDENT_AVAILABLE:
        'El fulfillment no tiene incidencia valida para el cambio de courier',
    COURIER_IS_ACTUALLY: 'El courier y servicio ya es el actual',
    DEFAULT: 'Ocurrió un error',
};

// lambda URLs
const batchDeliverApis = {
    dev: 'https://vs75d7ghe4b7pp4ria63oe265i0qkjyo.lambda-url.us-east-1.on.aws/',
    demo: 'https://vyjbfxe4cbrsy6hoaq4r7x3bqa0mdaee.lambda-url.us-east-1.on.aws/',
    prod: 'https://ncunyb4nmyhca7lomvk7nhhp4y0kzibo.lambda-url.us-east-1.on.aws/',
};

// lambda URLs
const changeCourierApis = {
    dev: '',
    demo: '',
    prod: '',
};

export const deliveriesApi = createApi({
    reducerPath: 'deliveries',
    baseQuery,
    tagTypes: ['tickets', 'manifests', 'batch_deliveries', 'bulk_batch_delivers'],
    endpoints: (builder) => ({
        getMassiveTickets: builder.mutation({
            method: 'post',
            query: (data) => {
                return {
                    url: `/fulfillments/getAllTicketsInOnePdf`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Buscando etiquetas de operador logístico...</b>,
                    success: <b>Etiquetas de operador logístico encontradas</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['tickets'],
        }),
        getOnlyLinksTickets: builder.mutation({
            method: 'post',
            query: (pdfLink) => {
                return {
                    method: 'post',
                    url: `/fulfillments/getpdflink`,
                    body: pdfLink,
                };
            },
            invalidatesTags: ['tickets'],
        }),
        getSeparateTickets: builder.mutation({
            method: 'post',
            query: (data) => {
                return {
                    method: 'post',
                    url: `/fulfillments/separateTicketsEnviame`,
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Buscando etiquetas de operador logístico...</b>,
                    success: <b>Etiquetas de operador logístico encontradas</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['tickets'],
        }),
        getAllManifestsByFilters: builder.query({
            keepUnusedDataFor: 10,
            queryFn: (args, api, extraOptions, fetchWithBQ) => {
                const actuallyFilters = api.getState().utils.deliveriesFilters;
                let filters = args ? args : actuallyFilters;
                const clearedFilters = Object.entries(filters).reduce(
                    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                    {}
                );

                const { searchTerm = '', ...otherFilters } = clearedFilters;
                const querystring = qs.stringify({
                    search_term: searchTerm,
                    ...otherFilters,
                });

                return fetchWithBQ({
                    url: `deliveries/batch-delivers?${querystring}`,
                    method: 'get',
                });
            },
            providesTags: (result) => {
                return result
                    ? [
                          ...result.docs.map((row) => ({
                              type: 'manifests',
                              id: row._id,
                          })),
                          {
                              type: 'manifests',
                              id: 'LIST',
                          },
                      ]
                    : [{ type: 'manifests', id: 'LIST' }];
            },
        }),
        getFulfillmentsByManifestId: builder.query({
            query: (id) => {
                return {
                    url: `/deliveries/batch-delivers/${id}/packages-and-fulfillments`,
                    method: 'get',
                };
            },
        }),
        getBatchDeliveryById: builder.query({
            query: (id) => {
                return {
                    url: `/deliveries/batch-delivers/${id}`,
                    method: 'get',
                };
            },
            providesTags: ['batch_deliveries'],
            // this request return an url object with json link. we need to get this file after the request and return the value of the json
            transformResponse: (response) => {
                const { url } = response;

                return fetch(url).then((res) => res.json());
            },
        }),
        getBulkBatchDelivers: builder.query({
            query: (id) => {
                return {
                    url: `deliveries/bulk/batch-delivers/${id}`,
                    method: 'get',
                };
            },
            onQueryStarted: (__, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: 'Obteniendo manifiestos...',
                    success: 'Manifiestos obtenidos con éxito',
                    error: (response) => (
                        <b>
                            {ERROR_CODE_DIC[response?.error?.data?.errorCode] || `Ocurrió un error`}
                        </b>
                    ),
                });
            },
            providesTags: ['bulk_batch_delivers'],
        }),
        createBulkBatchDeliver: builder.mutation({
            query: (data) => {
                return {
                    url: `deliveries/bulk/batch-delivers`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Creando lista...</b>,
                    success: <b>Lista creada con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        removeBatchDeliveryOfList: builder.mutation({
            query: ({ batch_deliver_id, multi_batch_id }) => {
                return {
                    url: `deliveries/bulk/batch-delivers/${multi_batch_id}/batch-deliver`,
                    method: 'delete',
                    body: {
                        batch_deliver_id,
                    },
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Quitando manifiesto de la lista</b>,
                    success: <b>Se ha quitado de la lista el manifiesto</b>,
                    error: (response) => (
                        <b>
                            {ERROR_CODE_DIC[response?.error?.data?.errorCode] || `Ocurrió un error`}
                        </b>
                    ),
                });
            },
            invalidatesTags: (result, error) => (error ? [] : ['bulk_batch_delivers']),
        }),
        updateBatchDeliverStatus: builder.mutation({
            query: (data) => {
                const { id, package_code, fulfillment_id } = data;

                return {
                    url: `/deliveries/batch-delivers/${id}/packages/re-processed`,
                    method: 'post',
                    body: {
                        package_code,
                        fulfillment_id,
                    },
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Re-procesando bulto</b>,
                    success: <b>Bulto re-procesado</b>,
                    error: (response) => (
                        <b>
                            {ERROR_CODE_DIC[response?.error?.data?.errorCode] || `Ocurrió un error`}
                        </b>
                    ),
                });
            },
            invalidatesTags: ['manifests', 'batch_deliveries'],
        }),
        confirmBatchDeliver: builder.mutation({
            query: (data) => {
                return {
                    url: `/deliveries/batch-delivers/${data.batch_id}/confirm-delivers`,
                    method: 'post',
                    body: {
                        batch_id: data.batch_id,
                    },
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Revalidando entrega</b>,
                    success: (response) => {
                        return (
                            <b>
                                La entrega del manifiesto #{response?.data?.manifest_id} fue
                                entregada exitosamente
                            </b>
                        );
                    },
                    error: (response) => (
                        <b>
                            {ERROR_CODE_DIC[response?.error?.data?.errorCode] || `Ocurrió un error`}
                        </b>
                    ),
                });
            },
            invalidatesTags: ['batch_deliveries', 'manifests'],
        }),
        bulkConfirmBatchDeliver: builder.mutation({
            query: (data) => {
                return {
                    url: `/deliveries/bulk/batch-delivers/confirm-delivers`,
                    method: 'post',
                    body: {
                        batch_delivers: data.batch_delivers,
                    },
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Confirmando entregas...</b>,
                    success: () => {
                        return (
                            <b>
                                Se ha confirmado la entrega de #{data?.batch_delivers?.length ?? 0}{' '}
                                manifiestos
                            </b>
                        );
                    },
                    error: (response) => (
                        <b>
                            {ERROR_CODE_DIC[response?.error?.data?.errorCode] || `Ocurrió un error`}
                        </b>
                    ),
                });
            },
            invalidatesTags: ['batch_deliveries', 'manifests'],
        }),
        preProcessBatchDelivers: builder.mutation({
            query: (data) => {
                return {
                    url:
                        batchDeliverApis[getFromEnv('REACT_APP_STAGE')] ||
                        '/deliveries/batch-delivers/pre-process',
                    timeout: 290000,
                    method: 'post',
                    body: { fulfillment_ids: data.fulfillmentIds },
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Procesando {data.isTwoStep ? 'pre-entrega' : 'entrega'}</b>,
                    success: (
                        <b>{data.isTwoStep ? 'Pre-entrega' : 'Entrega'} procesada con éxito</b>
                    ),
                    error: (response) => {
                        if (
                            response?.error?.data?.errorCode ===
                            'NOT_HAVE_ALL_FULFILLMENT_CONSOLIDATION_THE_ORDER'
                        ) {
                            return (
                                <b>
                                    El pedido {data.remoteOrderId} tiene entrega consolidada,
                                    verifique que todos los fulfillments sean seleccionados para la
                                    entrega
                                </b>
                            );
                        }

                        if (response?.error?.data?.errorCode === 'MANIFEST_DTE_NOT_FOUND')
                            return <b>{response?.error?.data?.message}</b>;

                        return (
                            <b>
                                Ocurrió un error en la {data.isTwoStep ? 'pre-entrega' : 'entrega'}.
                            </b>
                        );
                    },
                });
            },
            invalidatesTags: ['manifests'],
        }),
        getBatchDeliversByManifestId: builder.query({
            method: 'get',
            queryFn: (manifest_id, api, extraOptions, fetchWithBQ) => {
                const querystring = qs.stringify({
                    filters: [
                        {
                            field: 'manifest_id',
                            value: manifest_id,
                            condition: 'is',
                            andOr: '',
                        },
                    ],
                });

                return fetchWithBQ(`/deliveries/batch-delivers?${querystring}`);
            },
        }),
        deleteManifest: builder.mutation({
            query: (id) => {
                return {
                    url: `/deliveries/batch-delivers/${id}`,
                    method: 'delete',
                    body: {},
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Cancelando manifiesto</b>,
                    success: <b>Manifiesto cancelado con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['manifests', 'batch_deliveries'],
        }),
        getManifestPdfs: builder.mutation({
            query: (data) => {
                return {
                    url: `/fulfillments/getAllManifestsInOnePdf`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Obteniendo manifiestos...</b>,
                    success: <b>Manifiestos obtenidos con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        generateShippingNotes: builder.mutation({
            query: (data) => {
                return {
                    url: 'deliveries/documents/generate-shipping-note',
                    method: 'post',
                    body: data,
                };
            },
            invalidatesTags: ['manifests', 'batch_deliveries'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Obteniendo guías de despacho...</b>,
                    success: <b>Guías de despacho obtenidos con éxito.</b>,
                    error: (response) => (
                        <b>
                            {ERROR_CODES[response?.error?.data?.errorCode ?? 'DEFAULT'] ||
                                'Ocurrió un error.'}
                        </b>
                    ),
                });
            },
        }),
        mergeShippingNotes: builder.mutation({
            query: (data) => {
                return {
                    url: 'deliveries/documents/merge-shipping-note',
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Generado documentos de guías de despacho...</b>,
                    success: <b>Guías de despacho obtenidos con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        validatePinDelivery: builder.mutation({
            query: (data) => {
                return {
                    url: `deliveries/deliveries/${data?.fulfillment_id}/validate-delivery-pin`,
                    method: 'post',
                    body: {
                        delivery_pin: data?.delivery_pin,
                    },
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Validando entrega...</b>,
                    success: <b>PIN exitoso</b>,
                    error: <b> El PIN introducido es inválido, por favor verifique.</b>,
                });
            },
        }),
        changeCourier: builder.mutation({
            query: (data) => {
                const currentUrl =
                    changeCourierApis[getFromEnv('REACT_APP_STAGE')] ||
                    'deliveries/fulfillments/couriers/change';
                return {
                    url: currentUrl,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Cambiando Courier/Tipo de servicio...</b>,
                    success: <b>Cambio de Courier/Tipo de servicio exitoso</b>,
                    error: (response) => (
                        <b>
                            {ERROR_CODES[response?.error?.data?.errorCode ?? 'DEFAULT'] ||
                                'Ocurrió un error.'}
                        </b>
                    ),
                });
            },
            // invalidatesTags: ['Orders'],
        }),
    }),
});

export const {
    useGetMassiveTicketsMutation,
    useGetOnlyLinksTicketsMutation,
    useGetSeparateTicketsMutation,
    useLazyGetAllManifestsByFiltersQuery,
    useGetFulfillmentsByManifestIdQuery,
    useUpdateBatchDeliverStatusMutation,
    useConfirmBatchDeliverMutation,
    useGetBatchDeliveryByIdQuery,
    useGetBulkBatchDeliversQuery,
    useRemoveBatchDeliveryOfListMutation,
    usePreProcessBatchDeliversMutation,
    useCreateBulkBatchDeliverMutation,
    useBulkConfirmBatchDeliverMutation,
    useLazyGetBatchDeliversByManifestIdQuery,
    useDeleteManifestMutation,
    useGetManifestPdfsMutation,
    useGenerateShippingNotesMutation,
    useMergeShippingNotesMutation,
    useValidatePinDeliveryMutation,
    useChangeCourierMutation,
} = deliveriesApi;
