/* eslint-disable no-unused-vars */
import { Divider } from 'antd';
import { ReactComponent as PreparationBoxIcon } from 'assets/PreparationBoxIcon.svg';
import Container from 'components/Grid/Container';
import IF from 'components/Utils/IF';
import useFulfillmentsScanMode from 'hooks/useFulfillmentsScanMode';
import useGetPreparationPreference from 'hooks/useGetPreparationPreference';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { useGetFulfillmentListQuery, useGetOneFulfillmentQuery } from 'redux/features/Fulfillments';
import { useGetPackageTypesQuery } from 'redux/features/PackageTypes';
import { useGetAllUsersQuery } from 'redux/features/Users';
import styled from 'styled-components';
import { PreparationHeaderV2 } from './components/Header/PreparationHeaderV2';
import PickingConfirmationModal from './components/PickingConfirmationModal';
import { Alert } from './styles';
// LuPackageOpen

// const PreparationPageHeader = React.lazy(() => import('./components/Header/PreparationHeader'));

import useGetCurrentPermissions from 'hooks/useGetCurrentPermissions';
import NotFoundPage from 'pages/NotFound';
import { LoadingLayout } from 'pages/NotFound/loadingLayout';
import { ModalAssignOperator } from './components/ModalAssignOperator';
import dayjs from 'dayjs';

const PreparationArticles = React.lazy(() => import('./components/PreparationArticles'));
const PreparationScannedArticles = React.lazy(() =>
    import('./components/PreparationScannedArticles')
);
const PreparationPackages = React.lazy(() => import('./components/PreparationPackages'));
const PreparationScanForm = React.lazy(() => import('./components/PreparationScanForm'));
const PreparationComment = React.lazy(() => import('./components/PreparationComment'));

const PageStyled = styled.div`
    background-color: white;
    padding: 20px;
    width: 100%;
    height: auto;
`;

const PageHalfLayout = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const PageLayout = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin-top: 2rem;
    gap: 1.5rem;
`;

const PreparationPageV2 = () => {
    const [showPickingConfirmation, setShowPickingConfirmation] = useState(false);
    const [, , preparationPreferences] = useGetPreparationPreference();
    const [, isAdmin] = useGetCurrentPermissions();
    const history = useHistory();
    const { fulfillment_id, fulfillment_list_id } = useParams();
    const [scanMode, setScanMode] = useFulfillmentsScanMode();
    const [openModalSetOperator, setOpenModalSetOperator] = useState(false);
    const fulfillmentList = useGetFulfillmentListQuery(fulfillment_list_id, {
        skip: !fulfillment_list_id,
    });

    const { data, isLoading, isFetching, isError, refetch } = useGetOneFulfillmentQuery(
        fulfillment_id,
        {
            refetchOnFocus: true,
        }
    );

    useEffect(() => {
        refetch();
    }, [fulfillment_id]);

    const userDifferentLocation = (data?.userDifferentLocation && !isAdmin) || false;

    const blockPreparationForFlow =
        data?.order?.custom?.unlock_fulfillment_after_so_integration_rpro ?? false;

    const fulfillmentCannotBePrepared = useMemo(() => {
        const orderWithoutDteCannotPrepared =
            data?.sales_channel?.order_without_dte_cannot_prepared ?? false;
        if (orderWithoutDteCannotPrepared) {
            const hasDte = data?.order?.documents?.some((document) =>
                ['DTE_TICKET', 'DTE_INVOICE'].includes(document?.type)
            );
            if (!hasDte) {
                toast.error(
                    'El pedido/fulfillment no puede ser preparado debido a facturación electrónica pendiente'
                );
                return true;
            }
            return !hasDte;
        }
    }, [data]);

    const blockPreparation =
        userDifferentLocation || blockPreparationForFlow || fulfillmentCannotBePrepared;

    useEffect(() => {
        if (
            preparationPreferences?.preparations_preferences?.picking_confirmation &&
            ['open', 'in_progress'].includes(data?.status) &&
            !userDifferentLocation
        ) {
            setShowPickingConfirmation(true);
        }
    }, [preparationPreferences, data, userDifferentLocation]);

    useEffect(() => {
        const validationRunning = data?.order?.flows?.find(
            (f) => f.status === 'RUNNING' && f.flow_type === 'INTEGRATIONS'
        );

        console.log('validationRunning', validationRunning, data?.order?.flows);
        if (!validationRunning) return;

        toast.error('Acción deshabilitada por movimientos de integración pendiente');
        history.push(`/orders/${data?.order?._id}`);
    }, [data]);

    useGetPackageTypesQuery();
    useGetAllUsersQuery();

    const loading = isLoading || !data || isFetching;

    const showLoading = (!data?._id && loading) || (data?._id !== fulfillment_id && loading);

    const allItemsInZero = Array.from(data?.line_items ?? []).every((item) => item?.quantity === 0);

    const notHaveOperator = !isLoading && !isError && !data?.assigned_operators?.length;

    useEffect(() => {
        const required_operator =
            preparationPreferences?.preparations_preferences?.required_operator;
        if (notHaveOperator && required_operator) setOpenModalSetOperator(true);
    }, [notHaveOperator]);

    const enable_comments = useMemo(() => {
        const enable_comment_in_preparation =
            preparationPreferences?.preparations_preferences?.enable_comment_in_preparation;

        return enable_comment_in_preparation;
    }, [preparationPreferences]);

    return (
        <>
            {isLoading ? (
                <LoadingLayout />
            ) : data === undefined ? (
                <NotFoundPage />
            ) : (
                <Container extraTitle="Preparación pedido">
                    <ModalAssignOperator
                        onCancel={() => {}}
                        onOk={() => {
                            setOpenModalSetOperator(false);
                            refetch();
                        }}
                        dataFulfillment={data}
                        isOpen={openModalSetOperator}
                    />
                    <PickingConfirmationModal
                        fulfillment={data}
                        showPickingConfirmation={showPickingConfirmation}
                        blockPreparation={blockPreparation}
                        fulfillment_list_id={fulfillment_list_id}
                    />

                    <PageStyled>
                        <Suspense fallback={<></>}>
                            <PreparationHeaderV2
                                fulfillment={data}
                                loading={showLoading}
                                userDifferentLocation={blockPreparation}
                                fulfillmentList={fulfillmentList?.data ?? {}}
                            />
                        </Suspense>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {!userDifferentLocation &&
                                data?.packages?.length === 0 &&
                                !['sac', 'cancelled'].includes(data?.status) && (
                                    <Alert backgroundColor="#D5E6DE">
                                        <PreparationBoxIcon />
                                        <b>Crea tu primer bulto para comenzar el proceso</b>
                                    </Alert>
                                )}

                            {data?.status === 'sac' && (
                                <Alert backgroundColor="#D6F3FB">
                                    <PreparationBoxIcon />
                                    <b>
                                        No es posible preparar este pedido, debe solucionar estado
                                        SAC
                                    </b>
                                </Alert>
                            )}

                            {data?.status === 'cancelled' && (
                                <Alert backgroundColor="#D6F3FB">
                                    <PreparationBoxIcon />
                                    <b>Preparación cancelada, no es posible preparar este pedido</b>
                                </Alert>
                            )}

                            {allItemsInZero && data?.status !== 'cancelled' && (
                                <Alert backgroundColor="#D6F3FB">
                                    <PreparationBoxIcon />
                                    <b>Este pedido no tiene productos para preparar</b>
                                </Alert>
                            )}
                        </div>

                        <IF condition={blockPreparationForFlow}>
                            <b>Esta preparación aún no termina su flujo de integración</b>
                        </IF>

                        <IF condition={userDifferentLocation}>
                            <b>Esta preparación no corresponde a tu bodega</b>
                        </IF>

                        <IF condition={!blockPreparation}>
                            <PageLayout>
                                <PageHalfLayout>
                                    <Suspense fallback={<></>}>
                                        <PreparationArticles
                                            enableChangeTicket={
                                                preparationPreferences?.preparations_preferences
                                                    ?.enable_change_ticket
                                            }
                                            fulfillment={data}
                                            loading={showLoading}
                                        />
                                    </Suspense>

                                    <Suspense fallback={<></>}>
                                        <PreparationScanForm
                                            fulfillment={data}
                                            scanMode={scanMode}
                                            setScanMode={setScanMode}
                                            loading={showLoading}
                                        />
                                    </Suspense>

                                    <Suspense fallback={<></>}>
                                        <PreparationScannedArticles
                                            fulfillment={data}
                                            loading={showLoading}
                                        />
                                    </Suspense>
                                </PageHalfLayout>

                                <PageHalfLayout>
                                    <Suspense fallback={<></>}>
                                        <Suspense fallback={<></>}>
                                            <PreparationPackages
                                                fulfillment={data}
                                                loading={showLoading}
                                            />
                                        </Suspense>
                                        {enable_comments && (
                                            <PreparationComment
                                                fulfillment={data}
                                                preparationPreferences={
                                                    preparationPreferences?.preparations_preferences
                                                }
                                            />
                                        )}
                                    </Suspense>
                                </PageHalfLayout>
                            </PageLayout>
                        </IF>
                    </PageStyled>
                </Container>
            )}
        </>
    );
};

export default PreparationPageV2;
