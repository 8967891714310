import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Flex from 'components/Utils/Flex';
import styled, { css } from 'styled-components';

const StyledButton = styled.button`
    &:hover {
        cursor: pointer;
    }
    svg {
        fill: rgb(4, 151, 219);
    }
`;

const PaginationContainer = styled.div`
    border: 1px solid #dce5ec;
    border-radius: 4px;
    padding: 6px;
    display: flex;
    align-items: center;
    gap: 8px;
    &:disabled {
        opacity: 0.6;
    }
`;

const Page = styled.button`
    ${({ $isCurrent }) =>
        $isCurrent &&
        css`
            background-color: rgb(242, 113, 39);
            color: white;
        `}
    padding: 2px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 3px;
    min-height: 30px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const Dots = styled.div`
    display: flex;
    align-items: center;
    gap: 3px;
`;

const Dot = styled.div`
    background: #536d8f;
    height: 3px;
    width: 3px;
    border-radius: 50%;
    cursor: default;
    user-select: none;
    opacity: 0.4;
`;

const PaginationText = styled.div`
    text-align: center;
    small {
        opacity: 0.6;
    }
`;

const handler = ({
    loading,
    hasPrevPage,
    hasNextPage,
    page,
    handlePreviousPage,
    handleNextPage,
    handleResetPage,
    totalPages = 0,
    totalDocs = 0,
}) => {
    return (
        <Flex alignItems="center" justifyContent="center" gap="1rem" direction="column">
            <Flex alignItems="center" justifyContent="center" gap="1rem" direction="row">
                <PaginationContainer>
                    <StyledButton
                        onClick={handlePreviousPage}
                        disabled={!hasPrevPage || loading}
                        title="Ir a la página anterior"
                    >
                        <FaChevronLeft />
                    </StyledButton>

                    {page > 2 && (
                        <Flex>
                            <Page
                                onClick={handleResetPage}
                                title="Volver a la página 1"
                                disabled={loading}
                            >
                                <b>1</b>
                            </Page>
                            <Dots>
                                <Dot />
                                <Dot />
                                <Dot />
                            </Dots>
                        </Flex>
                    )}

                    <Page $isCurrent>
                        <b>{page}</b>
                    </Page>

                    <StyledButton
                        onClick={handleNextPage}
                        disabled={!hasNextPage || loading}
                        title="Ir a la siguiente página"
                    >
                        <FaChevronRight />
                    </StyledButton>
                </PaginationContainer>
            </Flex>

            {totalPages > 0 && (
                <PaginationText>
                    <p>
                        Página {page} de {totalPages}
                    </p>
                    <small>Se encontraron {totalDocs} registros</small>
                </PaginationText>
            )}
        </Flex>
    );
};

export default handler;
