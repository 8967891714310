import { Table } from 'antd';
import { Button } from '@andescommercecloud/beflow-design-system';
import styled, { css } from 'styled-components';

export const ProductTableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    // padding-bottom: 30px;
    // padding-right: 15px;
    border-bottom: 1px solid #9ebcc0;
    background-color: ${(props) => props.$tableColor};
    position: relative
`;

export const DisabledBox = styled.div`
    background-color: #F5F5F5;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
    opacity: 0.5;
    cursor: not-allowed;
`

export const TableStyled = styled(Table)`
    ${(props) =>
        props?.$dontShowCheckbox
            ? `
                .ant-checkbox-wrapper {
                    display: none;
                }

                .ant-table-thead > tr > th:nth-child(2) > div {
                   margin-left: -35px !important;
                }
    `
            : ''}

    .ant-table-content {
        background-color: ${(props) => props.$tableColor};
    }
    .ant-table-cell {
        border-top-left-radius: 0px !important;
        font-weight: 400;
        color: #536d8f !important;
        font-size: 12px;
    }

    .ant-table-thead {
        background-color: rgba(220, 229, 236, 0.2);

        tr > th {
            border-top: 1px solid #e4e8ec;
        }
    }

    .ant-table-thead > tr > th {
        font-size: 13px;
        color: rgba(45, 61, 118, 1) !important;
        font-weight: 600 !important;
    }

    .ant-table-thead > tr > th:last-child {
        text-align: right;
    }

    tbody > tr > td:last-child {
        text-align: right;
    }

    .ant-table-cell {
        border-bottom: none !important;
    }

    tbody > tr:last-child > td {
        border-bottom: 2px solid #e4e8ec !important;
    }

    .ant-table-thead > tr > th:nth-child(2), // Primera columna
    tbody > tr > td:nth-child(2) {
        min-width: 350px !important;
        max-width: 350px !important;
    }

    .ant-table-thead > tr > th:nth-child(3), // Segunda columna
    tbody > tr > td:nth-child(3) {
        min-width: 100px !important;
        max-width: 100px !important;
    }

    .ant-table-thead > tr > th:nth-child(4), // Tercera columna
    tbody > tr > td:nth-child(4) {
        min-width: 100px !important;
        max-width: 100px !important;
        text-align: center;
    }

    .ant-table-thead > tr > th:nth-child(5), // Cuarta columna
    tbody > tr > td:nth-child(5) {
        min-width: 100px !important;
        max-width: 100px !important;
    }

    &.order-page__product-table-pre-devolution-quantity
        .ant-table-container
        table
        > thead
        > tr:first-child
        th:nth-child(2)
        > div {
        margin-left: -40px;
    }

    ${({ name }) =>
        name === 'product_table_removed_quantity' || name === 'product_table_refunded_quantity'
            ? `
        tbody > tr > td {
            text-decoration: line-through;
        }

        input {
            background: red !important;
        }
    `
            : ''}

    ${({ name }) =>
        name === 'product_table_pre_devolution_quantity'
            ? `
        tbody > tr > td {
            font-size: #7998A7;
            opacity: 0.6;
        }


        input {
            background: red !important;
        }
    `
            : ''}
    th {
        background: none !important;
        color: #2d3d76 !important;
        font-size: 16px;
        &::before {
            display: none;
        }
    }
`;

export const ProductTableHeader = styled.div`
    color: #b00303;
    font-weight: 400 !important;
    font-size: 14px;
    display: flex;
    .icon_table_header {
        margin-right: 8px;
        margin-top: 2.6px;
    }
    .order-page__product-table-button-return-icon path {
        stroke: #b00303;
    }
    .order-page__product-table-button-return-icon {
        width: 16px;
        height: 16px;
    }
`;

export const ProductTableDetails = styled.div`
    display: grid;
    grid-template-columns: 1.1fr 0.4fr 0.6fr 0.1fr 0.4fr 0.6fr;
    // grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 1rem;

    padding: 20px 14px;
    // border-top: 1px solid #e4e8ec;
    span {
        font-size: 12px;
    }

    .order-page__product-table-icon-status {
        margin-left: 8px;
    }

    .order_page_product_table_promised_date_label {
        color: #536d8f;
    }
    .order_page_product_table_promised_date_value {
        color: #2d3d76;
        font-weight: 600;
    }

    .order_page_product_table_title {
        grid-area: 1 / 1 / 2 / span 5;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .order-page__product-table-name {
            color: #037fb9;
            font-size: 18px;
            font-weight: 600;
        }
        .order-page__product-table-name-consolidation {
            color: #2d3d76;
            font-size: 24px;
            font-weight: 700;
        }

        .order-page__product-table-id {
            color: #0497db;
            font-size: 16px;
            margin-top: 2px;
        }
        .title-cancelled-table,
        .title-incidence-table {
            color: #037fb9;
            display: flex;
            direction: row;
            gap: 4px;
            span {
                margin-top: 4px;
            }
            .icon-cancelled {
                margin-top: 4px;
                font-size: 18px;
                color: #536d8f;
            }

            .icon-incidence {
                margin-top: 4px;
                font-size: 16px;
                color: #b00303;
            }
        }

        .order-page__product-table-button-info {
            margin-top: 4px;
            height: 21px;
            width: 65px;
            font-size: 12px;

            .order-page__product-table-button-info-icon {
                font-size: 18px;
            }
        }
    }

    .order_page_product_table_term_limit {
        // grid-area: 2 / 1 / 3 / 2;
        grid-area: 2 / 1 / 3 / span 6;

        font-size: 13px;
        display: flex;
        direction: row;
        gap: 4px;
        /* align-items: center; */
        color: #536d8f;

        .order-page__product-table-tag-term-limit-styles {
            margin-left: 4px;
            background-color: #f5f5f5;
            border: 1px solid #2d3d76;
            color: #536d8f;
            font-size: 10px;

            .order-page__product-table-tag-dot-styles {
                margin-right: 4px;
            }
        }
    }
    .order_page_product_table_status {
        // grid-area: 2 / 2 / 3 / 4;
        font-size: 12px;

        .order-page__product-table-tag-status-styles {
            margin-left: 4px;
            background-color: #f5f5f5;
            border: 1px solid #2d3d76;
            color: #142c4a;
            font-size: 10px;

            .header-table-icon-status {
                margin-left: 8px;
            }
            .icon-sac {
                color: #b00303;
            }
            .icon-header-delivered-to-courier {
                color: #2d3d76;
                margin-left: 8px;
                font-weight: 500;
                font-size: 12px;
            }
            .header-table-icon-cancelled {
                margin-left: 8px;
                font-size: 14px;
            }
            .fulfilled-icon {
                font-size: 10px;
                width: 14px;
            }
            .header-table-icon-delivered {
                margin-left: 7px;
                color: #2d3d76 !important;
                width: 13px;
                height: 13px;
            }
            .in-progress-icon {
                margin-left: 8px;
                color: #2d3d76 !important;
                font-size: 12px;
            }
            .partially_fulfilled-icon {
                font-size: 10px;
                width: 14px;
            }
        }
    }

    .order_page_product_table_location {
        grid-area: 3 / 1 / 4 / 6;
        font-size: 12px;
        .order_page_product_table_location_label {
            color: #536d8f;
        }
        .order_page_product_table_value_location {
            color: #2d3d76;
            font-weight: 400;
        }
        .order_page_product_table_location_value {
            color: #2d3d76;
            font-weight: 400;
        }
    }

    .order_page_product_table_delivery_method {
        grid-area: 4 / 1 / 5 / 3;
        font-size: 12px;
        .order_page_product_table_delivery_method_label {
            color: #536d8f;
        }
        .order_page_product_table_delivery_method_value {
            .order-page__product-table-tag-delivery_method-styles {
                margin-left: 4px;
                background-color: #f5f5f5;
                border: 1px solid #2d3d76;
                color: #2d3d76;
                font-size: 10px;

                .order-page__product-table-icon-delivery_method {
                    margin-left: 8px;
                    font-size: 16px;
                }
            }
        }
    }
    .order_page_product_table_agency {
        grid-area: 5 / 1 / 5 / 3 !important;
        margin-top: -1.2rem;
    }
    .order_page_product_table_actions {
        grid-area: 1 / 6 / 2 / 7;
        justify-self: end;
        font-size: 14px;
        .order-page__product-table-icon-actions {
            font-size: 20px;
        }
    }
    .order_page_product_table_courier {
        grid-area: 3 / 6 / 4 / 7;
        font-size: 12px;
        // text-align: center;
        .order_page_product_table_courier_label {
            color: #536d8f;
        }
        .order_page_product_table_courier_value {
            color: #2d3d76;
            font-weight: 600;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    .order_page_product_table_shipping_service {
        grid-area: 4 / 6 / 5 / 7;
        font-size: 12px;
        // text-align: center;
        .order_page_product_table_shipping_service_label {
            color: #536d8f;
        }
        .order_page_product_table_shipping_service_value {
            color: #2d3d76;
            font-weight: 600;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
`;

export const ActionsButtonProductTable = styled(Button)``;

export const InfoButtonProductTable = styled(Button)`
    border-radius: 20px;
    display: flex;
    align-items: center;
    background: none;
    border: 1px solid #2d3d76;
    color: #2d3d76;
    font-size: 12px;
    gap: 2px;

    &:hover,
    &:focus {
        background-color: #f27127 !important;
        border: 1px solid #e79b09 !important;
        color: white;
    }

    ${(props) =>
        props?.is_active &&
        css`
            background-color: #f27127 !important;
            border: 1px solid #e79b09 !important;
            color: white;

            &:hover,
            &:focus {
                background-color: #f27127;
                border: 1px solid #e79b09;
                color: white;
            }
        `}
`;

export const IconContainer = styled.div`
    background-color: ${({ theme }) => theme?.buttons?.colors?.secondary?.fourth};
    border-radius: 50%;
    height: 45px;
    width: 45px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.6rem;
    position: relative;

    & svg {
        fill: ${({ theme }) => theme?.buttons?.color?.primary?.fourth};
        opacity: 0.3;
    }

    & ~ p {
        color: ${({ theme }) => theme?.buttons?.colors?.primary?.second};
        font-size: 10px;
        font-weight: 700;
        text-align: center;
    }
`;

export const NameTableProduct = styled.div`
    display: flex;
    direction: row;
    gap: 5px;
`;

export const TitlesTablesProduct = styled.div`
    display: grid;
    // grid-template-columns: 1.53fr 0.5fr 0.2fr 0.2fr;
    grid-template-columns: 1.6fr 0.5fr 0.2fr 0.2fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;

    justify-content: flex-end;
    position: relative;
    top: 40px;
    z-index: 10;
    color: #536d8f;
    .title_total_tables_product {
        padding-left: 15px;
    }
`;

export const FulfillmentSummaryWrapper = styled.div`


    ${({ name }) =>
        name !== 'order_total_summary' &&
        `
        display: flex;
        direction: row;
        justify-content: space-between;
        padding: 10px 5px 10px 0px;
        width: 97%;
        align-self: center;
  `}

    ${({ name }) =>
        name !== 'order_total_summary'
            ? `
        .summary_fulfillment_title {
            color: #7998a7;
            font-size: 16px;
            align-self: center;
        }
        `
            : `
        .summary_fulfillment_title {
            color: rgb(45, 61, 118);
            font-size: 16px;
            font-weight: 600;
        }
            `}
`;

export const FulfillmentSummaryPrices = styled.div`
    ${({ name }) =>
        name === 'order_total_summary' &&
        `
            margin-top: 10px;
            padding-left: 1rem;
        `}

    display: flex;
    flex-direction: column;
    gap: 6px;

    ${({ orderIsCancelled }) =>
        orderIsCancelled &&
        `
    text-decoration: line-through;
    opacity: 0.6;
  `}

    ${({ name }) =>
        name !== 'order_total_summary'
            ? `
            .summary_fulfillment_subtitule {
                font-size: 12px;
                color: #536d8f;
                display: flex;
                justify-content: space-between;
            }

            .summary_fulfillment_price {
                font-size: 12px;
                color: #142c4a;
                margin-left: 10px;
            }

            .summary_fulfillment_shipping_removed {
                opacity:.8;
                text-decoration: line-through;
            }

            margin-right: -6px;  
            gap:2px;
        `
            : `
            .summary_fulfillment_subtitule {
                font-size: 14px;
                color: #2D3D76;
                display: flex;
                justify-content: space-between;
            }

            .summary_fulfillment_price {
                font-size: 14px;
                color: #142C4A;
                margin-right: 9rem;
            }
            `}

    .total_price {
        font-weight: 600;
    }
`;

export const OrderSummaryWrapper = styled.div`
    // padding: 20px;
    border: 1px solid #037fb9;
    padding-right: 15px;
`;

export const TotalContainerColumns = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;

    .value_with_discount {
        text-decoration: line-through;
        // opacity: 0.6;
        font-size: 11px;
    }
`;

export const SkuCell = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const TextPointer = styled.span`
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
    color: #2d3d76;
    font-weight: 500;
`;
