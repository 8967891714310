import { Form, Divider, Button, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { ReactComponent as InfoIcon } from 'assets/info-cleaner-grey.svg';
import { Label } from '../styled';
import { ProblemOrderDetailsDrawer } from '../../ShowDetailsDrawer/ProblemOrderDetailsDrawer';
import FormStyled from 'components/Utils/Form';
import { useStateValue } from '../StateContext';

const ProblemOrders = () => {
    const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);
    const [form] = Form.useForm();
    const { selectedIds, setSelectedIds, data, statusIncidence, setStatusIncidence } =
        useStateValue();

    const onCheckboxGroupChange = (list) => {
        setSelectedIds(list);
        setStatusIncidence('none');
    };

    useEffect(() => {
        form.setFieldValue('selectedIds', selectedIds);
        setIsCheckboxDisabled(false);
    }, [selectedIds, form]);

    return (
        <>
            <Label fontWeight={600} className="mb-4">
                Pedidos seleccionados con incidencia ({selectedIds.length})
            </Label>

            {statusIncidence === 'other' && (
                <div className="flex p-2" style={{ display: 'flex', backgroundColor: '#D6F3FB' }}>
                    <InfoIcon
                        className="mr-1"
                        style={{ width: '2.5em', height: '2em', border: 2 }}
                    />
                    <label
                        htmlFor="unreported-orders"
                        style={{
                            fontSize: 12,
                            marginTop: 5,
                            marginBottom: 5,
                            fontWeight: 4000,
                            lineHeight: 1.5,
                            color: '#255035',
                        }}
                    >
                        Tienes <b>({selectedIds.length})</b> pedido(s) en la lista sin reportar
                        incidencia, para continuar con el proceso debes seleccionar el pedido.{' '}
                        <br /> Si quieres finalizar el proceso solo debes hacer click en la{' '}
                        <b>“X”</b>.
                    </label>
                </div>
            )}
            <FormStyled
                form={form}
                layout="vertical"
                name="manual-incidence-in-problem-orders-form"
            >
                <Checkbox.Group
                    onChange={onCheckboxGroupChange}
                    name="selectedIds"
                    value={selectedIds}
                    className="w-full"
                    disabled={isCheckboxDisabled}
                >
                    {data?.map((data) => (
                        <ProblemOrderDetailsDrawer
                            itemLength={data.line_items?.length}
                            nameOrder={data.name}
                            totalPrice={
                                data.total_price ??
                                data.order?.total_price ??
                                data.order_id?.total_price
                            }
                            key={data._id}
                            id={data._id}
                        />
                    ))}
                </Checkbox.Group>
            </FormStyled>

            {!selectedIds.length && (
                <>
                    <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                        <Divider />
                    </div>
                    <div className="flex">
                        <InfoIcon
                            style={{
                                color: '#037FB9',
                                width: '2em',
                                height: '2em',
                                marginLeft: 20,
                            }}
                        />
                        <span
                            className="ml-4"
                            style={{ fontSize: 14, marginTop: 5, fontWeight: 450 }}
                        >
                            Para continuar con la creación de la incidencia debes seleccionar al
                            menos un pedido. <br /> En caso de seleccionar más de uno deben
                            compartir la incidencia.
                        </span>
                    </div>
                </>
            )}

            {['none', 'other'].includes(statusIncidence) && !!selectedIds.length && (
                <>
                    <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                        <Divider />
                    </div>
                    <div className="px-2 py-2 flex flex-col justify-center items-center">
                        <Label fontWeight={500} textAlign="center">
                            ¿Los pedidos seleccionados comparten <br /> la misma incidencia?
                        </Label>
                        <div className="flex justify-center mt-4">
                            <Button
                                onClick={() => {
                                    setIsCheckboxDisabled(true);
                                    setStatusIncidence('ready');
                                }}
                                style={{ border: '1px solid #1E2B5F', fontWeight: '600' }}
                            >
                                <div className="flex items-center">
                                    <span className="mr-3">Si</span>
                                    <CheckOutlined style={{ color: 'green' }} />
                                </div>
                            </Button>
                            <Button
                                onClick={() => {
                                    setStatusIncidence('pending');
                                    setIsCheckboxDisabled(false);
                                    setSelectedIds([]);
                                }}
                                className="ml-9"
                                style={{ border: '1px solid #1E2B5F', fontWeight: '600' }}
                            >
                                <div className="flex items-center">
                                    <span className="mr-3">No</span>
                                    <CloseOutlined style={{ color: 'red' }} />
                                </div>
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

ProblemOrders.propTypes = {
    selectedIds: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    statusIncidence: PropTypes.string.isRequired,
    setStatusIncidence: PropTypes.func.isRequired,
    setSelectedIds: PropTypes.bool.isRequired,
};

export default ProblemOrders;
