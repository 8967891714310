import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

import {
    FlowCircle,
    FlowCircleContainer,
    FlowCircleIconWrapper,
    FlowDate,
    FlowStatus,
    FlowStepContainer,
    FlowStepLine,
    FlowWrapper,
    FulfillmentFlowWrapper,
    ScrollButton,
    ScrollButtonsContainer,
} from './styles';

import {
    consolidationDefaultFlow,
    customFlowSteps,
    pickupDefaultFlow,
    preparationcdTransferPickupStoreDefaultFlow,
    shippingDefaultFlow,
} from './flows';

const sortFlow = (flow) => {
    return flow?.sort((a, b) => {
        if (a.date && b.date) {
            return new Date(a.date) - new Date(b.date);
        }

        if (!a.date && !b.date) {
            return 0;
        }

        if (!a.date) {
            return 1;
        }

        if (!b.date) {
            return -1;
        }
    });
};

const flowSelector = ({ order, logistic, fulfillment }) => {
    const isFulfillment = fulfillment?.status;
    const isLogistic = !isFulfillment && logistic?._id;
    const isOrder = !isFulfillment && !isLogistic && order?._id;

    let deliveryMethod = null;
    let history = [];
    let flowSelected = null;
    let isConsolidationFlowAndIsNotConsolidationFulfillment = false;

    let orderHistoryBackup =
        order?.status_history?.filter((step) => {
            if (isFulfillment && ['sac'].includes(step?.status)) {
                return false;
            }

            if (
                ['fulfillment_status', 'remove_items'].includes(step?.status_type) &&
                !['unfulfilled', 'in_progress'].includes(step?.status)
            ) {
                return true;
            }

            if (step?.status_type === 'status' && ['delivered'].includes(step?.status)) {
                return true;
            }

            return false;
        }) ?? [];

    // remove duplicated history
    orderHistoryBackup = orderHistoryBackup.reduce((acc, step) => {
        const isDuplicated = acc.find(
            (el) =>
                el?.status === step?.status &&
                el?.status_type === step?.status_type &&
                el?.updatedAt === step?.updatedAt
        );

        if (!isDuplicated) {
            acc.push(step);
        }

        return acc;
    }, []);

    if (isFulfillment) {
        history =
            fulfillment?.status_history.filter(
                (el) =>
                    el && !(el?.status_type === 'fulfillment_status' && el?.status === 'delivered')
            ) ?? [];

        if (fulfillment?.preparationcd_transfer_pickupstore?.pickup_location) {
            deliveryMethod = 'preparationcd_transfer_pickupstore';
        } else if (
            fulfillment?.consolidation?.consolidation_location_id &&
            !fulfillment?.consolidation?.is_consolidation_fulfillment
        ) {
            deliveryMethod = 'consolidation';
            isConsolidationFlowAndIsNotConsolidationFulfillment = true;
            history = fulfillment.status_history?.filter(
                (el) => el.status_type === 'fulfillment_status' || el.status === 'delivered'
            );
        } else {
            deliveryMethod = fulfillment?.delivery_method;
        }

        // console.log('HISTORY_IS_FULFILLMENT');
    }

    // console.log('history', history);

    if (isLogistic) {
        if (logistic?.preparationcd_transfer_pickupstore?.pickup_location) {
            deliveryMethod = 'preparationcd_transfer_pickupstore';
        } else if (
            logistic?.consolidation?.consolidation_location_id &&
            !logistic?.consolidation?.is_consolidation_fulfillment
        ) {
            deliveryMethod = 'consolidation';
            isConsolidationFlowAndIsNotConsolidationFulfillment = true;
        } else {
            deliveryMethod = logistic?.delivery_method ?? 'shipping'; // shipping en caso que venga vacío;
        }

        history =
            order?.status_history.filter((el) =>
                ['order_status', 'status', 'remove_items'].includes(el?.status_type)
            ) ?? [];
        // console.log('HISTORY_IS_LOGISTIC');
    }

    if (isOrder) {
        deliveryMethod = order?.delivery_method;

        history =
            order?.status_history.filter((el) =>
                ['order_status', 'status', 'remove_items'].includes(el?.status_type)
            ) ?? [];

        // console.log('HISTORY_IS_ORDER');
    }

    // console.log('history_TEST', history);

    // console.log('deliveryMethod', deliveryMethod);

    // console.log('HISTORY_1', history);
    console.log({ deliveryMethod });
    if (deliveryMethod === 'pickup') {
        flowSelected = pickupDefaultFlow();
        console.log({ flowSelected });
    }

    if (deliveryMethod === 'shipping') {
        flowSelected = shippingDefaultFlow();
    }

    if (deliveryMethod === 'preparationcd_transfer_pickupstore') {
        flowSelected = preparationcdTransferPickupStoreDefaultFlow();
    }

    if (deliveryMethod === 'consolidation') {
        flowSelected = consolidationDefaultFlow();
        // console.log('INIT FLOW SELECTED', flowSelected);
    }

    console.log('TEST_0=====================');
    console.log({
        isFulfillment,
        flowSelected,
    });

    // console.log(`DELIVERY METHOD F ${fulfillment?._id}`, deliveryMethod);

    // console.log('HISTORY', history);

    const hasReadyForDeliverHistory = history?.find((step) => step.status === 'ready_for_deliver');

    const hasDeliveredToCourierHistory = history?.find(
        (step) => step.status === 'delivered_to_courier'
    );

    // const hasDeliveredToCourierConsolidation = history?.find(
    //     (step, i) =>
    //         step.status === 'delivered_to_courier' &&
    //         ['delivered'].includes(history?.[i + 1]?.status)
    // );

    // console.log('history', history);
    // console.log('hasDeliveredToCourierConsolidation___1', hasDeliveredToCourierConsolidation);

    const hasDeliveredHistory = history?.find((step) => step.status === 'delivered');

    const hasCancelationRequest = history?.filter(
        (step) => step.status === 'cancelation_requested'
    );

    // const hasCancelledHistory = history?.find((step) => step.status === 'cancelled');

    const historyCustomRemoveItems = history?.filter(
        (step) =>
            step?.status_type === 'remove_items' && ['removed', 'refunded'].includes(step?.status)
    );

    const historyCustomFulfillmentStatus = history?.filter(
        (step) => ['fulfillment_status', 'order_status', 'status'].includes(step?.status_type)
        // && ['cancelled'].includes(step?.status)
    );

    // console.log('historyCustomFulfillmentStatus', historyCustomFulfillmentStatus);

    // NO es preparationcd_transfer_pickupstore
    const notPTP = deliveryMethod !== 'preparationcd_transfer_pickupstore';

    const notConsolidation = deliveryMethod !== 'consolidation';

    if (flowSelected) {
        flowSelected = flowSelected.map((step) => {
            if (step?.status === 'open') {
                step.step_completed = true;
                step.date = order?.createdAt;
            }

            return step;
        });
    }

    if (flowSelected && (isLogistic || isOrder)) {
        // console.log('LOGISTIC OR ORDER', flowSelected);

        flowSelected = flowSelected.map((step) => {
            if (step?.status === 'delivered' && hasDeliveredHistory) {
                step.step_completed = true;
                step.date = hasDeliveredHistory?.updatedAt;
            }

            return step;
        });
    }

    // console.log('TEST_1', flowSelected);

    if (flowSelected && isFulfillment) {
        // console.log('ISFULFILLMENT', history);

        flowSelected = flowSelected.map((step, i) => {
            if (step?.status === 'open') {
                step.step_completed = true;
                step.date = order?.createdAt;
            }

            if (step?.status === 'in_progress') {
                step.step_completed = true;
                step.date = fulfillment?.createdAt;
            }

            if (step?.status === 'in_progress' && step?.updatedAt) {
                step.step_completed = true;
                step.date = step?.updatedAt;
            }

            if (
                step?.status === 'ready_for_deliver' &&
                hasReadyForDeliverHistory &&
                notPTP &&
                notConsolidation
            ) {
                step.step_completed = true;
                step.date = hasReadyForDeliverHistory?.updatedAt;
            }

            if (
                step?.status === 'delivered_to_courier' &&
                hasDeliveredToCourierHistory &&
                notPTP &&
                notConsolidation
            ) {
                step.step_completed = true;
                step.date = hasDeliveredToCourierHistory?.updatedAt;
            }

            if (step?.status === 'delivered' && hasDeliveredHistory && notPTP && notConsolidation) {
                step.step_completed = true;
                step.date = hasDeliveredHistory?.updatedAt;
            }

            // if (step?.status === 'cancelled')

            // if (step?.status === 'cancelled' && hasCancelledHistory) {
            //     step.step_completed = true;
            //     step.date = hasCancelledHistory?.updatedAt;
            // }

            if (deliveryMethod === 'preparationcd_transfer_pickupstore') {
                const hasDeliveredToPickupLocationHistory = history?.find(
                    (step) => step.status === 'delivered_to_pickup_location'
                );

                const hasReadyForDeliverToPickupLocationHistory = history?.find(
                    (step) => step.status === 'ready_for_deliver_to_pickup_location'
                );

                const hasReceivedInPickupLocationHistory = history?.find(
                    (step) => step.status === 'received_in_pickup_location'
                );

                // const hasDeliveredHistory = history.find((step) => step.status === 'delivered');

                if (
                    step?.status === 'ready_for_deliver_to_pickup_location' &&
                    hasReadyForDeliverToPickupLocationHistory
                ) {
                    step.step_completed = true;
                    step.date = hasReadyForDeliverToPickupLocationHistory?.updatedAt;
                }

                if (
                    step?.status === 'delivered_to_pickup_location' &&
                    hasDeliveredToPickupLocationHistory
                ) {
                    step.step_completed = true;
                    step.date = hasDeliveredToPickupLocationHistory?.updatedAt;
                }

                if (
                    step?.status === 'received_in_pickup_location' &&
                    hasReceivedInPickupLocationHistory
                ) {
                    step.step_completed = true;
                    step.date = hasReceivedInPickupLocationHistory?.updatedAt;
                }

                // Listo para entregar === recibido en tienda
                if (step?.status === 'ready_for_deliver' && hasReceivedInPickupLocationHistory) {
                    step.step_completed = true;
                    step.date = hasReceivedInPickupLocationHistory?.updatedAt;
                }

                if (step?.status === 'delivered_to_courier' && hasDeliveredToCourierHistory) {
                    step.step_completed = true;
                    step.date = hasDeliveredToCourierHistory?.updatedAt;
                }

                if (step?.status === 'delivered' && hasDeliveredHistory) {
                    step.step_completed = true;
                    step.date = hasDeliveredHistory?.updatedAt;
                }
            }

            if (
                deliveryMethod === 'consolidation' &&
                isConsolidationFlowAndIsNotConsolidationFulfillment
            ) {
                const hasDeliveredToConsolidationLocationHistory = history?.find(
                    (step) => step.status === 'delivered_to_cd_location'
                );

                const hasReadyForDeliverToConsolidationLocationHistory = history?.find(
                    (step) => step.status === 'ready_for_deliver_to_cd_location'
                );

                const hasReceivedInConsolidationLocationHistory = history?.find(
                    (step) => step.status === 'received_in_cd_location'
                );

                // const hasDeliveredHistory = history.find((step) => step.status === 'delivered');

                if (
                    step?.status === 'ready_for_deliver_to_cd_location' &&
                    hasReadyForDeliverToConsolidationLocationHistory
                ) {
                    step.step_completed = true;
                    step.date = hasReadyForDeliverToConsolidationLocationHistory?.updatedAt;
                }

                if (
                    step?.status === 'delivered_to_cd_location' &&
                    hasDeliveredToConsolidationLocationHistory
                ) {
                    step.step_completed = true;
                    step.date = hasDeliveredToConsolidationLocationHistory?.updatedAt;
                }

                if (
                    step?.status === 'received_in_cd_location' &&
                    hasReceivedInConsolidationLocationHistory
                ) {
                    step.step_completed = true;
                    step.date = hasReceivedInConsolidationLocationHistory?.updatedAt;
                }

                // Listo para entregar === recibido en tienda
                if (
                    step?.status === 'ready_for_deliver' &&
                    hasReceivedInConsolidationLocationHistory
                ) {
                    step.step_completed = true;
                    step.date = hasReceivedInConsolidationLocationHistory?.updatedAt;
                }

                if (
                    ['delivered_to_courier'].includes(step?.status) &&
                    ['delivered'].includes(flowSelected?.[i + 1]?.status)
                ) {
                    // step.step_completed = true;
                    if (hasDeliveredToCourierHistory) {
                        step.date = hasDeliveredToCourierHistory?.updatedAt;
                        step.step_completed = true;
                    }

                    // console.log('hasDeliveredToCourierConsolidation', {
                    //     step,
                    //     // hasDeliveredToCourierConsolidation,
                    //     next: flowSelected?.[i + 1]?.status,
                    // });
                }

                if (step?.status === 'delivered' && hasDeliveredHistory) {
                    step.step_completed = true;
                    step.date = hasDeliveredHistory?.updatedAt;
                }
            }

            return step;
        });
    }

    const historyCustomStatus = [
        ...historyCustomRemoveItems,
        ...historyCustomFulfillmentStatus,
        // ...(!isFulfillment ? orderHistoryBackup : []),
        ...orderHistoryBackup,
    ];

    // console.log('flowSelected', flowSelected);
    // console.log('historyCustomStatus', historyCustomStatus);

    // console.log('TEST_2', [...customFlowSteps, ...flowSelected]);

    // console.log('TEST===========');
    // console.log({
    //     isFulfillment,
    //     customFlowSteps: customFlowSteps(),
    //     flowSelected,
    // });

    if (flowSelected && (historyCustomStatus?.length ?? 0) > 0) {
        const historyCustomStatusXCustomFlowSteps = historyCustomStatus.reduce((acc, step, i) => {
            const customOrRepeatedStep = [...flowSelected, ...customFlowSteps()];

            // console.log('STEPPPP', step);
            // console.log('customOrRepeatedStep', customOrRepeatedStep);

            const customFlowStep = customOrRepeatedStep?.find((customStep) => {
                if (step?.status_type === 'remove_items') {
                    if (step?.status === 'removed') {
                        return customStep?.status === 'items_removed';
                    }

                    if (step?.status === 'refunded') {
                        return customStep?.status === 'items_refunded';
                    }
                }

                if (['fulfillment_status', 'order_status', 'status'].includes(step?.status_type)) {
                    if (!isFulfillment && step.status === 'cancelled') {
                        return customStep?.status === 'cancelled';
                    }

                    if (
                        isFulfillment &&
                        step.status_type === 'fulfillment_status' &&
                        step.status === 'cancelled'
                    ) {
                        return customStep?.status === 'cancelled';
                    }

                    if (
                        isFulfillment &&
                        step.status_type === 'fulfillment_status' &&
                        step.status === 'ready_for_deliver_to_cd_location'
                    ) {
                        return customStep?.status === 'ready_for_deliver_to_cd_location';
                    }

                    if (step.status === 'archived' && order?.disable_movements) {
                        return customStep?.status === 'delivered';
                    }

                    if (
                        isFulfillment &&
                        step.status_type === 'status' &&
                        step.status === 'success'
                    ) {
                        return customStep?.status === 'success';
                    }

                    if (step?.status === 'sac') {
                        return customStep?.status === 'sac';
                    }

                    if (step.status === 'unfulfilled' && i !== 0 && !isFulfillment) {
                        return customStep?.status === 'open';
                    }

                    if (step.status === 'in_progress' && isFulfillment) {
                        return customStep?.status === 'in_progress';
                    }

                    if (
                        step.status === 'success' &&
                        isFulfillment &&
                        deliveryMethod !== 'consolidation' &&
                        deliveryMethod !== 'pickup'
                    ) {
                        return customStep?.status === 'success';
                    }

                    // return false;
                    // return customStep?.status === step?.status;
                }

                if (['status'].includes(step?.status_type)) {
                    if (step?.status === 'sac' && isFulfillment) {
                        return customStep?.status === 'sac';
                    }
                }

                return false;
            });

            // console.log('customFlowStep', customFlowStep);

            // ESTO ESTABA AGREGANDO UN ESTADO INCORRECTO AL FLUJO DE RETIRO, POR ESO SE AGREGA CONDICION SOLO PARA DESPACHO
            if (customFlowStep && fulfillment?.delivery_method === 'shipping') {
                const sumTeenSecondsToDate = dayjs(step?.updatedAt).add(10, 'second').format();
                acc.push({
                    ...customFlowStep,
                    step_completed: true,
                    date: order?.disable_movements ? sumTeenSecondsToDate : step.updatedAt,
                });
            }

            if (customFlowStep && fulfillment?.delivery_method === 'pickup') {
                acc.push({
                    ...customFlowStep,
                    step_completed: true,
                    date: step.updatedAt,
                });
            }

            return acc;
        }, []);

        // console.log('historyCustomStatusXCustomFlowSteps', historyCustomStatusXCustomFlowSteps);

        flowSelected = [...flowSelected, ...historyCustomStatusXCustomFlowSteps];

        // console.log('historyCustomStatusXCustomFlowSteps', historyCustomStatusXCustomFlowSteps);
    }

    // console.log('flowSelected========', flowSelected);

    // remove duplicated steps with same status and date
    flowSelected = Array.from(flowSelected ?? []).reduce((acc, step) => {
        const isDuplicated = acc.find(
            (el) =>
                el?.status === step?.status &&
                el?.status_type === step?.status_type &&
                el?.date === step?.date
        );

        let successInSameTimeThanReceivedInPickupLocation = false;

        // success and received_in_pickup_location at same time
        if (step?.status === 'success') {
            const hasReceivedInPickupLocation = acc.find(
                (el) => el?.status === 'received_in_pickup_location'
            );

            if (
                hasReceivedInPickupLocation &&
                dayjs(step?.updatedAt).isSame(hasReceivedInPickupLocation?.updatedAt)
            ) {
                successInSameTimeThanReceivedInPickupLocation = true;
            }
        }

        if (!isDuplicated && !successInSameTimeThanReceivedInPickupLocation) {
            acc.push(step);
        }

        return acc;
    }, []);

    // En caso que la orden sea con preparacion en cd con transferencia a tienda, reemplazamos los success por
    // ready_for_deliver_to_cd_location para que no se muestre Listo para entregar en el flujo
    flowSelected = Array.from(flowSelected ?? []).map((step) => {
        const hasReadforDeliverToPickupLocationStatus = flowSelected.find(
            (step) => step.status === 'ready_for_deliver_to_pickup_location'
        );

        if (step?.status === 'success' && hasReadforDeliverToPickupLocationStatus) {
            step.status = 'ready_for_deliver_to_pickup_location';
            step.step_name = 'Listo para despachar a tienda';
        }

        return step;
    });

    //sort flowSelected by date, if date is null, put it at the end in the same order
    flowSelected = sortFlow(flowSelected);

    // find is final step index
    const findIsFinalStepIndex = flowSelected?.findIndex((step) => step?.is_final);

    // remove steps with !step_completed before findLastStepCompletedIndex
    flowSelected = flowSelected?.reduce((acc, step, i) => {
        if (i > findIsFinalStepIndex && !step?.step_completed) {
            return acc;
        }

        if (!step?.date && step?.step_completed) {
            return acc;
        }

        if (deliveryMethod !== 'preparationcd_transfer_pickupstore') {
            const hasReadyForDeliverStatus = acc.find((el) => el?.status === 'ready_for_deliver');

            if (hasReadyForDeliverStatus && step?.status === 'success') {
                return acc;
            }
        }

        acc.push(step);

        return acc;
    }, []);

    if (hasCancelationRequest?.length) {
        const findCancelationRequestStep = customFlowSteps().find(
            (step) => step?.status === 'cancelation_requested'
        );

        for (const step of hasCancelationRequest) {
            flowSelected = [
                ...flowSelected,
                {
                    ...findCancelationRequestStep,
                    step_completed: true,
                    date: step?.updatedAt,
                },
            ];
        }
    }

    // remove duplicated cancelled steps after first one
    flowSelected = flowSelected?.reduce((acc, step) => {
        if (step?.status === 'cancelled') {
            const hasCancelledStatus = acc.find((el) => el?.status === 'cancelled');

            if (hasCancelledStatus) {
                return acc;
            }

            acc.push(step);

            return acc;
        }

        acc.push(step);

        return acc;
    }, []);

    // si el paso anterior es cancelled y el siguiente es items_removed, sumar 5 segundos al paso anterior (para fix del bug que se muestra cancelled antes de items_removed)
    flowSelected = flowSelected.map((step, i) => {
        if (
            step?.status === 'cancelled' &&
            step?.step_completed &&
            flowSelected?.[i + 1]?.status === 'items_removed'
        ) {
            // add 5 seconds to date
            step.date = dayjs(step.date).add(5, 'second').format();
        }

        return step;
    });

    const flowHasCancelledCompleted = flowSelected.find(
        (step) => step?.status === 'cancelled' && step?.step_completed
    );

    if (isLogistic && logistic?.is_cancelled && !flowHasCancelledCompleted) {
        const findCancelledStep = customFlowSteps().find((step) => step?.status === 'cancelled');

        flowSelected = [
            ...flowSelected,
            {
                ...findCancelledStep,
                step_completed: true,
                date: logistic?.cancellation_date,
            },
        ].filter((step) => step?.step_completed);
    }

    // hacemos sort de nuevo
    flowSelected = sortFlow(flowSelected);

    return {
        history,
        deliveryMethod,
        flowSelected,
    };
};

const FlowDrawer = ({ order, logistic, fulfillment, refProp }) => {
    const { history, flowSelected } = flowSelector({
        order,
        logistic,
        fulfillment,
    });
    const isFulfillment = fulfillment?.status;
    // console.log(`FLOWSELECTED ${fulfillment?._id}`, flowSelected);

    return (
        <FlowWrapper ref={refProp}>
            {Array.from(flowSelected ?? []).map((step, index) => {
                const isCurrentStep = step?.status === history[index]?.status;
                const isCompletedStep = step?.step_completed;
                const isFinalStep = step?.is_final;

                let historyDate = step?.date;

                const stepDate = historyDate ? dayjs(historyDate).format('DD/MM/YYYY') : '';
                const stepTime = historyDate ? dayjs(historyDate).format('HH:mm:ss') : '';

                if (
                    isFulfillment &&
                    fulfillment.delivery_method === 'pickup' &&
                    step?.status === 'success' &&
                    !fulfillment?.preparationcd_transfer_pickupstore?.status
                ) {
                    return '';
                }

                return (
                    <FlowStepContainer key={index}>
                        <FlowCircleContainer>
                            <FlowCircle
                                isCurrentStep={isCurrentStep}
                                isCompletedStep={isCompletedStep}
                                isFinalStep={isFinalStep}
                                iconName={step?.icon_name}
                            >
                                <FlowCircleIconWrapper>
                                    {step?.icon && step.icon}
                                </FlowCircleIconWrapper>
                            </FlowCircle>
                            <FlowStepLine
                                isCompletedStep={isCompletedStep}
                                beforeStepCompleted={flowSelected[index - 1]?.step_completed}
                                nextStepCompleted={flowSelected[index + 1]?.step_completed}
                                index={index}
                                totalLength={flowSelected?.length ?? 0}
                            />
                        </FlowCircleContainer>
                        <FlowStatus>{step?.step_name}</FlowStatus>
                        <FlowDate>
                            <p>{stepDate}</p>
                            <p>{stepTime}</p>
                        </FlowDate>
                    </FlowStepContainer>
                );
            })}
        </FlowWrapper>
    );
};

const FulfillmentFlow = ({ order, logistic, fulfillment }) => {
    const flowDrawerRef = useRef(null);
    const [showScrollButtons, setShowScrollButtons] = useState(false);
    const [leftScrollButtonEnabled, setLeftScrollButtonEnabled] = useState(false);
    const [rightScrollButtonEnabled, setRightScrollButtonEnabled] = useState(false);

    const handleScroll = (offset) => {
        if (flowDrawerRef.current) {
            flowDrawerRef.current.scrollBy({ left: offset, behavior: 'smooth' });
        }
    };

    const checkScrollPosition = () => {
        const { scrollLeft, scrollWidth, clientWidth } = flowDrawerRef.current;
        const isFullLeft = scrollLeft === 0;
        const isFullRight = scrollLeft === scrollWidth - clientWidth;

        if (isFullLeft && isFullRight) {
            setShowScrollButtons(false);
            setLeftScrollButtonEnabled(false);
            setRightScrollButtonEnabled(false);
        }

        if (!isFullLeft && !isFullRight) {
            setShowScrollButtons(true);
            setLeftScrollButtonEnabled(true);
            setRightScrollButtonEnabled(true);
        }

        if (isFullLeft && !isFullRight) {
            setShowScrollButtons(true);
            setLeftScrollButtonEnabled(false);
            setRightScrollButtonEnabled(true);
        }

        if (isFullRight && !isFullLeft) {
            setShowScrollButtons(true);
            setRightScrollButtonEnabled(false);
            setLeftScrollButtonEnabled(true);
        }
    };

    useEffect(() => {
        checkScrollPosition();

        const scrollContainer = flowDrawerRef.current;

        const handleResize = () => {
            checkScrollPosition();
        };

        window.addEventListener('resize', handleResize);

        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', checkScrollPosition);
        }

        return () => {
            window.removeEventListener('resize', handleResize);

            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', checkScrollPosition);
            }
        };
    }, []);

    return (
        <FulfillmentFlowWrapper>
            {/* <hr /> */}
            <h3>Estados de entrega</h3>
            <FlowDrawer
                order={order}
                logistic={logistic}
                fulfillment={fulfillment}
                refProp={flowDrawerRef}
            />
            {showScrollButtons ? (
                <ScrollButtonsContainer>
                    <ScrollButton
                        disabled={!leftScrollButtonEnabled}
                        onClick={() => handleScroll(-100)}
                    >
                        {'<'}
                    </ScrollButton>
                    <ScrollButton
                        disabled={!rightScrollButtonEnabled}
                        onClick={() => handleScroll(100)}
                    >
                        {'>'}
                    </ScrollButton>
                </ScrollButtonsContainer>
            ) : null}
        </FulfillmentFlowWrapper>
    );
};

export default FulfillmentFlow;
