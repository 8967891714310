import { ReactComponent as EditIcon } from 'assets/edit-icon2.svg';
import {
    devolutionIncidenceType,
    directionNotFoundIncidenceType,
    deliveredFailedIncidenceType,
    deliveryLogisticOperatorIncidenceType,
    pickupFailedIncidenceType,
    insufficientStockIncidenceType,
    productInBadConditionIncidenceType,
    otherIncidenceType,
} from 'helpers/renderManualIncidencesLabels';
import { useMemo } from 'react';
import FormStyled from 'components/Utils/Form';
import { ReactComponent as IncidenceIcon } from 'assets/alert-rhombus.svg';
import Flex from 'components/Utils/Flex';
import { Label } from './styled';

const incidencesTypes = [
    devolutionIncidenceType,
    directionNotFoundIncidenceType,
    deliveredFailedIncidenceType,
    deliveryLogisticOperatorIncidenceType,
    pickupFailedIncidenceType,
    insufficientStockIncidenceType,
    productInBadConditionIncidenceType,
    otherIncidenceType,
];

const labelStatus = {
    open: 'Abierto',
    resolved: 'Resuelto',
    cancelled: 'Cancelado',
};

const CurrentIncidences = ({
    name,
    incidence: {
        order_id,
        order_ids,
        incidence_type,
        responsible_id,
        comment,
        incidence_package,
        ocurrence,
        status,
    },
    editMode,
    handleEditClick,
}) => {
    const incidencesTypesLabel = useMemo(() => {
        return incidencesTypes.find((i) => i?.value === incidence_type)?.label;
    }, [incidence_type]);

    const incidencesPackagesCodes = incidence_package?.package_id
        ?.map((p) => `#${p?.code}`)
        .join(', ');

    const fullResponsibleName = useMemo(() => {
        if (!responsible_id) return 'Servicio externo';

        const { firstname, lastname } = responsible_id || {};

        return `${firstname ?? ''} ${lastname ?? ''}`;
    }, [responsible_id]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormStyled layout={'vertical'} name="manual-incidence-details-show">
                <div className="w-full" style={{ padding: '4px' }}>
                    <div
                        className="w-full flex flex-col justify-center p-2 my-2"
                        style={{
                            border: '1px solid #E4E8EC',
                            boxSizing: 'border-box',
                        }}
                    >
                        <div className="flex justify-between items-center">
                            <Label fontWeight={400}>Detalles de Incidencia</Label>
                            {editMode && (
                                <EditIcon
                                    style={{
                                        cursor: 'pointer',
                                        width: '20.12px',
                                        height: '20.12px',
                                    }}
                                    onClick={handleEditClick}
                                />
                            )}
                        </div>
                        <div className="ml-2" id="hello-world">
                            {name !== 'header_order_v2' && order_id && (
                                <Label fontsize="12px">Pedido: {order_id?.name}</Label>
                            )}
                            {order_ids?.map((order) => (
                                <div key={order._id}>
                                    <Label fontsize="12px">Pedido {order?.name}</Label>
                                </div>
                            ))}
                        </div>

                        <div className="mt-5">
                            <div className="mb-1">
                                <span style={{ color: '#7998A7' }}>Tipo de incidencia:</span>
                            </div>
                            <Flex className="ml-2" alignItems="center">
                                <Label>
                                    {incidencesTypesLabel}{' '}
                                    {incidence_type === 'other' && !!ocurrence && (
                                        <Label fontWeight={400} whiteSpace={'pre'}>
                                            {' '}
                                            ({ocurrence})
                                        </Label>
                                    )}
                                    {!!incidencesPackagesCodes && (
                                        <span
                                            style={{
                                                fontWeight: 400,
                                                color: '#142C4A',
                                            }}
                                        >
                                            {incidencesPackagesCodes}
                                        </span>
                                    )}
                                    <IncidenceIcon
                                        style={{
                                            marginLeft: 5,
                                            display: 'inline-block',
                                        }}
                                    />
                                </Label>
                            </Flex>
                        </div>
                        <div className="mt-5">
                            <div className="mb-1">
                                <span style={{ color: '#7998A7' }}>Responsable del reporte:</span>
                            </div>
                            <Label className="ml-2">{fullResponsibleName}</Label>
                        </div>
                        <div className="mt-5">
                            <div className="mb-1">
                                <span style={{ color: '#7998A7' }}>Comentario:</span>
                            </div>
                            <Label className={status ? 'ml-2' : 'ml-2 mb-4'}>
                                {comment || 'Sin comentario'}
                            </Label>
                        </div>
                        {status && (
                            <div className="mt-5">
                                <div className="mb-1">
                                    <span style={{ color: '#7998A7' }}>Estado:</span>
                                </div>
                                <Label className="ml-2 mb-4">{labelStatus[status]}</Label>
                            </div>
                        )}
                    </div>
                </div>
            </FormStyled>
        </div>
    );
};

export default CurrentIncidences;
