import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPreparersByCustom } from 'redux/features/Users/getPreparersByCustom';

export const useGetPreparersBySupervisor = () => {
    const dispatch = useDispatch();
    const { loading, data, success, error } = useSelector((state) => state.getPreparersByCustom);
    useEffect(() => {
        if (!data[0] && !success) {
            dispatch(getPreparersByCustom());
        }
    }, []);
    return [loading, data, error];
};

export const useGetPreparersBySac = () => {
    const dispatch = useDispatch();
    const { loading, data, error } = useSelector((state) => state.getPreparersByCustom);
    const { store_selected } = useSelector((state) => state.changeStoreGAOD);

    useEffect(() => {
        if (store_selected?._id) {
            dispatch(getPreparersByCustom({ store_id: store_selected?._id }));
        }
    }, [store_selected?._id]);

    return [loading, data, error];
};

export const useGetPreparersByStore = ({ store_id }) => {
    const dispatch = useDispatch();
    const { loading, data, error } = useSelector((state) => state.getPreparersByCustom);

    useEffect(() => {
        if (store_id) {
            dispatch(getPreparersByCustom({ store_id }));
        }
    }, [store_id]);

    return [loading, data, error];
};
