/* eslint-disable no-unused-vars */
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'redux/features/Utils';
import PrimaryButton from 'components/V2/Buttons/PrimaryButton';
import Dropdown from 'components/V2/Dropdown';
import Menu from 'components/V2/Menu';
import MenuItem from 'components/V2/Menu/MenuItem';
import { ReactComponent as ChevronDownIcon } from 'assets/chevron-down.svg';
import CreateManualIncidenceOrderDrawer from 'components/ManualIncidences/Order/CreateIncidenceDrawer';

const CrossdockingActions = ({
    selected = [],
    disabled = false,
    data,
    hasStatusAllowedRowProcessShipment,
    hasStatusAllowedRowSendToDeliveries,
    processShipment,
}) => {
    const dispatch = useDispatch();

    const handleClickProcessShipment = () => {
        processShipment();
    };

    const handleClickSendToDeliveries = () => {
        dispatch(toggleModal('orders-to-delivery-tickets-confirmation'));
    };

    const menu = (
        <Menu>
            <MenuItem
                disabled={!hasStatusAllowedRowProcessShipment}
                onClick={handleClickProcessShipment}
            >
                Procesar envío ({selected.length})
            </MenuItem>
            <MenuItem
                disabled={!hasStatusAllowedRowSendToDeliveries}
                onClick={handleClickSendToDeliveries}
            >
                Enviar a entrega ({selected.length})
            </MenuItem>

            <CreateManualIncidenceOrderDrawer
                isMenuItem
                ids={selected}
                orders={data?.docs}
                viewPage="crossdocking"
            />
        </Menu>
    );

    return (
        <Dropdown disabled={disabled} overlay={menu}>
            <PrimaryButton icon={<ChevronDownIcon width={24} height={24} />} iconPosition="right">
                Acciones ({selected.length})
            </PrimaryButton>
        </Dropdown>
    );
};

export default CrossdockingActions;
