import { DownOutlined } from '@ant-design/icons';
import { Form, Select } from 'antd';
import { useMemo } from 'react';
import { getIncidenceTypesByStatus, rulesRequired } from 'utils';
import { IncidenceContainer, Label } from './styles';

export const IncidenceOrderDetail = ({
    setIncidenceSelected,
    userDataLocalStorage,
    order,
    fulfillmentOrLogisticId,
}) => {
    const filteredIncidencesTypes = useMemo(() => {
        const fulfillment =
            order.fulfillments?.find((f) => f._id === fulfillmentOrLogisticId) ??
            (order.fulfillments?.length === 1 ? order.fulfillments[0] : null);

        const statusConsolidationOrTransferCd =
            fulfillment?.consolidation?.status ??
            fulfillment?.preparationcd_transfer_pickupstore?.status;

        if (['received_in_cd_location'].includes(statusConsolidationOrTransferCd))
            return getIncidenceTypesByStatus(statusConsolidationOrTransferCd);

        return getIncidenceTypesByStatus(fulfillment?.status ?? order?.fulfillment_status);
    }, [order]);

    return (
        <IncidenceContainer>
            <Label justifyContent="center" fontSize="14px" marginBottom="0px">
                Detalles de incidencia
            </Label>
            <Label>Tipo de incidencia</Label>

            <Form.Item
                name="incidence_type"
                rules={[rulesRequired]}
                style={{ padding: 0, margin: 0 }}
            >
                <Select
                    suffixIcon={<DownOutlined style={{ color: '#F27127' }} />}
                    placeholder="Seleccione"
                    onChange={(value) => setIncidenceSelected(value)}
                    allowClear
                >
                    {filteredIncidencesTypes.map((incidenceType) => (
                        <Select.Option key={incidenceType.value} value={incidenceType.value}>
                            {incidenceType.label}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Label marginTop="8px">Responsable del reporte</Label>
            <Form.Item name="user_id" rules={[rulesRequired]} style={{ padding: 0, margin: 0 }}>
                <Select
                    suffixIcon={<DownOutlined style={{ color: '#F27127' }} />}
                    placeholder="Seleccione"
                    disabled
                >
                    <Select.Option key={userDataLocalStorage._id} value={userDataLocalStorage._id}>
                        {userDataLocalStorage.username}
                    </Select.Option>
                </Select>
            </Form.Item>
        </IncidenceContainer>
    );
};
