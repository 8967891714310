import TCard from 'components/TCard';
import { ViewTitle } from 'components/V2/Settings/ViewTittle';
import { Title, ViewContainer } from 'pages/Settings/styles';
import { ReactComponent as ArrowUturnLeftIcon } from 'assets/arrow-uturn-left.svg';
import { CheckboxContent } from './styles';
import { Checkbox, Form } from 'antd';
import FormStyled from 'components/Utils/Form';
import Flex from 'components/Utils/Flex';
import { Button } from '@andescommercecloud/beflow-design-system';
import {
    useCreateReturnPreferencesMutation,
    useGetReturnPreferenceByMerchantQuery,
    useUpdateReturnPreferencesMutation,
} from 'redux/features/ReturnPreference';
import useCurrentUser from 'hooks/useCurrentUser';
import { useEffect } from 'react';

const ReturnPreferences = () => {
    const [form] = Form.useForm();
    const user = useCurrentUser();

    const [createReturnPreference] = useCreateReturnPreferencesMutation();
    const [updateReturnPreference] = useUpdateReturnPreferencesMutation();
    const { data: returnPreferences, isLoading } = useGetReturnPreferenceByMerchantQuery(
        {
            merchant_id: user.userData?.merchant_id?._id,
        },
        { skip: !user.userData?.merchant_id?._id }
    );
    const handleSubmit = async (values) => {
        const payload = {
            ...values,
            merchant_id: user.userData?.merchant_id?._id,
        };

        if (returnPreferences?._id) {
            updateReturnPreference({
                _id: returnPreferences?._id,
                ...payload,
            });
        } else {
            createReturnPreference(payload);
        }
    };

    useEffect(() => {
        if (returnPreferences) {
            form.setFieldsValue({
                ...returnPreferences,
            });
        }
    }, [returnPreferences]);

    return (
        <TCard
            title={<ViewTitle title="Preferencias de devolución" icon={<ArrowUturnLeftIcon />} />}
            paddingless
            bordered
            squaredBorderRadius
            loading={isLoading}
        >
            <ViewContainer>
                <FormStyled
                    name="return_preferences"
                    layout="vertical"
                    onFinish={handleSubmit}
                    form={form}
                >
                    <Title>Para registro de devolución a través de interfaz OMS:</Title>
                    <CheckboxContent>
                        <Form.Item name={'enable_return_approval_flow'} valuePropName="checked">
                            <Checkbox style={{ fontSize: '12px' }}>
                                Habilitar devoluciones a través de flujo de aprobación
                            </Checkbox>
                        </Form.Item>
                    </CheckboxContent>
                </FormStyled>
                <Flex margin="3rem 0px 1.5rem 0px" justifyContent="flex-end">
                    <Button onClick={() => form.submit()}>Guardar Cambios</Button>
                </Flex>
            </ViewContainer>
        </TCard>
    );
};

export default ReturnPreferences;
