import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';
import toast from 'react-hot-toast';

const ERROR_CODES = {
    USER_NOT_FOUND_IN_SAP: 'El usuario no fue encontrado en SAP o las credenciales son incorrectas',
};

export const integrationApi = createApi({
    reducerPath: 'integrationsV2',
    baseQuery,
    tagTypes: ['integrations'],
    endpoints: (builder) => ({
        getIntegrations: builder.query({
            query: () => ({
                url: `/settings/integrations`,
                method: 'get',
            }),
            providesTags: ['integrations'],
            refetchOnMountOrArgChange: true,
        }),
        createIntegration: builder.mutation({
            query: (data) => ({
                url: `/settings/integrations`,
                method: 'post',
                body: data,
            }),
            invalidatesTags: ['integrations'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Creando integración</b>,
                    success: <b>Integración creada con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        updateIntegration: builder.mutation({
            query: ({ _id, ...data }) => ({
                url: `/settings/integrations/${_id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['integrations'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando integración</b>,
                    success: <b>Integración actualizada con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        changeStatusIntegration: builder.mutation({
            query: ({ _id, ...data }) => ({
                url: `/settings/integrations/${_id}/change-status`,
                method: 'post',
                body: data,
            }),
            invalidatesTags: ['integrations'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando estado de integración</b>,
                    success: <b>Integración actualizada con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        createSapIntegration: builder.mutation({
            query: (data) => ({
                url: `/sap`,
                method: 'post',
                body: data,
            }),
            invalidatesTags: ['integrations'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Creando integración SAP</b>,
                    success: <b>Integración SAP creada con éxito</b>,
                    error: (response) => (
                        <b>
                            {ERROR_CODES[response?.error?.data?.errorCode] || 'Ocurrió un error.'}
                        </b>
                    ),
                });
            },
        }),
        updateSapIntegration: builder.mutation({
            query: ({ _id, ...data }) => ({
                url: `/sap${_id}`,
                method: 'put',
                body: data,
            }),
            invalidatesTags: ['integrations'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando integración SAP</b>,
                    success: <b>Integración SAP actualizada con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
    }),
});

export const {
    useUpdateIntegrationMutation,
    useGetIntegrationsQuery,
    useCreateIntegrationMutation,
    useCreateSapIntegrationMutation,
    useUpdateSapIntegrationMutation,
    useChangeStatusIntegrationMutation,
} = integrationApi;
