import { Button } from '@andescommercecloud/beflow-design-system';
import { DownOutlined } from '@ant-design/icons';
import { Divider, Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import FormStyled from 'components/Utils/Form';
import useCurrentUser from 'hooks/useCurrentUser';
import { useEffect, useState } from 'react';
import { useCreateManualIncidenceMutation } from 'redux/features/ManualIncidences';
import { useGetAllUsersQuery } from 'redux/features/Users';
import CurrentIncidences from '../../ShowDetailsDrawer/CurrentIncidence';
import { Description, Label } from '../styled';
import Comment from './Comment';
import { useStateValue } from '../StateContext';

const IncidenceForm = ({ cleanData }) => {
    const [form] = Form.useForm();
    const incidenceType = Form.useWatch('incidence_type', form);
    const comment = Form.useWatch('comment', form);
    const occurrence = Form.useWatch('ocurrence', form);
    const responsibleId = Form.useWatch('responsible_id', form);
    const [editMode, setEditMode] = useState(true);
    const { selectedIds, selectedData, type, incidencesTypes } = useStateValue();

    const { data: usersData, isFetching: loadingUsers } = useGetAllUsersQuery();
    const [createManualIncidence, { isLoading }] = useCreateManualIncidenceMutation();
    const { userData } = useCurrentUser();

    useEffect(() => {
        if (!loadingUsers && userData?._id) {
            form.setFieldsValue({
                responsible_id: userData?._id,
            });
        }
    }, [loadingUsers, userData, form]);

    const onSubmit = async (values) => {
        if (editMode) {
            return setEditMode(false);
        }

        const response = await createManualIncidence({
            ...values,
            order_ids: type === 'order' ? selectedIds : [],
            fulfillments:
                type === 'fulfillment'
                    ? selectedData
                          .filter((data) => selectedIds.includes(data._id))
                          .map((data) => ({
                              fulfillment_id: data._id,
                              order_id:
                                  data.order?._id ??
                                  data.order_id?._id ??
                                  data.order ??
                                  data.order_id,
                          }))
                    : [],
        }).catch((error) => {
            console.log('error incidence', { error });
        });

        if (!response) return;

        setEditMode(true);
        form.setFieldsValue({
            incidence_type: undefined,
            ocurrence: undefined,
            responsible_id: undefined,
            comment: undefined,
        });

        cleanData(selectedIds);
    };

    return (
        <div className="w-full px-2">
            <div className="w-full flex flex-col justify-center">
                <Label>Detalles de Incidencia</Label>
                {selectedData?.map((data) => (
                    <Description key={data._id}>{`${type === 'order' ? 'Pedido' : 'Fulfillment'} ${
                        data.name
                    }`}</Description>
                ))}
            </div>

            <div className="mt-4">
                <FormStyled
                    form={form}
                    layout="vertical"
                    name="manual-incidence-form"
                    onFinish={onSubmit}
                >
                    <div style={{ display: editMode ? 'none' : 'block' }}>
                        <CurrentIncidences
                            incidence={{
                                order_ids: selectedData,
                                incidence_type: incidenceType,
                                responsible_id: usersData?.find((u) => u._id === responsibleId),
                                comment,
                                ocurrence: occurrence,
                            }}
                            editMode
                            handleEditClick={() => setEditMode(true)}
                        />
                    </div>

                    <div style={{ display: editMode ? 'block' : 'none' }}>
                        <Form.Item
                            name="incidence_type"
                            label={<Label>Tipo de incidencia</Label>}
                            style={{ marginBottom: '20px' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor, seleccione un tipo de incidencia.',
                                },
                            ]}
                            requiredMark={false}
                        >
                            <Select
                                suffixIcon={<DownOutlined style={{ color: '#F27127' }} />}
                                placeholder="Selecciona"
                            >
                                {incidencesTypes?.map((incidenceType) => (
                                    <Select.Option
                                        key={incidenceType.value}
                                        value={incidenceType.value}
                                    >
                                        {incidenceType.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {incidenceType === 'other' && (
                            <Form.Item
                                name="ocurrence"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor, ingresa una ocurrencia.',
                                    },
                                ]}
                                label={<Label>Ingrese la ocurrencia</Label>}
                            >
                                <Input />
                            </Form.Item>
                        )}

                        <Form.Item
                            name="responsible_id"
                            label={<Label>Responsable del reporte</Label>}
                            style={{ marginTop: '10px' }}
                            rules={[{ required: true }]}
                        >
                            <Select
                                loading={loadingUsers}
                                suffixIcon={<DownOutlined style={{ color: '#F27127' }} />}
                            >
                                {usersData?.map((ud) => (
                                    <Select.Option key={ud?._id} value={ud?._id}>
                                        {`${ud?.firstname ?? ''} ${ud?.lastname ?? ''}`}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Comment form={form} />
                    </div>

                    <Divider style={{ marginTop: '50px' }} />

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variation="primary"
                            type="submit"
                            htmlType="submit"
                            disabled={isLoading || !selectedIds.length}
                        >
                            <Label color="currentColor">Crear incidencia</Label>
                        </Button>
                    </div>
                </FormStyled>
            </div>
        </div>
    );
};
IncidenceForm.propTypes = {
    selectedIds: PropTypes.array.isRequired,
    selectedData: PropTypes.array.isRequired,
    incidencesTypes: PropTypes.array.isRequired,
    cleanData: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

export default IncidenceForm;
