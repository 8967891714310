export function documentReportedLogs(log) {
    return {
        DOCUMENT_DTE_INVOICE_REPORTED_EXTERNALLY: (
            <span>
                Se ha recibido documento de tipo Factura{' '}
                <a target="_blank" rel="noreferrer" href={log?.other?.document_pdf}>
                    <u>#{log?.other?.folio ?? ''}</u>
                </a>{' '}
                para el pedido {log?.other?.order_name} informada desde servicio.
            </span>
        ),
        DOCUMENT_DTE_TICKET_REPORTED_EXTERNALLY: (
            <span>
                Se ha recibido documento de tipo Boleta{' '}
                <a target="_blank" rel="noreferrer" href={log?.other?.document_pdf}>
                    <u>#{log?.other?.folio ?? ''}</u>
                </a>{' '}
                para el pedido {log?.other?.order_name} informada desde servicio.
            </span>
        ),
        DOCUMENT_DTE_CREDIT_NOTE_REPORTED_EXTERNALLY: (
            <span>
                Se ha recibido documento de tipo Nota de crédito{' '}
                <a target="_blank" rel="noreferrer" href={log?.other?.document_pdf}>
                    <u>#{log?.other?.folio ?? ''}</u>
                </a>{' '}
                para el pedido {log?.other?.order_name} informada desde servicio.
            </span>
        ),
    };
}
