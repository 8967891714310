import { nanoid } from '@reduxjs/toolkit';
import { defaultColumnsView as preparationsColumns } from 'pages/PreparationsV2/config';
import { defaultColumnsView as pendingArrivalColumns } from 'pages/PendingArrivalsV2/config';
import { defaultColumnsView as crossdockingColumns } from 'pages/Crossdocking/config';
import { defaultColumnsView as deliveriesColumns } from 'pages/DeliveriesV2/config';
import { defaultColumnsView as manifestsColumns } from 'pages/Manifests/config';

const ordersColumns = [
    {
        name: 'remote_order_id',
        label: 'Orden',
        position: 1,
        status: true,
    },
    {
        name: 'sales_channel',
        label: 'Canal',
        position: 2,
        status: true,
    },
    {
        name: 'createdAt',
        label: 'Fecha de creación',
        position: 3,
        status: true,
    },
    {
        name: 'promised_date',
        label: 'Plazo límite',
        position: 4,
        status: true,
    },
    {
        name: 'fulfillment_status',
        label: 'Estado',
        position: 5,
        status: true,
    },
    {
        name: 'delivery_method',
        label: 'Tipo de entrega',
        position: 6,
        status: true,
    },
    {
        name: 'assigned_courier',
        label: 'Operador logístico',
        position: 7,
        status: true,
    },
    {
        name: 'fulfillments',
        label: 'Fulfillment',
        position: 8,
        status: true,
    },
    {
        name: 'customer',
        label: 'Cliente',
        position: 9,
        status: true,
    },
    {
        name: 'customer_document',
        label: 'Documento de identidad',
        position: 10,
        status: true,
    },
    {
        name: 'shipping_address',
        label: 'Dirección de entrega',
        position: 11,
        status: true,
    },
    {
        name: 'delivery_date',
        label: 'Fecha de retiro',
        position: 12,
        status: true,
    },
    {
        name: 'line_items',
        label: 'Unidades',
        position: 13,
        status: true,
    },
    {
        name: 'location_id',
        label: 'Tienda',
        position: 14,
        status: true,
    },
    {
        name: 'total_price',
        label: 'Total',
        position: 15,
        status: true,
    },
];

const ordersInitialTabsContent = [
    // fulfillment_status
    { value: 'all', name: 'Todos', typeOfTab: 'basic', columns: ordersColumns },
    {
        value: 'unfulfilled',
        name: 'Pendientes',
        typeOfTab: 'basic',
        columns: ordersColumns,
        filters: [
            {
                andOr: '',
                field: 'fulfillment_status',
                condition: 'is',
                value: 'unfulfilled',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'in_progress',
        name: 'En proceso',
        typeOfTab: 'basic',
        columns: ordersColumns,
        filters: [
            {
                andOr: '',
                field: 'fulfillment_status',
                condition: 'is',
                value: 'in_progress',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'cancelled',
        name: 'Cancelados',
        typeOfTab: 'basic',
        columns: ordersColumns,
        filters: [
            {
                andOr: '',
                field: 'fulfillment_status',
                condition: 'is',
                value: 'cancelled',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'fulfilled',
        name: 'Listos para enviar',
        typeOfTab: 'basic',
        columns: ordersColumns,
        filters: [
            {
                andOr: '',
                field: 'fulfillment_status',
                condition: 'is',
                value: 'fulfilled',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'delivered_to_courier',
        name: 'Despachados',
        typeOfTab: 'basic',
        columns: ordersColumns,
        filters: [
            {
                andOr: '',
                field: 'fulfillment_status',
                condition: 'is',
                value: 'delivered_to_courier',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
];

const ordersV3InitialTabsContent = [
    { value: 'all', name: 'Todos', typeOfTab: 'basic', columns: ordersColumns },
    {
        value: 'pending',
        name: 'Pendientes',
        typeOfTab: 'basic',
        columns: ordersColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'pending',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'in_progress',
        name: 'En proceso',
        typeOfTab: 'basic',
        columns: ordersColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'in_progress',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'cancelled',
        name: 'Cancelados',
        typeOfTab: 'basic',
        columns: ordersColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'cancelled',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'fulfilled',
        name: 'Listos para enviar',
        typeOfTab: 'basic',
        columns: ordersColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'success',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'delivered_to_courier',
        name: 'Despachados',
        typeOfTab: 'basic',
        columns: ordersColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'delivered_to_courier',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'cash_on_delivery',
        name: 'Pago contra entrega',
        typeOfTab: 'basic',
        columns: [
            ...ordersColumns,
            {
                name: 'cash_on_delivery_status',
                label: 'Estado del pago',
                position: 16,
                status: true,
            },
            {
                name: 'actions',
                label: 'Acciones',
                position: 17,
                status: true,
            },
        ],
        filters: [
            {
                andOr: '',
                field: 'custom.cash_on_delivery',
                condition: 'is',
                value: true,
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
];

const preparationsInitialTabsContent = [
    // fulfillment_status
    { value: 'all', name: 'Todos', typeOfTab: 'basic', columns: preparationsColumns },
    {
        value: 'open',
        name: 'Pendientes de preparación',
        typeOfTab: 'basic',
        columns: preparationsColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'includes',
                value: ['open', 'in_progress'],
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'partially_processed',
        name: 'Parcialmente preparados',
        typeOfTab: 'basic',
        columns: preparationsColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'partially_processed',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'paused',
        name: 'Pausados',
        typeOfTab: 'basic',
        columns: preparationsColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'paused',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'cancelled',
        name: 'Cancelados',
        typeOfTab: 'basic',
        columns: preparationsColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'cancelled',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'ready_for_deliver',
        name: 'Listos para enviar a entregas',
        typeOfTab: 'basic',
        columns: preparationsColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'ready_for_deliver',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
];

const pendingArrivalInitialTabsContent = [
    { value: 'all', name: 'Todos', typeOfTab: 'basic', columns: pendingArrivalColumns },
    {
        value: 'pendingPickupFromCD',
        name: 'Pendientes con retiro',
        typeOfTab: 'basic',
        columns: pendingArrivalColumns,
        filters: [
            {
                andOr: '',
                field: 'preparationcd_transfer_pickupstore',
                condition: 'exists',
                isRemovable: false,
                hide: true,
                id: nanoid(),
                compromiseValues: [],
            },
            {
                andOr: 'and',
                field: 'preparationcd_transfer_pickupstore.status',
                condition: 'not_include',
                value: [
                    'received_in_pickup_location',
                    'received_in_cd_location',
                    'delivered',
                    'cancelled',
                    'success',
                ],
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },

    {
        value: 'pendingShippingConsolidation',
        name: 'Pendientes con despacho (Consolidación)',
        typeOfTab: 'basic',
        columns: pendingArrivalColumns,
        filters: [
            {
                andOr: '',
                field: 'consolidation.consolidation_location_id',
                condition: 'exists',
                isRemovable: false,
                hide: true,
                id: nanoid(),
                compromiseValues: [],
            },
            {
                andOr: 'and',
                field: 'consolidation.status',
                condition: 'not_include',
                value: [
                    'received_in_pickup_location',
                    'received_in_cd_location',
                    'delivered',
                    'cancelled',
                    'success',
                ],
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
            {
                andOr: 'and',
                field: 'delivery_method',
                condition: 'is',
                value: 'shipping',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
];

const crossdockingTabsContent = [
    { value: 'all', name: 'Todos', typeOfTab: 'basic', columns: crossdockingColumns },
];

const deliveriesInitialTabsContent = [
    {
        value: 'all',
        name: 'Pendientes de entrega',
        typeOfTab: 'basic',
        columns: deliveriesColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'success',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'pending_delivery',
        name: 'Pendientes de entrega al courier',
        typeOfTab: 'basic',
        columns: deliveriesColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'success',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
            {
                andOr: 'and',
                field: 'delivery_method',
                condition: 'is',
                value: 'shipping',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'pending_pickup',
        name: 'Pendientes de entrega con retiro',
        typeOfTab: 'basic',
        columns: deliveriesColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'success',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
            {
                andOr: 'and',
                field: 'delivery_method',
                condition: 'is',
                value: 'pickup',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'deliveries_delivered_to_courier',
        name: 'Entregados al courier',
        typeOfTab: 'basic',
        columns: deliveriesColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'delivered_to_courier',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'deliveries_delivered',
        name: 'Entregados al cliente',
        typeOfTab: 'basic',
        columns: deliveriesColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'delivered',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
];

const manifestsInitialTabsContent = [
    {
        value: 'pending_delivery',
        name: 'Pendientes de entrega al courier',
        typeOfTab: 'basic',
        columns: manifestsColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'pending',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
            {
                andOr: 'and',
                field: 'delivery_method',
                condition: 'is',
                value: 'shipping',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'delivered_to_courier',
        name: 'Entregados al courier',
        typeOfTab: 'basic',
        columns: manifestsColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'delivered',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
            {
                andOr: 'and',
                field: 'delivery_method',
                condition: 'is',
                value: 'shipping',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'pending_pickup',
        name: 'Pendientes con retiro en tienda',
        typeOfTab: 'basic',
        columns: manifestsColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'pending',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
            {
                andOr: 'and',
                field: 'delivery_method',
                condition: 'is',
                value: 'pickup',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
    {
        value: 'delivered',
        name: 'Retiros entregados al cliente',
        typeOfTab: 'basic',
        columns: manifestsColumns,
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'is',
                value: 'delivered',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
            {
                andOr: 'and',
                field: 'delivery_method',
                condition: 'is',
                value: 'pickup',
                isRemovable: false,
                id: nanoid(),
                compromiseValues: [],
            },
        ],
    },
];

export const userPreferences = {
    orders: {
        tabs: [...ordersInitialTabsContent],
    },
    orders_v3: {
        tabs: [...ordersV3InitialTabsContent],
    },
    preparations: {
        tabs: [...preparationsInitialTabsContent],
    },
    pendingArrivals: {
        tabs: [...pendingArrivalInitialTabsContent],
    },
    crossdocking: {
        tabs: [...crossdockingTabsContent],
    },
    deliveries: {
        tabs: [...deliveriesInitialTabsContent],
    },
    manifests: {
        tabs: [...manifestsInitialTabsContent],
    },
};
